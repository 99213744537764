import React from 'react';

export default function Kratong2() {
  return (
    <svg width="112" height="103" viewBox="0 0 112 103" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="45.8701" y="29.2502" width="6.79719" height="37.3845" fill="url(#paint0_linear_0_4)" />
      <path d="M45.8392 19.4793C43.4602 26.2764 46.8305 27.6925 48.813 27.5509C55.1854 27.5509 53.9109 21.1786 53.0613 19.4793C52.3815 18.1198 49.9459 12.3989 48.813 9.70831C48.813 10.9828 48.813 10.9828 45.8392 19.4793Z" fill="#FFE92E" />
      <path d="M45.8392 19.4793C43.4602 26.2764 46.8305 27.6925 48.813 27.5509C55.1854 27.5509 53.9109 21.1786 53.0613 19.4793C52.3815 18.1198 49.9459 12.3989 48.813 9.70831C48.813 10.9828 48.813 10.9828 45.8392 19.4793Z" fill="#FFE92E" />
      <path d="M45.8392 19.4793C43.4602 26.2764 46.8305 27.6925 48.813 27.5509C55.1854 27.5509 53.9109 21.1786 53.0613 19.4793C52.3815 18.1198 49.9459 12.3989 48.813 9.70831C48.813 10.9828 48.813 10.9828 45.8392 19.4793Z" fill="#FFE92E" />
      <path d="M47.2764 21.7818C45.6593 26.6344 47.9502 27.6454 49.2977 27.5443C53.6291 27.5443 52.7629 22.995 52.1853 21.7818C51.7233 20.8113 50.0678 16.727 49.2977 14.8062C49.2977 15.7161 49.2977 15.7161 47.2764 21.7818Z" fill="#FFA217" />
      <path d="M47.9671 24.0895C46.8121 27.001 48.4484 27.6076 49.4109 27.547C52.5048 27.547 51.886 24.8174 51.4735 24.0895C51.1435 23.5072 49.9609 21.0566 49.4109 19.9041C49.4109 20.45 49.4109 20.45 47.9671 24.0895Z" fill="#FAEE8D" />
      <rect x="55.9585" y="0.958313" width="2.1875" height="65.625" fill="#EBC2EE" />
      <rect x="59.604" y="0.958313" width="2.1875" height="65.625" fill="#EBC2EE" />
      <rect x="16.5835" y="92.1042" width="75.1042" height="8.02083" fill="url(#paint1_linear_0_2)" />
      <path d="M0.541504 87.5369C29.3533 104.951 55.9582 91.5648 55.9582 91.5648C33.8554 74.156 0.541504 87.5369 0.541504 87.5369Z" fill="url(#paint2_linear_0_1)" />
      <path d="M111.375 87.5369C82.5634 104.951 55.9585 91.5648 55.9585 91.5648C78.0571 74.156 111.375 87.5369 111.375 87.5369Z" fill="url(#paint3_linear_0_1)" />
      <path d="M44.7613 91.6273C44.7613 91.6273 18.4722 106.399 0.608186 75.3197C0.608186 75.3197 32.4084 61.8147 44.7613 91.6273Z" fill="url(#paint4_linear_0_1)" />
      <path d="M41.1101 88.9121C41.1101 88.9121 14.9227 95.4265 6.87598 64.3722C6.87598 64.3722 37.5186 60.2577 41.1101 88.9121Z" fill="url(#paint5_linear_0_1)" />
      <path d="M41.462 78.1047C41.462 78.1047 26.6609 70.5178 35.5084 52.8356C35.5084 52.8356 51.4059 63.3424 41.462 78.1047Z" fill="url(#paint6_linear_0_1)" />
      <path d="M64.9396 76.5235C64.9396 76.5235 57.6816 61.5584 75.3871 52.7576C75.3871 52.7576 81.7641 70.7147 64.9396 76.5235Z" fill="url(#paint7_linear_0_1)" />
      <path d="M63.4251 91.6273C63.4251 91.6273 89.7154 106.395 107.578 75.3197C107.578 75.3197 75.778 61.8147 63.4251 91.6273Z" fill="url(#paint8_linear_0_1)" />
      <path d="M50.2763 95.4709C50.2763 95.4709 17.3951 97.635 14.396 58.5625C14.396 58.5625 52.1204 60.2404 50.2763 95.4709Z" fill="url(#paint9_linear_0_1)" />
      <path d="M70.4031 90.8967C70.4031 90.8967 69.7641 63.9188 101.826 62.8472C101.826 62.8472 99.1502 93.6488 70.4031 90.8967Z" fill="url(#paint10_linear_0_1)" />
      <path d="M58.9405 95.4709C58.9405 95.4709 91.8216 97.635 94.8207 58.5625C94.8207 58.5625 57.0963 60.2404 58.9405 95.4709Z" fill="url(#paint11_linear_0_1)" />
      <path d="M73.7652 78.0992C73.7652 87.9233 64.977 95.8884 54.1307 95.8884C43.2844 95.8884 34.4961 87.9233 34.4961 78.0992C34.4961 68.2751 54.1354 43.25 54.1354 43.25C54.1354 43.25 73.77 68.2751 73.77 78.0992H73.7652Z" fill="url(#paint12_linear_0_1)" />
      <defs>
        <linearGradient id="paint0_linear_0_4" x1="49.2687" y1="29.2502" x2="49.2687" y2="66.6347" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFE92E" />
          <stop offset="1" stop-color="#FB8B1C" />
        </linearGradient>
        <linearGradient id="paint1_linear_0_2" x1="54.1356" y1="100.125" x2="54.1356" y2="90.0989" gradientUnits="userSpaceOnUse">
          <stop stop-color="white" />
          <stop offset="0.759679" stop-color="#D9D9D9" />
        </linearGradient>
        <linearGradient id="paint2_linear_0_1" x1="4.9165" y1="88.8229" x2="57.0519" y2="89.9167" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFE71B" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient id="paint3_linear_0_1" x1="110.281" y1="87.7292" x2="55.9585" y2="87.7292" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFE71B" />
          <stop offset="0.447791" stop-color="#FFF281" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient id="paint4_linear_0_1" x1="26.0158" y1="97.2745" x2="18.96" y2="70.942" gradientUnits="userSpaceOnUse">
          <stop offset="0.01" stop-color="white" />
          <stop offset="0.562043" stop-color="#F98CDA" />
          <stop offset="1" stop-color="#FC04C5" />
        </linearGradient>
        <linearGradient id="paint5_linear_0_1" x1="7.19829" y1="64.1186" x2="27.1875" y2="81.9485" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFE502" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient id="paint6_linear_0_1" x1="35.7609" y1="52.8485" x2="37.8183" y2="69.2289" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFE502" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient id="paint7_linear_0_1" x1="75.5811" y1="52.9197" x2="67.3692" y2="67.2417" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFE502" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient id="paint8_linear_0_1" x1="81.4862" y1="97.0902" x2="88.5417" y2="70.7585" gradientUnits="userSpaceOnUse">
          <stop offset="0.01" stop-color="white" />
          <stop offset="0.562043" stop-color="#F98CDA" />
          <stop offset="1" stop-color="#FC04C5" />
        </linearGradient>
        <linearGradient id="paint9_linear_0_1" x1="32.0374" y1="95.3953" x2="32.0374" y2="59.5856" gradientUnits="userSpaceOnUse">
          <stop offset="0.01" stop-color="white" />
          <stop offset="0.562043" stop-color="#F98CDA" />
          <stop offset="1" stop-color="#FC04C5" />
        </linearGradient>
        <linearGradient id="paint10_linear_0_1" x1="102.003" y1="63.2171" x2="80.2387" y2="78.8306" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFE502" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient id="paint11_linear_0_1" x1="76.5164" y1="95.3953" x2="76.5164" y2="59.5856" gradientUnits="userSpaceOnUse">
          <stop offset="0.01" stop-color="white" />
          <stop offset="0.562043" stop-color="#F98CDA" />
          <stop offset="1" stop-color="#FC04C5" />
        </linearGradient>
        <linearGradient id="paint12_linear_0_1" x1="53.7709" y1="95.75" x2="53.7709" y2="44.7083" gradientUnits="userSpaceOnUse">
          <stop offset="0.01" stop-color="white" />
          <stop offset="0.562043" stop-color="#F98CDA" />
          <stop offset="1" stop-color="#FC04C5" />
        </linearGradient>
      </defs>
    </svg>
  );
}
