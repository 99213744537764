import "./CreateIcon.style.css";
import { Row, Col, ColorPicker, Grid } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  CREATE_STATE,
  USER_ICONS,
  CUSTOM_ICONS,
} from "../configure/modalState";
import AnimateClickableComponent from "../../animateClickableComponent/AnimateClickableComponent";
import CustomButton from "../../button/CustomButton";
import CustomInput from "../../input/CustomInput";

export default function CreateIcon({ setCreateState, dispatch, data }) {
  const [disabledButton, setDisabledButton] = useState(
    data?.name?.length > 0 ? false : true
  );
  const [selectedImage, setSelectedImage] = useState(
    data?.avatar.icon !== "" ? data?.avatar.icon : CUSTOM_ICONS.icon1?.icon
  );
  const [color, setColor] = useState(
    data?.avatar.icon !== "" ? data?.avatar : CUSTOM_ICONS.icon1
  );

  const { useBreakpoint } = Grid;
  const { sm, md, lg } = useBreakpoint();

  const onHandleIcon = (e) => {
    setSelectedImage(e);
    setColor(CUSTOM_ICONS[e]);
  };

  const onHandleColorSkin = (hex) => {
    setColor((prep) => {
      return {
        ...prep,
        face: hex,
        neck: darkerColor(hex, -30),
      };
    });
  };

  const onHandleColorHair = (hex) => {
    setColor((prep) => {
      return {
        ...prep,
        hairType1: hex,
        hairType2: darkerColor(hex, 40),
        hairType3: darkerColor(hex, 60),
        hairType4: darkerColor(hex, -20),
      };
    });
  };

  let rgb;
  const darkerColor = (hex, add) => {
    rgb = [
      parseInt(hex.slice(1, 3), 16),
      parseInt(hex.slice(3, 5), 16),
      parseInt(hex.slice(5, 7), 16),
    ];

    rgb = rgb.map((item) => {
      item = item + add;
      item = item > 255 ? 255 : item;
      item = item < 0 ? 0 : item;
      return item;
    });

    return (
      "#" +
      (0 | ((1 << 8) + rgb[0])).toString(16).substring(1) +
      (0 | ((1 << 8) + rgb[1])).toString(16).substring(1) +
      (0 | ((1 << 8) + rgb[2])).toString(16).substring(1)
    );
  };

  const onHandleData = (e) => {
    const value = e?.target?.value.replaceAll("\n"," ");
    const check = /^[a-zA-Zก-ฺเ-๏0-9\s!'()/&*,[#\-\].]+$/;
    if (value?.charAt(0) !== " " && (value === "" || (value?.length <= 40 && check.test(value)))) {
      dispatch({
        type: "SET_NAME",
        data: {
          name: value,
        },
      });
      value === "" ? setDisabledButton(true) : setDisabledButton(false);
    }
  };

  const onSubmitData = useCallback(async () => {
    dispatch({
      type: "SET_USER",
      data: {
        name: data?.name,
        avatar: color,
      },
    });
    setCreateState(CREATE_STATE[2]);
  }, [dispatch, data?.name, color, setCreateState]);

  useEffect(() => {
    if (data?.avatar.icon && data?.name) {
      setSelectedImage(data?.avatar.icon);
      setDisabledButton(data?.name?.length > 0 ? false : true);
    }
  }, [data]);

  return (
    <div>
      <h2 justify="center" align="center">
        Create your avatar
      </h2>

      <Row className="icons_group" align="middle">
        <Col
          className="icons"
          xs={{ span: 24, order: 2 }}
          sm={{ span: 13, order: 1 }}
          md={{ span: 15, order: 1 }}
          lg={{ span: 15, order: 1 }}
          xl={{ span: 15, order: 1 }}
          xxl={{ span: 15, order: 1 }}
        >
          <Row
            justify="start"
            align="center"
            gutter={[{ xs: 24, sm: 8, md: 16, lg: 24, xl: 24, xxl: 24 }, 4]}
          >
            {USER_ICONS?.map((image, index) => {
              return (
                <Col
                  className="gutter-row bouncein"
                  span={8}
                  onClick={(e) => onHandleIcon(image?.key)}
                  key={index}
                  style={{"--delay": `${index*100}ms`}}
                >
                  <AnimateClickableComponent
                    className={`icon ${
                      selectedImage === image?.key ? "icon-select" : ""
                    }`}
                  >
                    <img src={image?.image} alt={image?.key} />
                  </AnimateClickableComponent>
                </Col>
              );
            })}
          </Row>
        </Col>
        <Col
          className="custom slidein"
          style={{"--delay": "200ms"}}
          xs={{ span: 24, order: 1 }}
          sm={{ span: 11, order: 2 }}
          md={{ span: 9, order: 2 }}
          lg={{ span: 9, order: 2 }}
          xl={{ span: 9, order: 2 }}
          xxl={{ span: 9, order: 2 }}
        >
          <div className="custom_icon">
            {React.cloneElement(
              USER_ICONS.find((i) => i?.key === selectedImage)?.icon,
              { custom: color }
            )}
          </div>
          <div>
            <ColorPicker
              disabledAlpha
              size={(sm || md) && !lg ? "small" : ""}
              className="color_picker"
              value={color.face}
              showText={() => (
                <span
                  style={{ fontSize: (sm || md) && !lg ? "small" : "initial" }}
                >
                  Skin
                </span>
              )}
              onChange={(e, hex) => onHandleColorSkin(hex)}
            />
            <br />
            <ColorPicker
              disabledAlpha
              size={(sm || md) && !lg ? "small" : ""}
              className="color_picker"
              value={color.hairType1}
              showText={() => (
                <span
                  style={{ fontSize: (sm || md) && !lg ? "small" : "initial" }}
                >
                  Hair
                </span>
              )}
              onChange={(e, hex) => onHandleColorHair(hex)}
            />
          </div>
        </Col>
      </Row>
      <CustomInput
        onChange={onHandleData}
        placeholder="Enter your name..."
        value={data?.name}
        className="margin-top-1 slidein"
        maxLength={40}
        showCount={true}
        style={{"--delay": "300ms"}}
      />
      <CustomButton
        type="primary"
        onClick={onSubmitData}
        disabled={disabledButton}
        className="margin-top-1 slidein"
        block
        style={{"--delay": "350ms"}}
      >
        Next
      </CustomButton>
    </div>
  );
}
