import styled, { keyframes } from "styled-components";
const kratongRight = keyframes`
    0% {
      right: 100vw;
      transform: translateY(-30%) rotate(10deg);
    }
    10% {
      transform: translateY(-20%) rotate(-10deg);
    }
    20% {
      transform: translateY(-30%) rotate(10deg);
    }
    40% {
      transform: translateY(-20%) rotate(-10deg);
    }
    50% {
      transform: translateY(-30%) rotate(10deg);
    }
    60% {
      transform: translateY(-20%) rotate(-10deg);
    }
    70% {
      transform: translateY(-30%) rotate(10deg);
    }
    80% {
      transform: translateY(-20%) rotate(-10deg);
    }
    90% {
      transform: translateY(-30%) rotate(10deg);
    }
    100% {
      right: calc(0vw - 400px);
      transform: translateY(-20%) rotate(0deg);
    }`;

export const KratongAnimation = styled.div`
  position: absolute;
  z-index: 1;
  right: calc(100vw + 8rem);
  animation: ${props => props?.isMobile ? "50s" : "80s"} linear 1 normal none running ${kratongRight};
  animation-delay: ${props => props?.delay ? `${props?.delay}s` : "0s"};
  animation-fill-mode: forwards;
  transition: 4s;
`;

// export const BounceAnimation = styled(Col)