import React from 'react';

export default function Kratong1() {
  return (
    <svg width="136" height="126" viewBox="0 0 136 126" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="59.5996" y="28.5002" width="6.79719" height="37.3845" fill="url(#paint0_linear_861_15485)" />
      <path d="M59.5682 18.7293C57.1892 25.5264 60.5595 26.9425 62.542 26.8009C68.9144 26.8009 67.6399 20.4286 66.7903 18.7293C66.1105 17.3698 63.6749 11.6489 62.542 8.95831C62.542 10.2328 62.542 10.2328 59.5682 18.7293Z" fill="#FFE92E" />
      <path d="M59.5682 18.7293C57.1892 25.5264 60.5595 26.9425 62.542 26.8009C68.9144 26.8009 67.6399 20.4286 66.7903 18.7293C66.1105 17.3698 63.6749 11.6489 62.542 8.95831C62.542 10.2328 62.542 10.2328 59.5682 18.7293Z" fill="#FFE92E" />
      <path d="M59.5682 18.7293C57.1892 25.5264 60.5595 26.9425 62.542 26.8009C68.9144 26.8009 67.6399 20.4286 66.7903 18.7293C66.1105 17.3698 63.6749 11.6489 62.542 8.95831C62.542 10.2328 62.542 10.2328 59.5682 18.7293Z" fill="#FFE92E" />
      <path d="M61.0054 21.0318C59.3883 25.8844 61.6792 26.8954 63.0267 26.7943C67.3581 26.7943 66.4919 22.245 65.9143 21.0318C65.4523 20.0613 63.7968 15.977 63.0267 14.0562C63.0267 14.9661 63.0267 14.9661 61.0054 21.0318Z" fill="#FFA217" />
      <path d="M61.6961 23.3395C60.5411 26.251 62.1774 26.8576 63.1399 26.797C66.2338 26.797 65.615 24.0674 65.2025 23.3395C64.8725 22.7572 63.6899 20.3066 63.1399 19.1541C63.1399 19.7 63.1399 19.7 61.6961 23.3395Z" fill="#FAEE8D" />
      <rect x="69.6875" y="0.208313" width="2.1875" height="65.625" fill="#EBC2EE" />
      <rect x="73.3335" y="0.208313" width="2.1875" height="65.625" fill="#EBC2EE" />
      <g filter="url(#filter0_d_861_15485)">
        <path d="M93.0505 102.784C93.0505 97.9889 82.1044 94.104 68.6012 94.104C55.098 94.104 44.1519 97.9889 44.1519 102.784C44.1519 107.579 68.6012 119.792 68.6012 119.792C68.6012 119.792 93.0505 107.579 93.0505 102.784Z" fill="#006837" />
        <path d="M61.2389 94.7761C61.2389 94.7761 20.81 93.7322 17.1265 112.556C17.1265 112.556 63.5013 111.745 61.2389 94.7761Z" fill="#006837" />
        <path d="M7.0791 98.5847C39.656 87.1495 69.7377 95.9392 69.7377 95.9392C44.7512 107.37 7.0791 98.5847 7.0791 98.5847Z" fill="#006837" />
        <path d="M41.7614 96.9688C41.7614 96.9688 12.5448 94.0468 3.3335 107.622C3.3335 107.622 37.5455 109.51 41.7614 96.9736V96.9688Z" fill="#006837" />
        <path d="M74.4854 94.7761C74.4854 94.7761 114.914 93.7322 118.598 112.556C118.598 112.556 72.223 111.745 74.4854 94.7761Z" fill="#006837" />
        <path d="M128.65 98.5847C96.0733 87.1495 65.9917 95.9392 65.9917 95.9392C90.9781 107.37 128.65 98.5847 128.65 98.5847Z" fill="#006837" />
        <path d="M93.9678 96.9688C93.9678 96.9688 123.184 94.0468 132.396 107.618C132.396 107.618 98.1837 109.505 93.9678 96.9688Z" fill="#006837" />
        <path d="M7.0791 84.9234C39.656 114.105 69.7377 91.673 69.7377 91.673C44.7465 62.5009 7.0791 84.9234 7.0791 84.9234Z" fill="#004C26" />
        <path d="M128.65 84.9234C96.0733 114.105 65.9917 91.673 65.9917 91.673C90.9781 62.5009 128.65 84.9234 128.65 84.9234Z" fill="#004C26" />
        <path d="M24.1323 59.6839C24.1323 59.6839 17.5352 59.1405 15.7576 57.863C13.98 56.5855 15.0589 53.7732 17.231 54.021L17.8251 54.0878L17.6873 53.5062C17.1787 51.3708 19.8166 49.8454 21.3138 51.571C22.811 53.3013 24.1371 59.6791 24.1371 59.6791L24.1323 59.6839Z" fill="#FFCAE8" />
        <path d="M22.716 58.5542C23.562 59.0022 24.1846 59.4837 24.1086 59.6267C24.0325 59.7697 23.2863 59.5266 22.4355 59.0785C21.5895 58.6304 20.9669 58.149 21.0429 58.006C21.119 57.863 21.8652 58.1061 22.716 58.5542Z" fill="#FFD685" />
        <path d="M22.8444 58.4064C23.391 59.1929 23.9423 59.7601 24.0754 59.6648C24.2085 59.5695 23.8758 58.8545 23.3292 58.068C22.7826 57.2815 22.2312 56.7142 22.0981 56.8096C21.9651 56.9049 22.2978 57.6199 22.8444 58.4064Z" fill="#FFD685" />
        <path d="M24.1228 59.6267C24.1228 59.6267 20.4108 65.1226 18.4288 66.0474C16.4468 66.9721 14.5313 64.6507 15.8146 62.8728L16.1664 62.3866L15.5912 62.2197C13.4904 61.6144 13.4572 58.5589 15.6958 58.1013C17.9345 57.6437 24.1276 59.6219 24.1276 59.6219L24.1228 59.6267Z" fill="#FFA1D2" />
        <path d="M22.4454 60.3083C23.2487 59.7887 23.9759 59.4789 24.0615 59.6171C24.1518 59.7554 23.5671 60.2892 22.7639 60.8088C21.9606 61.3284 21.2334 61.6382 21.1479 61.5C21.0576 61.3617 21.6422 60.8279 22.4454 60.3083Z" fill="#FFD685" />
        <path d="M22.3786 60.1224C23.3339 60.0318 24.0896 59.8269 24.0754 59.6648C24.0611 59.5027 23.2769 59.4408 22.3215 59.5313C21.3662 59.6219 20.6105 59.8269 20.6247 59.9889C20.639 60.151 21.4232 60.213 22.3786 60.1224Z" fill="#FFD685" />
        <path d="M24.0706 59.6076C24.0706 59.6076 26.9034 65.6089 26.6895 67.792C26.4757 69.9751 23.5098 70.447 22.6352 68.4355L22.3976 67.8873L21.9651 68.2973C20.3776 69.8083 17.7397 68.283 18.4811 66.1141C19.2226 63.9501 24.0706 59.6028 24.0706 59.6028V59.6076Z" fill="#FFCAE8" />
        <path d="M23.7993 61.4046C23.7613 60.4465 23.8659 59.6648 24.0275 59.6553C24.1891 59.6505 24.3507 60.4227 24.3887 61.3808C24.4267 62.3389 24.3222 63.1206 24.1606 63.1302C23.9989 63.1349 23.8373 62.3627 23.7993 61.4046Z" fill="#FFD685" />
        <path d="M23.6096 61.3617C24.0184 60.4942 24.2275 59.7315 24.0802 59.6648C23.9328 59.5933 23.4813 60.2416 23.0773 61.1139C22.6685 61.9814 22.4594 62.7441 22.6067 62.8108C22.7541 62.8823 23.2056 62.234 23.6096 61.3617Z" fill="#FFD685" />
        <path d="M24.0229 59.641C24.0229 59.641 30.5963 60.4227 32.3264 61.7621C34.0565 63.1016 32.8778 65.8758 30.7151 65.5516L30.1258 65.4611L30.2446 66.0474C30.6771 68.1971 27.9822 69.6272 26.5515 67.8492C25.1209 66.0664 24.0229 59.641 24.0229 59.641Z" fill="#FFA1D2" />
        <path d="M25.4014 60.8231C24.5696 60.3464 23.966 59.8411 24.0468 59.6981C24.1276 59.5551 24.8691 59.8268 25.6961 60.3083C26.5279 60.7849 27.1315 61.2902 27.0507 61.4332C26.9699 61.5762 26.2284 61.3045 25.4014 60.8231Z" fill="#FFD685" />
        <path d="M25.2637 60.9661C24.7456 60.1605 24.2133 59.5742 24.0802 59.6648C23.9424 59.7553 24.2513 60.4799 24.7694 61.2854C25.2874 62.091 25.8198 62.6773 25.9529 62.5868C26.0907 62.4962 25.7818 61.7717 25.2637 60.9661Z" fill="#FFD685" />
        <path d="M24.0327 59.7029C24.0327 59.7029 27.8399 54.2737 29.8409 53.3823C31.8419 52.491 33.7145 54.8505 32.398 56.6046L32.0415 57.0813L32.6118 57.2576C34.7032 57.9011 34.6794 60.9566 32.436 61.3713C30.1926 61.7907 24.0327 59.7029 24.0327 59.7029Z" fill="#FFCAE8" />
        <path d="M25.7245 59.0499C24.9117 59.5552 24.1797 59.8507 24.0942 59.7125C24.0086 59.5742 24.598 59.0499 25.4108 58.5446C26.2235 58.0394 26.9555 57.7438 27.041 57.8821C27.1266 58.0203 26.5372 58.5446 25.7245 59.0499Z" fill="#FFD685" />
        <path d="M25.7816 59.2358C24.8263 59.3073 24.0658 59.498 24.0753 59.6648C24.0895 59.8269 24.8738 59.9031 25.8244 59.8269C26.775 59.7506 27.5402 59.5647 27.5307 59.3979C27.5164 59.2358 26.7322 59.1595 25.7816 59.2358Z" fill="#FFD685" />
        <path d="M24.085 59.722C24.085 59.722 21.3568 53.6731 21.6087 51.4947C21.8606 49.3163 24.836 48.8969 25.6773 50.9227L25.9054 51.4756L26.3474 51.0705C27.9635 49.588 30.5728 51.161 29.7934 53.3108C29.0139 55.4606 24.0898 59.722 24.0898 59.722H24.085Z" fill="#FFA1D2" />
        <path d="M24.3848 57.9297C24.4038 58.8878 24.2897 59.6695 24.1234 59.6743C23.957 59.6791 23.8097 58.9021 23.7906 57.944C23.7716 56.9859 23.8857 56.2042 24.0521 56.1994C24.2137 56.1994 24.3658 56.9716 24.3848 57.9297Z" fill="#FFD685" />
        <path d="M24.5796 57.9726C24.1566 58.8354 23.9332 59.5933 24.0805 59.6648C24.2279 59.7363 24.6889 59.0976 25.1119 58.2348C25.5349 57.372 25.7583 56.6141 25.611 56.5426C25.4636 56.4711 25.0026 57.1099 24.5796 57.9726Z" fill="#FFD685" />
        <path d="M43.6622 89.0466C43.6622 89.0466 14.4457 96.5112 5.23438 61.867C5.23438 61.867 39.4463 57.0526 43.6622 89.0466Z" fill="url(#paint1_linear_861_15485)" />
        <path d="M38.7331 53.5539C38.7331 53.5539 31.4943 52.9581 29.5408 51.5567C27.5874 50.1553 28.7709 47.0665 31.1568 47.3382L31.808 47.4144L31.6559 46.7757C31.0998 44.4353 33.9944 42.7574 35.6342 44.6545C37.2739 46.5517 38.7331 53.5539 38.7331 53.5539Z" fill="#FFCAE8" />
        <path d="M37.1792 52.3146C38.1061 52.8056 38.7952 53.3347 38.7097 53.492C38.6241 53.6493 37.8066 53.3823 36.875 52.8914C35.9482 52.4004 35.259 51.8713 35.3446 51.714C35.4301 51.5567 36.2476 51.8236 37.1792 52.3146Z" fill="#FFD685" />
        <path d="M37.3218 52.1525C37.9207 53.0153 38.5243 53.635 38.6716 53.5349C38.819 53.43 38.453 52.6483 37.8541 51.7807C37.2553 50.918 36.6516 50.2983 36.5043 50.3984C36.3569 50.5033 36.7229 51.285 37.3218 52.1525Z" fill="#FFD685" />
        <path d="M38.7237 53.492C38.7237 53.492 34.6504 59.5218 32.4736 60.5371C30.2967 61.5524 28.1959 59.0022 29.6028 57.0527L29.9878 56.5188L29.3604 56.3377C27.0552 55.6751 27.0171 52.3241 29.4744 51.8189C31.927 51.3184 38.7237 53.4872 38.7237 53.4872V53.492Z" fill="#FFA1D2" />
        <path d="M36.8845 54.2403C37.7686 53.6683 38.5623 53.3299 38.6574 53.4824C38.7524 53.6349 38.1155 54.2165 37.2315 54.7885C36.3474 55.3605 35.5537 55.6989 35.4586 55.5464C35.3636 55.3938 36.0005 54.8123 36.8845 54.2403Z" fill="#FFD685" />
        <path d="M36.8135 54.0354C37.8591 53.9353 38.6956 53.7112 38.6766 53.5349C38.6576 53.3537 37.7973 53.2918 36.7517 53.3919C35.706 53.492 34.8695 53.716 34.8885 53.8924C34.9075 54.0735 35.7678 54.1355 36.8135 54.0354Z" fill="#FFD685" />
        <path d="M38.6668 53.4681C38.6668 53.4681 41.7752 60.0509 41.5423 62.4485C41.3094 64.8462 38.0536 65.361 37.0935 63.154L36.8321 62.5534L36.3568 63.0062C34.6125 64.6603 31.7179 62.9872 32.5354 60.6134C33.3529 58.2396 38.6668 53.4729 38.6668 53.4729V53.4681Z" fill="#FFCAE8" />
        <path d="M38.3723 55.4415C38.3343 54.3881 38.4436 53.5301 38.6242 53.5253C38.8048 53.5206 38.9807 54.3643 39.0234 55.4177C39.0615 56.4711 38.9521 57.3291 38.7715 57.3339C38.5909 57.3387 38.4151 56.495 38.3723 55.4415Z" fill="#FFD685" />
        <path d="M38.1584 55.3986C38.6052 54.4453 38.8381 53.6111 38.6718 53.5348C38.5102 53.4586 38.0159 54.1688 37.5691 55.1221C37.1223 56.0755 36.8894 56.9097 37.0557 56.9859C37.2174 57.0622 37.7117 56.352 38.1584 55.3986Z" fill="#FFD685" />
        <path d="M38.6147 53.5063C38.6147 53.5063 45.825 54.3643 47.7262 55.8372C49.6274 57.3101 48.3298 60.3512 45.9581 59.9937L45.3117 59.8984L45.44 60.5419C45.9106 62.9014 42.959 64.4744 41.3857 62.5153C39.8125 60.5609 38.6147 53.511 38.6147 53.511V53.5063Z" fill="#FFA1D2" />
        <path d="M40.1263 54.8028C39.2137 54.2785 38.5483 53.7255 38.6386 53.5682C38.7289 53.4109 39.5369 53.7112 40.4495 54.2356C41.362 54.7599 42.0275 55.3128 41.9371 55.4701C41.8468 55.6274 41.0388 55.3271 40.1263 54.8028Z" fill="#FFD685" />
        <path d="M39.974 54.9601C39.4084 54.0735 38.8238 53.4348 38.6717 53.5301C38.5196 53.6254 38.8571 54.4262 39.4274 55.3128C39.9931 56.1994 40.5777 56.8382 40.7298 56.7428C40.8819 56.6475 40.5444 55.8467 39.974 54.9601Z" fill="#FFD685" />
        <path d="M38.624 53.573C38.624 53.573 42.8019 47.6146 44.9978 46.6375C47.1889 45.6603 49.2469 48.2486 47.802 50.1743L47.4075 50.6987L48.0349 50.8893C50.3306 51.5948 50.3069 54.9458 47.8448 55.4034C45.3828 55.861 38.624 53.573 38.624 53.573Z" fill="#FFCAE8" />
        <path d="M40.478 52.858C39.5845 53.4109 38.786 53.735 38.6909 53.5825C38.5959 53.43 39.2423 52.858 40.1358 52.305C41.0294 51.7521 41.8279 51.428 41.9229 51.5805C42.018 51.733 41.3668 52.305 40.478 52.858Z" fill="#FFD685" />
        <path d="M40.5442 53.0629C39.4986 53.144 38.6573 53.3537 38.6716 53.5301C38.6858 53.7112 39.5461 53.7875 40.5918 53.7112C41.6374 53.6349 42.4787 53.4204 42.4644 53.2441C42.4502 53.0629 41.5899 52.9867 40.5442 53.0629Z" fill="#FFD685" />
        <path d="M38.6859 53.5968C38.6859 53.5968 35.6915 46.9616 35.9671 44.5687C36.2428 42.1759 39.5081 41.7182 40.4302 43.9395L40.6821 44.5449L41.1669 44.1016C42.9398 42.4762 45.8058 44.2017 44.9455 46.5612C44.09 48.9207 38.6859 53.592 38.6859 53.592V53.5968Z" fill="#FFA1D2" />
        <path d="M39.014 51.6282C39.0378 52.6816 38.9094 53.5396 38.7288 53.5396C38.5482 53.5396 38.3866 52.6911 38.3676 51.6377C38.3438 50.5843 38.4721 49.7263 38.6528 49.7263C38.8334 49.7263 38.995 50.5747 39.014 51.6282Z" fill="#FFD685" />
        <path d="M39.2231 51.6758C38.7573 52.6196 38.5149 53.4538 38.6765 53.5301C38.8381 53.6111 39.3419 52.9056 39.8077 51.9618C40.2735 51.018 40.5159 50.1839 40.3543 50.1076C40.1927 50.0266 39.6889 50.732 39.2231 51.6758Z" fill="#FFD685" />
        <path d="M114.824 59.6839C114.824 59.6839 108.227 59.1405 106.449 57.863C104.672 56.5855 105.751 53.7732 107.923 54.021L108.517 54.0878L108.379 53.5062C107.871 51.3708 110.509 49.8454 112.006 51.571C113.503 53.3013 114.829 59.6791 114.829 59.6791L114.824 59.6839Z" fill="#FFCAE8" />
        <path d="M113.403 58.5542C114.249 59.0022 114.872 59.4837 114.796 59.6267C114.72 59.7697 113.973 59.5266 113.123 59.0785C112.277 58.6304 111.654 58.149 111.73 58.006C111.806 57.863 112.552 58.1061 113.403 58.5542Z" fill="#FFD685" />
        <path d="M113.536 58.4064C114.082 59.1929 114.634 59.7601 114.767 59.6648C114.9 59.5695 114.567 58.8545 114.021 58.068C113.474 57.2815 112.923 56.7142 112.79 56.8096C112.656 56.9049 112.989 57.6199 113.536 58.4064Z" fill="#FFD685" />
        <path d="M114.81 59.6267C114.81 59.6267 111.098 65.1226 109.116 66.0474C107.134 66.9721 105.218 64.6507 106.502 62.8728L106.853 62.3866L106.278 62.2197C104.177 61.6144 104.144 58.5589 106.383 58.1013C108.621 57.6437 114.815 59.6219 114.815 59.6219L114.81 59.6267Z" fill="#FFA1D2" />
        <path d="M113.132 60.3083C113.935 59.7887 114.662 59.4789 114.748 59.6171C114.838 59.7554 114.254 60.2892 113.45 60.8088C112.647 61.3284 111.92 61.6382 111.834 61.5C111.744 61.3617 112.329 60.8279 113.132 60.3083Z" fill="#FFD685" />
        <path d="M113.07 60.1224C114.026 60.0318 114.782 59.8269 114.767 59.6648C114.753 59.5027 113.969 59.4408 113.013 59.5313C112.058 59.6219 111.302 59.8269 111.317 59.9889C111.331 60.151 112.115 60.213 113.07 60.1224Z" fill="#FFD685" />
        <path d="M114.758 59.6076C114.758 59.6076 117.59 65.6089 117.377 67.792C117.163 69.9751 114.197 70.447 113.322 68.4355L113.085 67.8873L112.652 68.2973C111.065 69.8083 108.427 68.283 109.168 66.1141C109.91 63.9501 114.758 59.6028 114.758 59.6028V59.6076Z" fill="#FFCAE8" />
        <path d="M114.491 61.4046C114.453 60.4465 114.558 59.6648 114.719 59.6553C114.881 59.6505 115.043 60.4227 115.081 61.3808C115.119 62.3389 115.014 63.1206 114.852 63.1302C114.691 63.1349 114.529 62.3627 114.491 61.4046Z" fill="#FFD685" />
        <path d="M114.296 61.3617C114.705 60.4942 114.914 59.7315 114.767 59.6648C114.619 59.5933 114.168 60.2416 113.764 61.1139C113.355 61.9814 113.146 62.7441 113.293 62.8108C113.441 62.8823 113.892 62.234 114.296 61.3617Z" fill="#FFD685" />
        <path d="M114.715 59.641C114.715 59.641 121.288 60.4227 123.018 61.7621C124.748 63.1016 123.57 65.8758 121.407 65.5516L120.818 65.4611L120.936 66.0474C121.369 68.1971 118.674 69.6272 117.243 67.8492C115.813 66.0664 114.715 59.641 114.715 59.641Z" fill="#FFA1D2" />
        <path d="M116.088 60.8231C115.257 60.3464 114.653 59.8411 114.734 59.6981C114.815 59.5551 115.556 59.8268 116.383 60.3083C117.215 60.7849 117.818 61.2902 117.738 61.4332C117.657 61.5762 116.915 61.3045 116.088 60.8231Z" fill="#FFD685" />
        <path d="M115.951 60.9661C115.433 60.1605 114.9 59.5742 114.767 59.6648C114.629 59.7553 114.938 60.4799 115.456 61.2854C115.974 62.091 116.507 62.6773 116.64 62.5868C116.778 62.4962 116.469 61.7717 115.951 60.9661Z" fill="#FFD685" />
        <path d="M114.72 59.7029C114.72 59.7029 118.527 54.2737 120.528 53.3823C122.529 52.491 124.402 54.8505 123.085 56.6046L122.728 57.0813L123.299 57.2576C125.39 57.9011 125.366 60.9566 123.123 61.3713C120.88 61.7907 114.72 59.7029 114.72 59.7029Z" fill="#FFCAE8" />
        <path d="M116.411 59.0499C115.599 59.5552 114.867 59.8507 114.781 59.7125C114.696 59.5742 115.285 59.0499 116.098 58.5446C116.911 58.0394 117.642 57.7438 117.728 57.8821C117.814 58.0203 117.224 58.5446 116.411 59.0499Z" fill="#FFD685" />
        <path d="M116.473 59.2358C115.518 59.3073 114.758 59.498 114.767 59.6648C114.781 59.8269 115.566 59.9031 116.516 59.8269C117.467 59.7506 118.232 59.5647 118.223 59.3979C118.208 59.2358 117.424 59.1595 116.473 59.2358Z" fill="#FFD685" />
        <path d="M114.777 59.722C114.777 59.722 112.049 53.6731 112.301 51.4947C112.553 49.3163 115.528 48.8969 116.369 50.9227L116.597 51.4756L117.039 51.0705C118.655 49.588 121.265 51.161 120.485 53.3108C119.706 55.4606 114.782 59.722 114.782 59.722H114.777Z" fill="#FFA1D2" />
        <path d="M115.076 57.9297C115.095 58.8878 114.981 59.6695 114.815 59.6743C114.648 59.6791 114.501 58.9021 114.482 57.944C114.463 56.9859 114.577 56.2042 114.743 56.1994C114.905 56.1994 115.057 56.9716 115.076 57.9297Z" fill="#FFD685" />
        <path d="M115.266 57.9726C114.843 58.8354 114.62 59.5933 114.767 59.6648C114.914 59.7363 115.375 59.0976 115.798 58.2348C116.221 57.372 116.445 56.6141 116.298 56.5426C116.15 56.4711 115.689 57.1099 115.266 57.9726Z" fill="#FFD685" />
        <path d="M102.609 53.5539C102.609 53.5539 95.3698 52.9581 93.4163 51.5567C91.4628 50.1553 92.6463 47.0665 95.0323 47.3382L95.6835 47.4144L95.5314 46.7757C94.9753 44.4353 97.8699 42.7574 99.5096 44.6545C101.149 46.5517 102.609 53.5539 102.609 53.5539Z" fill="#FFCAE8" />
        <path d="M101.055 52.3146C101.982 52.8056 102.671 53.3347 102.585 53.492C102.5 53.6493 101.682 53.3823 100.751 52.8914C99.8237 52.4004 99.1345 51.8713 99.2201 51.714C99.3056 51.5567 100.123 51.8236 101.055 52.3146Z" fill="#FFD685" />
        <path d="M101.197 52.1525C101.796 53.0153 102.4 53.635 102.547 53.5349C102.694 53.43 102.328 52.6483 101.73 51.7807C101.131 50.918 100.527 50.2983 100.38 50.3984C100.232 50.5033 100.598 51.285 101.197 52.1525Z" fill="#FFD685" />
        <path d="M102.599 53.492C102.599 53.492 98.5259 59.5218 96.3491 60.5371C94.1722 61.5524 92.0714 59.0022 93.4783 57.0527L93.8632 56.5188L93.2358 56.3377C90.9306 55.6751 90.8926 52.3241 93.3499 51.8189C95.8025 51.3184 102.599 53.4872 102.599 53.4872V53.492Z" fill="#FFA1D2" />
        <path d="M100.76 54.2403C101.644 53.6683 102.438 53.3299 102.533 53.4824C102.628 53.6349 101.991 54.2165 101.107 54.7885C100.223 55.3605 99.4292 55.6989 99.3341 55.5464C99.239 55.3938 99.8759 54.8123 100.76 54.2403Z" fill="#FFD685" />
        <path d="M100.688 54.0354C101.734 53.9353 102.571 53.7112 102.552 53.5349C102.533 53.3537 101.672 53.2918 100.627 53.3919C99.581 53.492 98.7445 53.716 98.7635 53.8924C98.7825 54.0735 99.6428 54.1355 100.688 54.0354Z" fill="#FFD685" />
        <path d="M102.538 53.4681C102.538 53.4681 105.646 60.0509 105.413 62.4485C105.181 64.8462 101.925 65.361 100.965 63.154L100.703 62.5534L100.228 63.0062C98.4836 64.6603 95.589 62.9872 96.4065 60.6134C97.224 58.2396 102.538 53.4729 102.538 53.4729V53.4681Z" fill="#FFCAE8" />
        <path d="M102.248 55.4415C102.21 54.3881 102.319 53.5301 102.5 53.5253C102.68 53.5206 102.856 54.3643 102.899 55.4177C102.937 56.4711 102.828 57.3291 102.647 57.3339C102.466 57.3387 102.291 56.495 102.248 55.4415Z" fill="#FFD685" />
        <path d="M102.034 55.3986C102.481 54.4453 102.714 53.6111 102.547 53.5348C102.386 53.4586 101.891 54.1688 101.445 55.1221C100.998 56.0755 100.765 56.9097 100.931 56.9859C101.093 57.0622 101.587 56.352 102.034 55.3986Z" fill="#FFD685" />
        <path d="M102.49 53.5063C102.49 53.5063 109.701 54.3643 111.602 55.8372C113.503 57.3101 112.205 60.3512 109.834 59.9937L109.187 59.8984L109.316 60.5419C109.786 62.9014 106.834 64.4744 105.261 62.5153C103.688 60.5609 102.49 53.511 102.49 53.511V53.5063Z" fill="#FFA1D2" />
        <path d="M104.002 54.8028C103.089 54.2785 102.424 53.7255 102.514 53.5682C102.604 53.4109 103.412 53.7112 104.325 54.2356C105.238 54.7599 105.903 55.3128 105.813 55.4701C105.722 55.6274 104.914 55.3271 104.002 54.8028Z" fill="#FFD685" />
        <path d="M103.85 54.9601C103.284 54.0735 102.699 53.4348 102.547 53.5301C102.395 53.6254 102.733 54.4262 103.303 55.3128C103.869 56.1994 104.453 56.8382 104.605 56.7428C104.757 56.6475 104.42 55.8467 103.85 54.9601Z" fill="#FFD685" />
        <path d="M102.5 53.573C102.5 53.573 106.677 47.6146 108.873 46.6375C111.064 45.6603 113.122 48.2486 111.678 50.1743L111.283 50.6987L111.91 50.8893C114.206 51.5948 114.182 54.9458 111.72 55.4034C109.258 55.861 102.5 53.573 102.5 53.573Z" fill="#FFCAE8" />
        <path d="M104.354 52.858C103.46 53.4109 102.661 53.735 102.566 53.5825C102.471 53.43 103.118 52.858 104.011 52.305C104.905 51.7521 105.703 51.428 105.798 51.5805C105.893 51.733 105.242 52.305 104.354 52.858Z" fill="#FFD685" />
        <path d="M104.42 53.0629C103.374 53.144 102.533 53.3537 102.547 53.5301C102.561 53.7112 103.422 53.7875 104.467 53.7112C105.513 53.6349 106.354 53.4204 106.34 53.2441C106.326 53.0629 105.465 52.9867 104.42 53.0629Z" fill="#FFD685" />
        <path d="M102.561 53.5968C102.561 53.5968 99.567 46.9616 99.8426 44.5687C100.118 42.1759 103.384 41.7182 104.306 43.9395L104.558 44.5449L105.042 44.1016C106.815 42.4762 109.681 44.2017 108.821 46.5612C107.965 48.9207 102.561 53.592 102.561 53.592V53.5968Z" fill="#FFA1D2" />
        <path d="M102.889 51.6282C102.913 52.6816 102.785 53.5396 102.604 53.5396C102.424 53.5396 102.262 52.6911 102.243 51.6377C102.219 50.5843 102.348 49.7263 102.528 49.7263C102.709 49.7263 102.87 50.5747 102.889 51.6282Z" fill="#FFD685" />
        <path d="M103.099 51.6758C102.633 52.6196 102.39 53.4538 102.552 53.5301C102.714 53.6111 103.217 52.9056 103.683 51.9618C104.149 51.018 104.391 50.1839 104.23 50.1076C104.068 50.0266 103.564 50.732 103.099 51.6758Z" fill="#FFD685" />
        <path d="M93.9678 89.0466C93.9678 89.0466 123.184 96.5064 132.396 61.867C132.396 61.867 98.1837 57.0526 93.9678 89.0466Z" fill="url(#paint2_linear_861_15485)" />
        <path d="M63.4682 94.6522C63.4682 94.6522 30.587 96.8163 27.5879 57.7438C27.5879 57.7438 65.3123 59.4217 63.4682 94.6522Z" fill="url(#paint3_linear_861_15485)" />
        <path d="M50.816 63.9596C50.816 63.9596 41.4003 63.1874 38.8622 61.3617C36.3241 59.5409 37.8641 55.5225 40.963 55.8753L41.8091 55.9706L41.6094 55.1412C40.8822 52.0953 44.6466 49.9122 46.7854 52.3813C48.9195 54.8504 50.816 63.9596 50.816 63.9596Z" fill="#FFCAE8" />
        <path d="M48.7959 62.3485C50.0032 62.9872 50.8967 63.6736 50.7874 63.8833C50.6781 64.0883 49.6087 63.7403 48.4014 63.1016C47.1942 62.4629 46.3006 61.7764 46.4099 61.5667C46.5192 61.357 47.5887 61.7097 48.7959 62.3485Z" fill="#FFD685" />
        <path d="M48.9815 62.1339C49.761 63.2589 50.55 64.0644 50.7401 63.931C50.9302 63.7975 50.455 62.7774 49.6755 61.6525C48.896 60.5276 48.107 59.722 47.9169 59.8555C47.7267 59.9889 48.202 61.009 48.9815 62.1339Z" fill="#FFD685" />
        <path d="M50.8014 63.8785C50.8014 63.8785 45.5018 71.7245 42.6738 73.0449C39.8458 74.3652 37.1128 71.0476 38.9427 68.5118L39.4418 67.8206L38.6242 67.587C35.6204 66.7243 35.5776 62.3627 38.7716 61.7097C41.9656 61.0567 50.8061 63.8785 50.8061 63.8785H50.8014Z" fill="#FFA1D2" />
        <path d="M48.4063 64.8509C49.5565 64.1121 50.5879 63.6688 50.7162 63.8642C50.8446 64.0597 50.0128 64.8223 48.8626 65.5612C47.7123 66.3 46.6809 66.7433 46.5526 66.5479C46.4243 66.3524 47.2561 65.5898 48.4063 64.8509Z" fill="#FFD685" />
        <path d="M48.3156 64.584C49.675 64.4553 50.7586 64.1645 50.7396 63.931C50.7159 63.6974 49.5989 63.6116 48.2348 63.7403C46.8755 63.869 45.7918 64.1598 45.8108 64.3933C45.8346 64.6269 46.9515 64.7127 48.3156 64.584Z" fill="#FFD685" />
        <path d="M50.7259 63.8499C50.7259 63.8499 54.7707 72.4109 54.4665 75.5283C54.1623 78.6457 49.9274 79.3178 48.6773 76.4483L48.3351 75.6665L47.7172 76.2528C45.4501 78.4074 41.6857 76.229 42.7456 73.1402C43.8055 70.0514 50.7259 63.8499 50.7259 63.8499Z" fill="#FFCAE8" />
        <path d="M50.3455 66.4144C50.2932 65.0464 50.4406 63.9262 50.6735 63.9167C50.9064 63.9072 51.1393 65.0083 51.1915 66.3811C51.2438 67.7491 51.0965 68.8693 50.8636 68.8788C50.6307 68.8883 50.3978 67.7872 50.3455 66.4144Z" fill="#FFD685" />
        <path d="M50.0699 66.3572C50.6498 65.1179 50.9492 64.0311 50.7401 63.931C50.5309 63.8309 49.8893 64.7556 49.3047 65.9949C48.7248 67.2343 48.4254 68.3211 48.6345 68.4212C48.8436 68.5213 49.4853 67.5965 50.0699 66.3572Z" fill="#FFD685" />
        <path d="M50.6636 63.8976C50.6636 63.8976 60.046 65.013 62.5175 66.9292C64.9891 68.8407 63.3065 72.8018 60.2171 72.3346L59.3758 72.2059L59.5421 73.0449C60.1553 76.1146 56.3149 78.1595 54.2711 75.6141C52.2273 73.0687 50.6636 63.8976 50.6636 63.8976Z" fill="#FFA1D2" />
        <path d="M52.6267 65.585C51.4432 64.9034 50.5782 64.1836 50.6922 63.9786C50.8063 63.7784 51.8615 64.1645 53.045 64.8509C54.2284 65.5326 55.0935 66.2523 54.9794 66.4573C54.8654 66.6575 53.8102 66.2714 52.6267 65.585Z" fill="#FFD685" />
        <path d="M52.4318 65.79C51.695 64.6365 50.9346 63.8071 50.7397 63.931C50.5448 64.0597 50.9821 65.0941 51.7236 66.2476C52.4603 67.4011 53.2208 68.2305 53.4156 68.1066C53.6105 67.9779 53.1732 66.9435 52.4318 65.79Z" fill="#FFD685" />
        <path d="M50.6732 63.9834C50.6732 63.9834 56.1106 56.2328 58.9624 54.9601C61.8142 53.6874 64.4901 57.0527 62.6127 59.5599L62.0994 60.2416L62.9121 60.4894C65.897 61.4046 65.8637 65.7662 62.6602 66.362C59.4567 66.9578 50.6685 63.9786 50.6685 63.9786L50.6732 63.9834Z" fill="#FFCAE8" />
        <path d="M53.0877 63.0539C51.9232 63.7737 50.8823 64.1979 50.7635 63.9977C50.6399 63.7975 51.4859 63.0539 52.6456 62.3341C53.8101 61.6144 54.851 61.1901 54.9698 61.3903C55.0934 61.5905 54.2474 62.3341 53.0877 63.0539Z" fill="#FFD685" />
        <path d="M53.173 63.3208C51.8089 63.4257 50.7205 63.6974 50.7395 63.931C50.7585 64.1645 51.8755 64.2694 53.2396 64.1645C54.6037 64.0597 55.6921 63.788 55.6731 63.5544C55.6541 63.3208 54.5371 63.216 53.173 63.3208Z" fill="#FFD685" />
        <path d="M50.7492 64.0168C50.7492 64.0168 46.8565 55.3843 47.213 52.2717C47.5695 49.159 51.8187 48.5632 53.0164 51.4566L53.3444 52.2431L53.9718 51.6663C56.277 49.5547 60.0033 51.795 58.8863 54.8648C57.7694 57.9345 50.7445 64.012 50.7445 64.012L50.7492 64.0168Z" fill="#FFA1D2" />
        <path d="M51.1773 61.4571C51.2059 62.8251 51.0395 63.9405 50.8066 63.9453C50.5737 63.95 50.3598 62.8442 50.3313 61.4714C50.3028 60.1033 50.4691 58.9879 50.702 58.9832C50.9349 58.9784 51.1488 60.0843 51.1726 61.4571H51.1773Z" fill="#FFD685" />
        <path d="M51.4529 61.519C50.8493 62.7488 50.5309 63.8309 50.74 63.931C50.9491 64.0358 51.6098 63.1206 52.2087 61.8908C52.8123 60.661 53.1307 59.579 52.9216 59.4789C52.7125 59.374 52.0566 60.2892 51.4529 61.519Z" fill="#FFD685" />
        <path d="M74.1104 94.6522C74.1104 94.6522 106.992 96.8163 109.991 57.7438C109.991 57.7438 72.2662 59.4217 74.1104 94.6522Z" fill="url(#paint4_linear_861_15485)" />
        <path d="M86.2398 63.9596C86.2398 63.9596 76.8241 63.1874 74.2861 61.3617C71.748 59.5409 73.2879 55.5225 76.3869 55.8753L77.2329 55.9706L77.0333 55.1412C76.3061 52.0953 80.0704 49.9122 82.2093 52.3813C84.3434 54.8504 86.2398 63.9596 86.2398 63.9596Z" fill="#FFCAE8" />
        <path d="M84.2149 62.3485C85.4221 62.9872 86.3157 63.6736 86.2064 63.8833C86.0971 64.0883 85.0276 63.7403 83.8204 63.1016C82.6131 62.4629 81.7196 61.7764 81.8289 61.5667C81.9382 61.357 83.0076 61.7097 84.2149 62.3485Z" fill="#FFD685" />
        <path d="M84.4 62.1339C85.1795 63.2589 85.9685 64.0644 86.1586 63.931C86.3487 63.7975 85.8734 62.7774 85.0939 61.6525C84.3144 60.5276 83.5254 59.722 83.3353 59.8555C83.1452 59.9889 83.6205 61.009 84.4 62.1339Z" fill="#FFD685" />
        <path d="M86.2252 63.8785C86.2252 63.8785 80.9256 71.7245 78.0976 73.0449C75.2696 74.3652 72.5366 71.0476 74.3665 68.5118L74.8656 67.8206L74.0481 67.587C71.0442 66.7243 71.0014 62.3627 74.1954 61.7097C77.3894 61.0567 86.23 63.8785 86.23 63.8785H86.2252Z" fill="#FFA1D2" />
        <path d="M83.8296 64.8509C84.9798 64.1121 86.0112 63.6688 86.1396 63.8642C86.2679 64.0597 85.4361 64.8223 84.2859 65.5612C83.1357 66.3 82.1043 66.7433 81.976 66.5479C81.8476 66.3524 82.6794 65.5898 83.8296 64.8509Z" fill="#FFD685" />
        <path d="M83.7346 64.584C85.0939 64.4553 86.1776 64.1645 86.1586 63.931C86.1348 63.6974 85.0179 63.6116 83.6538 63.7403C82.2944 63.869 81.2107 64.1598 81.2297 64.3933C81.2535 64.6269 82.3705 64.7127 83.7346 64.584Z" fill="#FFD685" />
        <path d="M86.1443 63.8499C86.1443 63.8499 90.1891 72.4109 89.8849 75.5283C89.5807 78.6457 85.3458 79.3178 84.0958 76.4483L83.7536 75.6665L83.1357 76.2528C80.8685 78.4074 77.1042 76.229 78.1641 73.1402C79.224 70.0514 86.1443 63.8499 86.1443 63.8499Z" fill="#FFCAE8" />
        <path d="M85.764 66.4144C85.7117 65.0464 85.859 63.9262 86.0919 63.9167C86.3248 63.9072 86.5577 65.0083 86.61 66.3811C86.6623 67.7491 86.5149 68.8693 86.282 68.8788C86.0491 68.8883 85.8162 67.7872 85.764 66.4144Z" fill="#FFD685" />
        <path d="M85.4884 66.3572C86.0682 65.1179 86.3677 64.0311 86.1585 63.931C85.9494 63.8309 85.3078 64.7556 84.7231 65.9949C84.1433 67.2343 83.8438 68.3211 84.053 68.4212C84.2621 68.5213 84.9037 67.5965 85.4884 66.3572Z" fill="#FFD685" />
        <path d="M86.0825 63.8976C86.0825 63.8976 95.4649 65.013 97.9365 66.9292C100.408 68.8407 98.7254 72.8018 95.636 72.3346L94.7947 72.2059L94.9611 73.0449C95.5742 76.1146 91.7338 78.1595 89.69 75.6141C87.6462 73.0687 86.0825 63.8976 86.0825 63.8976Z" fill="#FFA1D2" />
        <path d="M88.0456 65.585C86.8621 64.9034 85.9971 64.1836 86.1112 63.9786C86.2252 63.7784 87.2804 64.1645 88.4639 64.8509C89.6474 65.5326 90.5124 66.2523 90.3984 66.4573C90.2843 66.6575 89.2291 66.2714 88.0456 65.585Z" fill="#FFD685" />
        <path d="M87.8507 65.79C87.114 64.6365 86.3535 63.8071 86.1586 63.931C85.9638 64.0597 86.401 65.0941 87.1425 66.2476C87.8792 67.4011 88.6397 68.2305 88.8346 68.1066C89.0294 67.9779 88.5922 66.9435 87.8507 65.79Z" fill="#FFD685" />
        <path d="M86.0922 63.9834C86.0922 63.9834 91.5296 56.2328 94.3814 54.9601C97.2331 53.6874 99.9091 57.0527 98.0317 59.5599L97.5183 60.2416L98.3311 60.4894C101.316 61.4046 101.283 65.7662 98.0792 66.362C94.8757 66.9578 86.0874 63.9786 86.0874 63.9786L86.0922 63.9834Z" fill="#FFCAE8" />
        <path d="M88.5066 63.0539C87.3421 63.7737 86.3012 64.1979 86.1824 63.9977C86.0588 63.7975 86.9049 63.0539 88.0646 62.3341C89.2291 61.6144 90.27 61.1901 90.3888 61.3903C90.5124 61.5905 89.6663 62.3341 88.5066 63.0539Z" fill="#FFD685" />
        <path d="M88.5925 63.3208C87.2284 63.4257 86.1399 63.6974 86.1589 63.931C86.1779 64.1645 87.2949 64.2694 88.659 64.1645C90.0231 64.0597 91.1115 63.788 91.0925 63.5544C91.0735 63.3208 89.9566 63.216 88.5925 63.3208Z" fill="#FFD685" />
        <path d="M86.1731 64.0168C86.1731 64.0168 82.2804 55.3843 82.6368 52.2717C82.9933 49.159 87.2425 48.5632 88.4402 51.4566L88.7682 52.2431L89.3956 51.6663C91.7008 49.5547 95.4271 51.795 94.3102 54.8648C93.1932 57.9345 86.1683 64.012 86.1683 64.012L86.1731 64.0168Z" fill="#FFA1D2" />
        <path d="M86.5963 61.4571C86.6248 62.8251 86.4584 63.9405 86.2256 63.9453C85.9927 63.95 85.7788 62.8442 85.7503 61.4714C85.7217 60.1033 85.8881 58.9879 86.121 58.9832C86.3539 58.9784 86.5678 60.0843 86.5915 61.4571H86.5963Z" fill="#FFD685" />
        <path d="M86.8719 61.519C86.2682 62.7488 85.9498 63.8309 86.1589 63.931C86.3681 64.0358 87.0287 63.1206 87.6276 61.8908C88.2312 60.661 88.5497 59.579 88.3406 59.4789C88.1314 59.374 87.4755 60.2892 86.8719 61.519Z" fill="#FFD685" />
        <path d="M87.4942 78.579C87.4942 88.4031 78.706 96.3682 67.8597 96.3682C57.0134 96.3682 48.2251 88.4031 48.2251 78.579C48.2251 68.7549 67.8644 43.7298 67.8644 43.7298C67.8644 43.7298 87.499 68.7549 87.499 78.579H87.4942Z" fill="url(#paint5_linear_861_15485)" />
      </g>
      <defs>
        <filter id="filter0_d_861_15485" x="0.416829" y="42.5" width="134.896" height="83.125" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="2.91667" />
          <feGaussianBlur stdDeviation="1.45833" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_861_15485" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_861_15485" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_861_15485" x1="62.9982" y1="28.5002" x2="62.9982" y2="65.8847" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFE92E" />
          <stop offset="1" stop-color="#FB8B1C" />
        </linearGradient>
        <linearGradient id="paint1_linear_861_15485" x1="7.18785" y1="75.6665" x2="45.6157" y2="75.6665" gradientUnits="userSpaceOnUse">
          <stop offset="0.36" stop-color="#006837" />
          <stop offset="0.62" stop-color="#00753B" />
          <stop offset="1" stop-color="#009245" />
        </linearGradient>
        <linearGradient id="paint2_linear_861_15485" x1="132.277" y1="75.6665" x2="91.2681" y2="75.6665" gradientUnits="userSpaceOnUse">
          <stop offset="0.36" stop-color="#006837" />
          <stop offset="0.62" stop-color="#00753B" />
          <stop offset="1" stop-color="#009245" />
        </linearGradient>
        <linearGradient id="paint3_linear_861_15485" x1="27.5926" y1="76.2099" x2="63.5347" y2="76.2099" gradientUnits="userSpaceOnUse">
          <stop offset="0.36" stop-color="#1D9050" />
          <stop offset="0.47" stop-color="#2B9754" />
          <stop offset="0.67" stop-color="#4FAA60" />
          <stop offset="0.94" stop-color="#8BCA74" />
          <stop offset="1" stop-color="#98D179" />
        </linearGradient>
        <linearGradient id="paint4_linear_861_15485" x1="105.604" y1="76.2099" x2="72.4754" y2="76.2099" gradientUnits="userSpaceOnUse">
          <stop offset="0.36" stop-color="#1D9050" />
          <stop offset="0.47" stop-color="#2B9754" />
          <stop offset="0.67" stop-color="#4FAA60" />
          <stop offset="0.94" stop-color="#8BCA74" />
          <stop offset="1" stop-color="#98D179" />
        </linearGradient>
        <linearGradient id="paint5_linear_861_15485" x1="48.2299" y1="70.0466" x2="87.4942" y2="70.0466" gradientUnits="userSpaceOnUse">
          <stop offset="0.01" stop-color="#1D9050" />
          <stop offset="0.18" stop-color="#2B9754" />
          <stop offset="0.49" stop-color="#4FAA60" />
          <stop offset="0.91" stop-color="#8BCA74" />
          <stop offset="1" stop-color="#98D179" />
        </linearGradient>
      </defs>
    </svg>
  );
}
