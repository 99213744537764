import "./CreateYourKratong.stlye.css";
import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useCallback } from "react";
import {
  CREATE_STATE,
  KRATONGS,
  DETAIL_KRATONGS,
} from "../configure/modalState";
import CO2Level from "../../co2/CO2Level";
import AnimateClickableComponent from "../../animateClickableComponent/AnimateClickableComponent";
import CustomButton from "../../button/CustomButton";
import CustomInput from "../../input/CustomInput";
import { KratongContext } from "../../../store/KratongContext";

export default function CreateKratong({ dispatch, setCreateState, data }) {
  const [disabledButton, setDisabledButton] = useState(
    data?.wish?.length > 0 ? false : true
  );
  const [selectedKratong, setSelectedKratong] = useState(
    data.noTypeKrathong === "" ? KRATONGS[0]?.key : data.noTypeKrathong
  );
  const {isSafari} = useContext(KratongContext)

  const onButtonClick = useCallback(() => {
    dispatch({
      type: "SET_KRATONG",
      data: { kratong: selectedKratong, text: data?.wish },
    });
    setCreateState(CREATE_STATE[3]);
  }, [data?.wish, dispatch, selectedKratong, setCreateState]);

  const onHandleData = (e) => {
    const value = e?.target?.value.replaceAll("\n", " ");
    const check = /^[a-zA-Zก-ฺเ-๏0-9\s!'()/&*,[#\-\].]+$/;
    if (
      value?.charAt(0) !== " " &&
      (value === "" || (value?.length <= 200 && check.test(value)))
    ) {
      dispatch({
        type: "SET_WISH",
        data: { wish: value },
      });
      value === "" ? setDisabledButton(true) : setDisabledButton(false);
    }
  };

  useEffect(() => {
    if (data?.noTypeKrathong && data?.wish) {
      setSelectedKratong(data?.noTypeKrathong);
      setDisabledButton(data?.wish?.length > 0 ? false : true);
    }
  }, [data]);

  return (
    <div>
      <h2 justify="center" align="center">
        Choose your Krathong
      </h2>

      <Row align="middle">
        <Col
          className="krathongs"
          xs={{ span: 24, order: 2 }}
          sm={{ span: 24, order: 2 }}
          md={{ span: 16, order: 1 }}
          lg={{ span: 16, order: 1 }}
          xl={{ span: 16, order: 1 }}
          xxl={{ span: 16, order: 1 }}
        >
          <Row
            justify="start"
            align="center"
            gutter={[{ xs: 8, sm: 8, md: 10, lg: 12, xl: 12, xxl: 12 }, 4]}
          >
            {KRATONGS?.map((image, index) => {
              return (
                <Col
                  className="gutter-row bouncein"
                  span={6}
                  onClick={(e) => setSelectedKratong(image?.key)}
                  key={index}
                  style={{"--delay": `${index*100}ms`}}
                >
                  <AnimateClickableComponent
                    className={`krathong ${
                      selectedKratong === image?.key ? "krathong-select" : ""
                    } ${!isSafari ? "k-safari" : ""} `}
                  >
                    <img src={image?.image} alt={image?.key} />
                  </AnimateClickableComponent>
                </Col>
              );
            })}
          </Row>
        </Col>
        <Col
          className="display-selected slidein"
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          md={{ span: 8, order: 2 }}
          lg={{ span: 8, order: 2 }}
          xl={{ span: 8, order: 2 }}
          xxl={{ span: 8, order: 2 }}
          style={{"--delay": "200ms"}}
        >
          <div className="display-selected-krathong-group">
            <div className="display-selected-krathong">
              <img
                src={KRATONGS.find((i) => i?.key === selectedKratong)?.image}
                alt={selectedKratong}
              />
            </div>
          </div>

          <div className="name-selected-krathong">
            {DETAIL_KRATONGS?.find((k) => k?.key === selectedKratong)?.name}
          </div>

          <div className="create-kratong-co2">
            <div className="text-co2-level">Eco-friendliness Score</div>

            <div className="display-selected_level">
              <CO2Level
                level={
                  DETAIL_KRATONGS?.find((k) => k?.key === selectedKratong)
                    ?.level
                }
                space="2px"
                height={30}
              />
            </div>
          </div>
        </Col>
      </Row>
      <CustomInput
        lineNo={3}
        onChange={onHandleData}
        placeholder="Write wishes for yourself or your loved ones, or express gratitude towards Goddess Ganga..."
        value={data?.wish}
        className="margin-top-1 slidein"
        maxLength={200}
        style={{"--delay": "300ms"}}
      />
      <CustomButton
        type="primary slidein"
        onClick={onButtonClick}
        disabled={disabledButton}
        className="margin-top-2"
        block
        style={{"--delay": "350ms"}}
      >
        Next Step
      </CustomButton>
    </div>
  );
}
