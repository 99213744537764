import React from 'react';

export default function Kratong5() {
  return (
    <svg width="137" height="108" viewBox="0 0 137 108" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="72.375" y="0.4375" width="2.1875" height="65.625" fill="#EBC2EE" />
      <rect x="76.0205" y="0.4375" width="2.1875" height="65.625" fill="#EBC2EE" />
      <rect x="63.0161" y="26.5419" width="6.79719" height="37.3845" fill="url(#paint0_linear_0_2)" />
      <path d="M62.9848 16.771C60.6057 23.5681 63.976 24.9842 65.9585 24.8426C72.3309 24.8426 71.0564 18.4702 70.2068 16.771C69.527 15.4115 67.0914 9.69055 65.9585 7C65.9585 8.27447 65.9585 8.27447 62.9848 16.771Z" fill="#FFE92E" />
      <path d="M62.9848 16.771C60.6057 23.5681 63.976 24.9842 65.9585 24.8426C72.3309 24.8426 71.0564 18.4702 70.2068 16.771C69.527 15.4115 67.0914 9.69055 65.9585 7C65.9585 8.27447 65.9585 8.27447 62.9848 16.771Z" fill="#FFE92E" />
      <path d="M62.9848 16.771C60.6057 23.5681 63.976 24.9842 65.9585 24.8426C72.3309 24.8426 71.0564 18.4702 70.2068 16.771C69.527 15.4115 67.0914 9.69055 65.9585 7C65.9585 8.27447 65.9585 8.27447 62.9848 16.771Z" fill="#FFE92E" />
      <path d="M64.4219 19.0735C62.8048 23.9261 65.0957 24.937 66.4432 24.8359C70.7746 24.8359 69.9084 20.2866 69.3308 19.0735C68.8688 18.103 67.2133 14.0187 66.4432 12.0979C66.4432 13.0077 66.4432 13.0077 64.4219 19.0735Z" fill="#FFA217" />
      <path d="M65.1126 21.3811C63.9576 24.2927 65.5939 24.8993 66.5564 24.8386C69.6503 24.8386 69.0315 22.109 68.619 21.3811C68.289 20.7988 67.1064 18.3483 66.5564 17.1958C66.5564 17.7417 66.5564 17.7417 65.1126 21.3811Z" fill="#FAEE8D" />
      <rect x="16.229" y="98.875" width="107.188" height="7.29167" fill="url(#paint1_linear_0_3)" />
      <path d="M72.1957 91.0104C72.2567 91.9103 74.5136 90.5604 75.8596 92.3482C77.2069 94.138 82.5579 98.5504 97.0866 100.872C111.615 103.194 119.426 99.0361 120.508 99.0729C121.591 99.1097 122.216 100.166 123.265 100.062C124.314 99.9552 123.12 98.0538 124.033 97.037C124.943 96.02 130.945 90.683 126.591 79.2453C122.24 67.8079 113.509 56.0111 110.095 57.7923C110.095 57.7923 111.148 55.0331 107.98 55.2724C107.98 55.2724 109.386 54.9931 109.467 53.2019C109.549 51.4107 107.951 50.7639 107.951 50.7639C107.951 50.7639 117.327 45.7414 118.995 42.1085C120.664 38.4757 115.011 34.6838 113.131 35.3825C111.25 36.0811 105.997 49.793 105.997 49.793L105.466 49.708C105.466 49.708 109.228 35.9639 104.526 35.1445C99.8267 34.3254 96.9794 34.2534 96.9075 37.2665C96.834 40.2794 103.602 49.8766 103.602 49.8766C103.602 49.8766 101.479 49.9679 100.872 51.2139C100.265 52.4598 100.692 54.0463 101.956 54.6517C101.956 54.6517 98.425 53.3284 99.7893 56.2834C99.7893 56.2834 91.019 56.6606 82.5375 66.7331C74.0584 76.801 73.776 85.3787 73.7988 87.3653C73.8213 89.3536 72.1347 90.1104 72.1959 91.0087L72.1957 91.0104ZM107.376 49.6916C107.376 49.6916 112.313 37.9581 114.824 38.4292C117.335 38.8986 119.124 41.3273 107.858 50.0885L107.376 49.6932L107.376 49.6916ZM104.667 49.1906C104.667 49.1906 100.437 41.9581 99.8978 38.1597C99.4815 35.2251 104.75 36.4228 105.035 37.5236C105.413 38.9916 105.502 44.3965 104.667 49.1906Z" fill="#88BFE5" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M124.033 97.0351C123.122 98.0521 124.315 99.9532 123.265 100.06C122.216 100.166 121.591 99.1078 120.509 99.071C119.426 99.0342 111.617 103.192 97.0868 100.87C82.5564 98.5482 77.2055 94.1357 75.8598 92.3462C74.5124 90.5565 72.2555 91.9064 72.1959 91.0084C72.1349 90.1085 73.8215 89.3516 73.7987 87.365C73.7868 86.2959 73.8654 83.3145 75.2607 79.2226C75.2451 79.4686 75.2234 79.7205 75.1851 79.9918C73.7676 90.1651 79.0794 81.5724 79.7499 82.0897C80.4204 82.6071 81.7104 85.5502 82.8495 84.3638C83.9887 83.1773 85.7141 82.6326 99.1458 83.7595C112.577 84.888 118.426 88.2686 119.573 87.9054C120.719 87.5406 121.808 88.3987 122.964 89.6796C124.12 90.9589 128.131 86.1343 128.131 86.1343C128.464 92.8922 124.737 96.2506 124.034 97.0353L124.033 97.0351Z" fill="url(#paint2_linear_0_2)" />
      <path style={{mixBlendMode:"screen"}} d="M110.943 65.3334C110.943 65.3334 110.473 57.987 115.654 61.7578C120.835 65.5287 126.729 79.7435 122.731 80.0629C118.732 80.3822 111.644 71.9951 111.644 71.9951C111.644 71.9951 110.399 83.0283 101.408 81.8605C92.4191 80.693 90.0721 65.5743 94.0788 62.6385C98.0854 59.7026 102.896 65.2116 102.896 65.2116C102.896 65.2116 102.962 59.1304 110.941 65.3331L110.943 65.3334Z" fill="url(#paint3_linear_0_2)" />
      <path opacity="0.5" d="M72.1957 91.0104C72.2567 91.9103 74.5136 90.5604 75.8596 92.3482C77.2069 94.138 82.5579 98.5504 97.0866 100.872C111.615 103.194 119.426 99.0361 120.508 99.0729C121.591 99.1097 122.216 100.166 123.265 100.062C124.314 99.9552 123.12 98.0538 124.033 97.037C124.943 96.02 130.945 90.683 126.591 79.2453C122.24 67.8079 113.509 56.0111 110.095 57.7923C110.095 57.7923 111.148 55.0331 107.98 55.2724C107.98 55.2724 109.386 54.9931 109.467 53.2019C109.549 51.4107 107.951 50.7639 107.951 50.7639C107.951 50.7639 117.327 45.7414 118.995 42.1085C120.664 38.4757 115.011 34.6838 113.131 35.3825C111.25 36.0811 105.997 49.793 105.997 49.793L105.466 49.708C105.466 49.708 109.228 35.9639 104.526 35.1445C99.8267 34.3254 96.9794 34.2534 96.9075 37.2665C96.834 40.2794 103.602 49.8766 103.602 49.8766C103.602 49.8766 101.479 49.9679 100.872 51.2139C100.265 52.4598 100.692 54.0463 101.956 54.6517C101.956 54.6517 98.425 53.3284 99.7893 56.2834C99.7893 56.2834 91.019 56.6606 82.5375 66.7331C74.0584 76.801 73.776 85.3787 73.7988 87.3653C73.8213 89.3536 72.1347 90.1104 72.1959 91.0087L72.1957 91.0104ZM107.376 49.6916C107.376 49.6916 112.313 37.9581 114.824 38.4292C117.335 38.8986 119.124 41.3273 107.858 50.0885L107.376 49.6932L107.376 49.6916ZM104.667 49.1906C104.667 49.1906 100.437 41.9581 99.8978 38.1597C99.4815 35.2251 104.75 36.4228 105.035 37.5236C105.413 38.9916 105.502 44.3965 104.667 49.1906Z" fill="url(#paint4_linear_0_2)" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M73.799 87.365C73.7765 85.3768 74.0587 76.8007 82.5399 66.7298C83.8956 65.1201 85.2588 63.7601 86.5924 62.6064C81.9498 66.8108 72.2576 82.6592 80.2484 88.7378C88.5589 95.0615 122.554 95.2242 125.541 90.1596C127.153 87.4281 127.146 83.1684 126.801 79.8197C130.735 90.8567 124.929 96.0344 124.035 97.0337C123.124 98.0507 124.317 99.9518 123.267 100.058C122.218 100.165 121.593 99.1064 120.511 99.0696C119.428 99.0328 111.619 103.191 97.089 100.869C82.5586 98.5468 77.2077 94.1343 75.862 92.3449C74.5147 90.5551 72.2577 91.905 72.1981 91.007C72.1371 90.1071 73.8237 89.3502 73.8009 87.3636L73.799 87.365Z" fill="url(#paint5_linear_0_2)" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M123.267 100.061C122.218 100.166 121.591 99.1094 120.508 99.0743C119.426 99.0358 111.615 103.195 97.0866 100.874C82.5578 98.5518 77.2052 94.1391 75.8595 92.3496C74.5106 90.5596 72.255 91.9114 72.1956 91.0117C72.1803 90.7795 72.2807 90.5538 72.4406 90.3156C73.8429 90.3524 75.1879 90.3035 76.1199 90.4133C76.2214 90.4244 76.3102 90.4403 76.3889 90.4563C77.6588 90.729 76.3477 91.6565 88.385 93.7452C89.2395 93.8954 78.1108 94.2872 81.585 94.8424C85.0591 95.3975 98.5294 95.5944 99.1925 95.6918C101.446 96.0297 100.476 97.1701 103.198 97.4995C106.245 97.8656 113.979 96.9755 116.366 97.3315C116.42 97.3402 116.471 97.3484 116.52 97.3596C116.834 97.4216 117.287 97.5212 117.839 97.6452C117.976 97.679 118.121 97.7107 118.272 97.7433C118.28 97.7479 118.291 97.7498 118.298 97.7508C118.438 97.7851 118.58 97.8164 118.729 97.8503L118.737 97.8517C119.061 97.9238 119.4 98.0002 119.755 98.079C119.843 98.0983 119.935 98.1197 120.026 98.1394C120.298 98.1998 120.579 98.2618 120.866 98.3212C121.137 98.3783 121.419 98.4386 121.703 98.4941C122.357 98.6292 123.028 98.7587 123.695 98.8738C123.75 99.497 123.79 100.009 123.263 100.064L123.267 100.061Z" fill="url(#paint6_linear_0_2)" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M109.02 58.1922C109.817 57.7596 110.133 57.1378 110.254 56.6395C110.308 57.232 110.097 57.7906 110.097 57.7906C112.283 56.6506 116.646 61.0772 120.645 67.4097C119.119 65.4859 117.396 63.4711 115.654 61.7578C112.087 58.2482 109.993 57.997 109.02 58.1922Z" fill="url(#paint7_linear_0_2)" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M106.309 56.365C105.892 56.8465 108.665 62.4895 108.665 62.4895C108.665 62.4895 105.823 58.1358 104.541 58.0671C103.387 58.0052 102.962 63.0453 102.888 64.048C102.935 63.1909 102.992 59.5302 100.66 57.6529C98.0762 55.5736 88.8851 60.8724 88.4148 61.1462C94.6395 56.5104 99.7908 56.2833 99.7908 56.2833C98.4266 53.3283 101.957 54.6517 101.957 54.6517C100.694 54.0447 100.266 52.4598 100.873 51.2139C101.48 49.9679 103.604 49.8766 103.604 49.8766C103.604 49.8766 101.147 46.3895 99.2084 42.9598C104.647 50.3072 99.832 40.6884 99.2185 37.7172C98.605 34.746 103.382 36.4898 103.382 36.4898L103.396 36.5312C101.788 36.0955 99.6222 36.2396 99.8955 38.1624C100.434 41.9608 104.664 49.1933 104.664 49.1933C105.088 46.7555 105.273 44.1639 105.303 42.007C105.3 51.5808 106.873 40.0945 106.887 39.998C106.962 44.2414 105.467 49.708 105.467 49.708L105.999 49.7929C105.999 49.7929 107.531 45.7945 109.237 42.0058C106.213 50.8434 109.701 42.8118 112.496 39.0168C114.424 36.399 115.706 37.9417 116.316 39.1508C115.967 38.7631 115.417 38.5407 114.824 38.4305C112.312 37.9612 107.376 49.693 107.376 49.693L107.857 50.0882C110.303 48.1862 112.131 46.584 113.472 45.2341C106.551 51.973 116.706 44.8675 116.708 44.8661C113.376 47.857 107.953 50.7622 107.953 50.7622C107.953 50.7622 109.551 51.4073 109.469 53.2002C109.388 54.9914 107.982 55.2707 107.982 55.2707C108.798 55.2087 109.331 55.3483 109.677 55.5857C108.3 55.2312 106.67 55.9495 106.311 56.3636L106.309 56.365Z" fill="url(#paint8_linear_0_2)" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M106.194 51.9161C106.194 51.9161 109.392 53.7564 107.437 55.0916C105.482 56.4252 102.412 55.3882 102.412 55.3882C106.643 56.2396 106.262 51.8758 105.391 51.8915C104.52 51.9056 103.45 54.8885 103.45 54.8885C103.45 54.8885 104.521 50.6223 102.313 51.5019C100.106 52.3815 101.954 54.6495 101.954 54.6495C100.69 54.0425 100.263 52.4576 100.87 51.2117C101.477 49.9657 103.601 49.8744 103.601 49.8744L105.995 49.7924C105.192 53.0103 107.949 50.765 107.949 50.765C107.242 51.886 106.191 51.9172 106.191 51.9172L106.194 51.9161Z" fill="url(#paint9_linear_0_2)" />
      <path d="M16.1456 101.826C16.5685 102.623 18.0783 100.47 20.0365 101.553C21.9968 102.637 28.6824 104.483 42.8942 100.675C57.106 96.8665 62.5405 89.8839 63.5437 89.4759C64.547 89.0678 65.5487 89.7774 66.4639 89.2537C67.3786 88.7285 65.5127 87.4797 65.9307 86.1792C66.3472 84.8791 69.6491 77.5578 61.0079 68.8917C52.3682 60.2251 39.5841 53.017 37.1937 56.0362C37.1937 56.0362 37.0299 53.0871 34.2354 54.5981C34.2354 54.5981 35.4046 53.7697 34.7482 52.1011C34.0917 50.4326 32.3692 50.4939 32.3692 50.4939C32.3692 50.4939 38.8797 42.0832 38.9211 38.0856C38.9626 34.088 32.2542 32.9326 30.8225 34.3376C29.3908 35.7427 30.1892 50.4046 30.1892 50.4046L29.6694 50.5439C29.6694 50.5439 27.4964 36.4608 22.8698 37.6309C18.2449 38.8006 15.616 39.8965 16.7797 42.6768C17.9418 45.4575 28.0369 51.4581 28.0369 51.4581C28.0369 51.4581 26.1354 52.4078 26.0896 53.793C26.0437 55.1782 27.0808 56.4525 28.4819 56.4895C28.4819 56.4895 24.7184 56.7218 27.1695 58.8631C27.1695 58.8631 19.3163 62.7857 15.6824 75.4423C12.0489 88.0935 15.2908 96.04 16.122 97.8445C16.9538 99.6505 15.7228 101.03 16.1452 101.825L16.1456 101.826ZM31.4066 49.7495C31.4066 49.7495 31.1267 37.0228 33.6114 36.4284C36.0956 35.8324 38.7194 37.3201 32.0083 49.9155L31.4071 49.7512L31.4066 49.7495ZM28.7287 50.3975C28.7287 50.3975 21.9159 45.5202 19.8741 42.2722C18.2968 39.7628 23.5958 38.7066 24.3047 39.5955C25.2489 40.7816 27.5355 45.6797 28.7287 50.3975Z" fill="#88BFE5" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M65.9306 86.1773C65.5142 87.4773 67.3785 88.7265 66.4638 89.2518C65.549 89.7771 64.5469 89.0659 63.5437 89.4739C62.5404 89.882 57.1075 96.8642 42.8941 100.673C28.6807 104.481 21.9951 102.636 20.0364 101.551C18.0761 100.467 16.5663 102.62 16.1455 101.824C15.7227 101.028 16.9537 99.6486 16.1224 97.8442C15.6753 96.873 14.5307 94.1189 14.135 89.8138C14.2212 90.0448 14.3042 90.2835 14.3799 90.5469C17.2365 100.413 18.5802 90.4011 19.4034 90.5998C20.2267 90.7986 22.6052 92.9593 23.1611 91.4113C23.7171 89.8634 25.0702 88.6621 37.7928 84.2108C50.5158 79.7611 57.2354 80.4611 58.1338 79.6618C59.0318 78.8608 60.3756 79.2003 61.9537 79.898C63.5314 80.5942 65.2246 74.5531 65.2246 74.5531C68.2862 80.5869 66.2535 85.1737 65.9323 86.1768L65.9306 86.1773Z" fill="url(#paint10_linear_0_2)" />
      <path style={{mixBlendMode:"screen"}} d="M41.0445 62.575C41.0445 62.575 37.6185 56.0595 43.8872 57.3884C50.1558 58.7173 61.3361 69.2906 57.8161 71.2134C54.2962 73.1362 44.4023 68.3711 44.4023 68.3711C44.4023 68.3711 47.767 78.9523 39.0824 81.5543C30.3993 84.1558 22.0882 71.3103 24.5484 66.9952C27.0085 62.6801 33.6478 65.7472 33.6478 65.7472C33.6478 65.7472 31.2271 60.1681 41.0429 62.5755L41.0445 62.575Z" fill="url(#paint11_linear_0_2)" />
      <path opacity="0.5" d="M16.1456 101.826C16.5685 102.623 18.0783 100.47 20.0365 101.553C21.9968 102.637 28.6824 104.483 42.8942 100.675C57.106 96.8665 62.5405 89.8839 63.5437 89.4759C64.547 89.0678 65.5487 89.7774 66.4639 89.2537C67.3786 88.7285 65.5127 87.4797 65.9307 86.1792C66.3472 84.8791 69.6491 77.5578 61.0079 68.8917C52.3682 60.2251 39.5841 53.017 37.1937 56.0362C37.1937 56.0362 37.0299 53.0871 34.2354 54.5981C34.2354 54.5981 35.4046 53.7697 34.7482 52.1011C34.0917 50.4326 32.3692 50.4939 32.3692 50.4939C32.3692 50.4939 38.8797 42.0832 38.9211 38.0856C38.9626 34.088 32.2542 32.9326 30.8225 34.3376C29.3908 35.7427 30.1892 50.4046 30.1892 50.4046L29.6694 50.5439C29.6694 50.5439 27.4964 36.4608 22.8698 37.6309C18.2449 38.8006 15.616 39.8965 16.7797 42.6768C17.9418 45.4575 28.0369 51.4581 28.0369 51.4581C28.0369 51.4581 26.1354 52.4078 26.0896 53.793C26.0437 55.1782 27.0808 56.4525 28.4819 56.4895C28.4819 56.4895 24.7184 56.7218 27.1695 58.8631C27.1695 58.8631 19.3163 62.7857 15.6824 75.4423C12.0489 88.0935 15.2908 96.04 16.122 97.8445C16.9538 99.6505 15.7228 101.03 16.1452 101.825L16.1456 101.826ZM31.4066 49.7495C31.4066 49.7495 31.1267 37.0228 33.6114 36.4284C36.0956 35.8324 38.7194 37.3201 32.0083 49.9155L31.4071 49.7512L31.4066 49.7495ZM28.7287 50.3975C28.7287 50.3975 21.9159 45.5202 19.8741 42.2722C18.2968 39.7628 23.5958 38.7066 24.3047 39.5955C25.2489 40.7816 27.5355 45.6797 28.7287 50.3975Z" fill="url(#paint12_linear_0_2)" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M16.122 97.8442C15.2903 96.0381 12.0489 88.0932 15.6831 75.4383C16.2641 73.4155 16.9538 71.6177 17.7007 70.0203C15.1774 75.753 12.7947 94.1767 22.5703 96.4661C32.7377 98.8489 63.8408 85.1276 64.5022 79.2847C64.8587 76.1336 63.1148 72.2472 61.4336 69.3307C69.5278 77.8023 66.3402 84.8981 65.9315 86.1752C65.515 87.4753 67.3794 88.7245 66.4646 89.2498C65.5498 89.775 64.5478 89.0639 63.5445 89.4719C62.5412 89.8799 57.1083 96.8621 42.8949 100.671C28.6816 104.479 21.996 102.634 20.0373 101.549C18.077 100.465 16.5671 102.618 16.1463 101.822C15.7235 101.026 16.9545 99.6466 16.1232 97.8421L16.122 97.8442Z" fill="url(#paint13_linear_0_1)" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M66.4655 89.253C65.5503 89.7766 64.547 89.0675 63.5442 89.4772C62.5405 89.8836 57.1064 96.8679 42.8947 100.676C28.6829 104.484 21.9957 102.639 20.037 101.554C18.0751 100.47 16.5673 102.625 16.1461 101.828C16.0373 101.622 16.0369 101.375 16.0857 101.092C17.381 100.554 18.589 99.9603 19.4847 99.6803C19.5818 99.649 19.6695 99.6273 19.7478 99.6098C21.0184 99.3407 20.1999 100.722 32.042 97.7181C32.8833 97.5066 22.8829 102.405 26.2812 101.494C29.6796 100.584 42.058 95.2675 42.7032 95.0859C44.898 94.4752 44.4784 95.9118 47.0974 95.1021C50.029 94.1931 56.7264 90.2252 59.0511 89.5762C59.1044 89.5619 59.1544 89.5485 59.2037 89.5388C59.5151 89.4675 59.9693 89.3736 60.5241 89.2615C60.6627 89.2366 60.8086 89.2062 60.9593 89.1745C60.9682 89.1756 60.9795 89.1726 60.986 89.1708C61.1279 89.145 61.2705 89.1155 61.42 89.0858L61.4281 89.0837C61.753 89.0175 62.0944 88.9486 62.4504 88.8759C62.5389 88.8574 62.631 88.8397 62.7228 88.8203C62.9951 88.7647 63.2771 88.7066 63.5631 88.6438C63.8346 88.585 64.1162 88.5252 64.3981 88.4601C65.0501 88.3168 65.7161 88.1609 66.3721 87.9939C66.676 88.5406 66.9215 88.9916 66.4631 89.2571L66.4655 89.253Z" fill="url(#paint14_linear_0_1)" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M36.3764 56.8396C36.9275 56.1195 36.9623 55.4228 36.8688 54.9189C37.1601 55.4377 37.1952 56.0338 37.1952 56.0338C38.7261 54.1011 44.516 56.3622 50.7498 60.5123C48.572 59.3784 46.1772 58.2418 43.8877 57.3884C39.1989 55.6396 37.1844 56.2647 36.3764 56.8396Z" fill="url(#paint15_linear_0_1)" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M33.1559 56.2774C32.9714 56.8871 37.8053 60.9078 37.8053 60.9078C37.8053 60.9078 33.4346 58.0924 32.2359 58.5528C31.1565 58.9673 32.8253 63.7421 33.1669 64.6877C32.8603 63.8859 31.4187 60.5205 28.5237 59.758C25.3162 58.9139 19.0867 67.5017 18.7691 67.9435C22.5608 61.1713 27.1711 58.8624 27.1711 58.8624C24.72 56.7211 28.4835 56.4888 28.4835 56.4888C27.082 56.4502 26.0454 55.1775 26.0912 53.7923C26.1371 52.4071 28.0386 51.4574 28.0386 51.4574C28.0386 51.4574 24.3724 49.2763 21.2034 46.9358C29.1667 51.4248 20.8461 44.6077 19.0737 42.1453C17.3013 39.683 22.3742 39.326 22.3742 39.326L22.4036 39.3582C20.758 39.6164 18.8394 40.6316 19.8734 42.2756C21.9152 45.5236 28.7279 50.4009 28.7279 50.4009C28.1199 48.0024 27.232 45.5606 26.3792 43.5792C30.2824 52.3213 27.0323 41.1926 27.0055 41.0989C28.8053 44.9424 29.671 50.5432 29.671 50.5432L30.1909 50.4039C30.1909 50.4039 29.9588 46.1281 29.9702 41.9732C30.8154 51.2754 30.7223 42.5199 31.7257 37.9148C32.418 34.7381 34.2177 35.6236 35.2686 36.4782C34.7915 36.2668 34.1986 36.2882 33.6118 36.4298C31.1276 37.0258 31.4071 49.7509 31.4071 49.7509L32.0083 49.9152C33.4646 47.1811 34.4802 44.9723 35.154 43.1926C31.5844 52.169 37.9569 41.5385 37.9581 41.5364C36.1366 45.6264 32.3704 50.4916 32.3704 50.4916C32.3704 50.4916 34.0925 50.4286 34.7494 52.0988C35.4058 53.7674 34.2366 54.5957 34.2366 54.5957C34.9559 54.2064 35.4994 54.1164 35.9124 54.1919C34.5106 54.4301 33.3158 55.7508 33.157 56.2753L33.1559 56.2774Z" fill="url(#paint16_linear_0_1)" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M31.2356 52.2625C31.2356 52.2625 34.9059 52.638 33.6657 54.6547C32.4251 56.6698 29.1987 56.9757 29.1987 56.9757C33.4087 56.0269 31.2806 52.1982 30.492 52.5679C29.703 52.9359 29.9424 56.0961 29.9424 56.0961C29.9424 56.0961 29.1796 51.7641 27.5234 53.4677C25.8671 55.1713 28.4797 56.488 28.4797 56.488C27.0782 56.4494 26.0416 55.1768 26.0874 53.7916C26.1333 52.4064 28.0348 51.4566 28.0348 51.4566L30.1875 50.4048C30.7667 53.6705 32.3679 50.4957 32.3679 50.4957C32.1796 51.8077 31.2328 52.265 31.2328 52.265L31.2356 52.2625Z" fill="url(#paint17_linear_0_1)" />
      <path d="M41.0407 96.7074C41.2639 97.6774 43.4882 95.8134 45.2662 97.5272C47.046 99.2428 53.6451 103.119 69.881 103.119C86.1169 103.119 93.9038 97.2286 95.0897 97.0802C96.2756 96.9318 97.1406 97.9742 98.2657 97.6774C99.3907 97.3788 97.7585 95.5148 98.5755 94.248C99.3907 92.9812 105.001 86.1205 98.2657 74.4153C91.5319 62.71 79.9641 51.3757 76.5539 53.9111C76.5539 53.9111 77.2215 50.721 73.8113 51.5334C73.8113 51.5334 75.2942 50.9844 75.071 49.0183C74.8478 47.0523 72.9943 46.6257 72.9943 46.6257C72.9943 46.6257 82.336 39.5202 83.5219 35.271C84.7078 31.0218 77.8874 27.8743 75.96 28.9631C74.0326 30.0518 70.6962 45.9079 70.6962 45.9079H70.1023C70.1023 45.9079 71.8083 30.2762 66.5427 30.202C61.2789 30.1278 58.1638 30.5452 58.6101 33.841C59.0546 37.1369 68.1012 46.4161 68.1012 46.4161C68.1012 46.4161 65.8031 46.8854 65.3586 48.3488C64.9141 49.8122 65.6556 51.4666 67.1384 51.9062C67.1384 51.9062 63.0605 51.079 65.0617 54.0614C65.0617 54.0614 55.5706 55.9996 48.0825 68.4523C40.5962 80.8994 41.7822 90.2955 42.1529 92.4563C42.5236 94.6189 40.8176 95.7373 41.0407 96.7055V96.7074ZM72.1809 45.5574C72.1809 45.5574 75.5174 31.9121 78.3355 31.9881C81.1537 32.0623 83.5256 34.3974 72.7748 45.9061L72.1809 45.5593V45.5574ZM69.1414 45.4832C69.1414 45.4832 63.2726 38.3388 62.024 34.2936C61.0594 31.1683 67.0093 31.556 67.511 32.7059C68.1786 34.2398 69.217 40.1137 69.1414 45.4832Z" fill="#88BFE5" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M98.5755 94.246C97.7603 95.5128 99.3907 97.3768 98.2657 97.6754C97.1406 97.974 96.2756 96.9298 95.0897 97.0782C93.9038 97.2266 86.1187 103.117 69.881 103.117C53.6432 103.117 47.0441 99.2408 45.2662 97.5252C43.4863 95.8096 41.262 97.6736 41.0407 96.7054C40.8176 95.7354 42.5236 94.617 42.1529 92.4562C41.9537 91.2933 41.5203 88.0308 42.3281 83.329C42.3539 83.5998 42.3742 83.878 42.3797 84.1803C42.6066 95.5128 46.8984 85.2246 47.7192 85.6716C48.5399 86.1185 50.458 89.101 51.4927 87.6098C52.5274 86.1185 54.3127 85.2246 69.1451 84.1136C83.9774 83.0044 90.9399 85.6697 92.1258 85.0743C93.3117 84.4771 94.647 85.2227 96.1299 86.4172C97.6128 87.6098 101.143 81.6542 101.143 81.6542C102.683 88.96 99.2063 93.2686 98.5774 94.246H98.5755Z" fill="url(#paint18_linear_0_1)" />
      <path style={{mixBlendMode:"screen"}} d="M78.7911 61.981C78.7911 61.981 77.0003 54.0575 83.3024 57.2644C89.6046 60.4712 98.5018 74.9345 94.2008 75.9787C89.8997 77.0229 80.7148 69.118 80.7148 69.118C80.7148 69.118 81.2792 81.3574 71.2791 81.6505C61.2808 81.9435 56.0907 65.8778 59.9455 61.981C63.8002 58.0842 70.0009 63.2496 70.0009 63.2496C70.0009 63.2496 69.0142 56.6115 78.7893 61.981H78.7911Z" fill="url(#paint19_linear_0_1)" />
      <path opacity="0.5" d="M41.0407 96.7074C41.2639 97.6774 43.4882 95.8134 45.2662 97.5272C47.046 99.2428 53.6451 103.119 69.881 103.119C86.1169 103.119 93.9038 97.2286 95.0897 97.0802C96.2756 96.9318 97.1406 97.9742 98.2657 97.6774C99.3907 97.3788 97.7585 95.5148 98.5755 94.248C99.3907 92.9812 105.001 86.1205 98.2657 74.4153C91.5319 62.71 79.9641 51.3757 76.5539 53.9111C76.5539 53.9111 77.2215 50.721 73.8113 51.5334C73.8113 51.5334 75.2942 50.9844 75.071 49.0183C74.8478 47.0523 72.9943 46.6257 72.9943 46.6257C72.9943 46.6257 82.336 39.5202 83.5219 35.271C84.7078 31.0218 77.8874 27.8743 75.96 28.9631C74.0326 30.0518 70.6962 45.9079 70.6962 45.9079H70.1023C70.1023 45.9079 71.8083 30.2762 66.5427 30.202C61.2789 30.1278 58.1638 30.5452 58.6101 33.841C59.0546 37.1369 68.1012 46.4161 68.1012 46.4161C68.1012 46.4161 65.8031 46.8854 65.3586 48.3488C64.9141 49.8122 65.6556 51.4666 67.1384 51.9062C67.1384 51.9062 63.0605 51.079 65.0617 54.0614C65.0617 54.0614 55.5706 55.9996 48.0825 68.4523C40.5962 80.8994 41.7822 90.2955 42.1529 92.4563C42.5236 94.6189 40.8176 95.7373 41.0407 96.7055V96.7074ZM72.1809 45.5574C72.1809 45.5574 75.5174 31.9121 78.3355 31.9881C81.1537 32.0623 83.5256 34.3974 72.7748 45.9061L72.1809 45.5593V45.5574ZM69.1414 45.4832C69.1414 45.4832 63.2726 38.3388 62.024 34.2936C61.0594 31.1683 67.0093 31.556 67.511 32.7059C68.1786 34.2398 69.217 40.1137 69.1414 45.4832Z" fill="url(#paint20_linear_0_1)" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M42.153 92.4562C41.7823 90.2936 40.5963 80.8993 48.0844 68.4485C49.2814 66.4583 50.5301 64.739 51.7824 63.2496C47.4555 68.6395 39.6539 87.5968 49.4198 92.829C59.5767 98.2726 96.6483 92.5304 99.022 86.4913C100.302 83.2344 99.5532 78.5939 98.5941 75.0049C104.802 86.3467 99.3779 92.9996 98.5775 94.2441C97.7623 95.5109 99.3927 97.3749 98.2676 97.6736C97.1426 97.9722 96.2776 96.928 95.0917 97.0763C93.9057 97.2247 86.1207 103.115 69.8829 103.115C53.6452 103.115 47.0461 99.239 45.2681 97.5233C43.4883 95.8077 41.264 97.6717 41.0427 96.7035C40.8195 95.7335 42.5255 94.6151 42.1548 92.4543L42.153 92.4562Z" fill="url(#paint21_linear_0_1)" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M98.2674 97.6773C97.1423 97.974 96.2755 96.9317 95.0895 97.0819C93.9036 97.2284 86.1167 103.121 69.8808 103.121C53.6449 103.121 47.044 99.2445 45.266 97.5289C43.4844 95.8133 41.2619 97.6791 41.0406 96.7091C40.9834 96.4587 41.0535 96.1953 41.1863 95.9079C42.7208 95.7038 44.1778 95.4163 45.2125 95.3737C45.325 95.3681 45.4246 95.37 45.5131 95.3737C46.9444 95.4497 45.6773 96.6887 59.1577 96.8686C60.1149 96.8834 48.0565 99.2482 51.9389 99.2482C55.8213 99.2482 70.5337 97.1171 71.2733 97.1079C73.7872 97.0838 72.9296 98.4952 75.9525 98.3802C79.3369 98.2485 87.6088 95.932 90.2721 95.9041C90.3329 95.9041 90.3901 95.9041 90.4455 95.9079C90.7977 95.9208 91.3086 95.9505 91.932 95.9895C92.0869 96.0024 92.2511 96.0117 92.4208 96.021C92.43 96.0247 92.4429 96.0247 92.4503 96.0247C92.6089 96.0377 92.7693 96.047 92.9372 96.0581H92.9464C93.3116 96.0803 93.6952 96.1045 94.0954 96.1286C94.195 96.1341 94.2983 96.1416 94.4016 96.1471C94.7078 96.1657 95.025 96.1842 95.3478 96.1991C95.6539 96.2139 95.9712 96.2306 96.2902 96.2417C97.0261 96.2751 97.7805 96.2992 98.5274 96.3085C98.6953 96.978 98.8281 97.5289 98.2637 97.681L98.2674 97.6773Z" fill="url(#paint22_linear_0_1)" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M75.4526 54.5342C76.2457 53.924 76.4818 53.1914 76.5261 52.6275C76.6884 53.2637 76.5556 53.9092 76.5556 53.9092C78.7393 52.2863 84.265 56.35 89.7243 62.5541C87.7269 60.7235 85.4989 58.8279 83.3022 57.2644C78.8038 54.0612 76.4781 54.1521 75.4526 54.5342Z" fill="url(#paint23_linear_0_1)" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M72.1814 53.0152C71.8107 53.6124 75.8148 59.2786 75.8148 59.2786C75.8148 59.2786 71.9601 55.0294 70.551 55.1778C69.2821 55.3113 69.6971 60.8774 69.7911 61.9828C69.6934 61.0406 69.1179 57.0418 66.25 55.4022C63.0721 53.5864 53.9794 60.9609 53.5146 61.3411C59.4904 55.2056 65.064 54.0613 65.064 54.0613C63.0629 51.0788 67.1408 51.906 67.1408 51.906C65.6579 51.4646 64.9165 49.812 65.361 48.3487C65.8055 46.8853 68.1035 46.416 68.1035 46.416C68.1035 46.416 64.8187 43.0441 62.1094 39.6444C69.3153 46.7035 62.3934 37.0607 61.2075 33.9299C60.0216 30.7991 65.5307 31.8675 65.5307 31.8675L65.5528 31.9101C63.725 31.7154 61.3901 32.2495 62.0227 34.2972C63.2713 38.3423 69.1401 45.4868 69.1401 45.4868C69.177 42.7566 68.928 39.9003 68.5849 37.5448C70.2485 47.9777 69.9627 35.1874 69.9608 35.0799C70.7815 39.6907 70.1047 45.9078 70.1047 45.9078H70.6986C70.6986 45.9078 71.6724 41.284 72.8712 36.8586C71.1154 47.0151 73.5167 37.6561 75.9015 33.0341C77.5467 29.8458 79.2121 31.3036 80.0882 32.5148C79.64 32.1531 79.0019 32.0066 78.336 31.9899C75.5179 31.9157 72.1814 45.5591 72.1814 45.5591L72.7753 45.906C75.1084 43.4076 76.8218 41.3433 78.0483 39.6388C71.6797 48.1873 81.5083 38.6762 81.5102 38.6743C78.4006 42.5137 72.9966 46.6238 72.9966 46.6238C72.9966 46.6238 74.8502 47.0485 75.0734 49.0164C75.2965 50.9824 73.8137 51.5314 73.8137 51.5314C74.6916 51.3218 75.2965 51.3812 75.7152 51.5796C74.153 51.4331 72.5023 52.4996 72.1833 53.0133L72.1814 53.0152Z" fill="url(#paint24_linear_0_1)" />
      <path style={{mixBlendMode:"multiply"}} opacity="0.69" d="M71.2811 48.1873C71.2811 48.1873 75.086 49.6358 73.1882 51.4312C71.2904 53.2248 67.7639 52.6294 67.7639 52.6294C72.5224 52.8204 71.3475 48.1317 70.4014 48.3004C69.4552 48.4674 68.8078 51.9042 68.8078 51.9042C68.8078 51.9042 69.232 47.0689 66.9801 48.4117C64.7281 49.7545 67.1369 51.9042 67.1369 51.9042C65.654 51.4628 64.9126 49.8102 65.357 48.3468C65.8015 46.8834 68.0996 46.4142 68.0996 46.4142L70.6946 45.9078C70.3792 49.5542 72.9927 46.6275 72.9927 46.6275C72.4173 47.9721 71.2774 48.1891 71.2774 48.1891L71.2811 48.1873Z" fill="url(#paint25_linear_0_1)" />
      <defs>
        <linearGradient id="paint0_linear_0_2" x1="66.4147" y1="26.5419" x2="66.4147" y2="63.9264" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFE92E" />
          <stop offset="1" stop-color="#FB8B1C" />
        </linearGradient>
        <linearGradient id="paint1_linear_0_3" x1="69.8228" y1="98.875" x2="69.8228" y2="106.167" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D9D9D9" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient id="paint2_linear_0_2" x1="98.8147" y1="98.0757" x2="108.115" y2="39.8776" gradientUnits="userSpaceOnUse">
          <stop offset="0.09" stop-color="white" />
          <stop offset="0.12" stop-color="#F1F4F8" />
          <stop offset="0.21" stop-color="#C9D8E5" />
          <stop offset="0.31" stop-color="#A7BFD5" />
          <stop offset="0.41" stop-color="#8BABC8" />
          <stop offset="0.52" stop-color="#759CBE" />
          <stop offset="0.64" stop-color="#6691B7" />
          <stop offset="0.79" stop-color="#5D8AB3" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint3_linear_0_2" x1="128.594" y1="46.4097" x2="101.412" y2="79.9872" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFFF52" />
          <stop offset="0.17" stop-color="#BDBD3D" />
          <stop offset="0.33" stop-color="#84842A" />
          <stop offset="0.49" stop-color="#54541B" />
          <stop offset="0.64" stop-color="#30300F" />
          <stop offset="0.78" stop-color="#151506" />
          <stop offset="0.9" stop-color="#050501" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient id="paint4_linear_0_2" x1="122.194" y1="53.1795" x2="81.6729" y2="103.235" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.15" stop-color="#CEE5FB" />
          <stop offset="0.35" stop-color="#BED8F1" />
          <stop offset="0.57" stop-color="#A4C3E0" />
          <stop offset="0.8" stop-color="#80A6C9" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint5_linear_0_2" x1="98.6302" y1="99.2428" x2="107.136" y2="46.0172" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.33" stop-color="#A0C0DE" />
          <stop offset="0.62" stop-color="#7AA2C6" />
          <stop offset="0.85" stop-color="#638FB7" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint6_linear_0_2" x1="102.158" y1="68.9609" x2="94.5665" y2="116.464" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.33" stop-color="#A0C0DE" />
          <stop offset="0.62" stop-color="#7AA2C6" />
          <stop offset="0.85" stop-color="#638FB7" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint7_linear_0_2" x1="108.37" y1="103.243" x2="147" y2="-138.493" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.33" stop-color="#A0C0DE" />
          <stop offset="0.62" stop-color="#7AA2C6" />
          <stop offset="0.85" stop-color="#638FB7" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint8_linear_0_2" x1="100.228" y1="67.6143" x2="108.346" y2="16.8102" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.33" stop-color="#A0C0DE" />
          <stop offset="0.62" stop-color="#7AA2C6" />
          <stop offset="0.85" stop-color="#638FB7" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint9_linear_0_2" x1="101.984" y1="67.8949" x2="110.102" y2="17.0909" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.33" stop-color="#A0C0DE" />
          <stop offset="0.62" stop-color="#7AA2C6" />
          <stop offset="0.85" stop-color="#638FB7" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint10_linear_0_2" x1="43.3315" y1="97.4164" x2="28.0776" y2="40.488" gradientUnits="userSpaceOnUse">
          <stop offset="0.09" stop-color="white" />
          <stop offset="0.12" stop-color="#F1F4F8" />
          <stop offset="0.21" stop-color="#C9D8E5" />
          <stop offset="0.31" stop-color="#A7BFD5" />
          <stop offset="0.41" stop-color="#8BABC8" />
          <stop offset="0.52" stop-color="#759CBE" />
          <stop offset="0.64" stop-color="#6691B7" />
          <stop offset="0.79" stop-color="#5D8AB3" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint11_linear_0_2" x1="49.439" y1="38.0963" x2="38.3219" y2="79.8422" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFFF52" />
          <stop offset="0.17" stop-color="#BDBD3D" />
          <stop offset="0.33" stop-color="#84842A" />
          <stop offset="0.49" stop-color="#54541B" />
          <stop offset="0.64" stop-color="#30300F" />
          <stop offset="0.78" stop-color="#151506" />
          <stop offset="0.9" stop-color="#050501" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient id="paint12_linear_0_2" x1="46.3583" y1="46.8882" x2="29.7859" y2="109.121" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.15" stop-color="#CEE5FB" />
          <stop offset="0.35" stop-color="#BED8F1" />
          <stop offset="0.57" stop-color="#A4C3E0" />
          <stop offset="0.8" stop-color="#80A6C9" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint13_linear_0_1" x1="43.6385" y1="98.5573" x2="29.688" y2="46.493" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.33" stop-color="#A0C0DE" />
          <stop offset="0.62" stop-color="#7AA2C6" />
          <stop offset="0.85" stop-color="#638FB7" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint14_linear_0_1" x1="34.504" y1="69.4713" x2="46.9546" y2="115.938" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.33" stop-color="#A0C0DE" />
          <stop offset="0.62" stop-color="#7AA2C6" />
          <stop offset="0.85" stop-color="#638FB7" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint15_linear_0_1" x1="54.1632" y1="98.236" x2="-9.1967" y2="-138.226" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.33" stop-color="#A0C0DE" />
          <stop offset="0.62" stop-color="#7AA2C6" />
          <stop offset="0.85" stop-color="#638FB7" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint16_linear_0_1" x1="32.1932" y1="69.029" x2="18.8773" y2="19.3334" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.33" stop-color="#A0C0DE" />
          <stop offset="0.62" stop-color="#7AA2C6" />
          <stop offset="0.85" stop-color="#638FB7" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint17_linear_0_1" x1="33.9108" y1="68.5688" x2="20.5949" y2="18.8732" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.33" stop-color="#A0C0DE" />
          <stop offset="0.62" stop-color="#7AA2C6" />
          <stop offset="0.85" stop-color="#638FB7" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint18_linear_0_1" x1="71.2772" y1="99.7713" x2="71.2772" y2="34.7349" gradientUnits="userSpaceOnUse">
          <stop offset="0.09" stop-color="white" />
          <stop offset="0.12" stop-color="#F1F4F8" />
          <stop offset="0.21" stop-color="#C9D8E5" />
          <stop offset="0.31" stop-color="#A7BFD5" />
          <stop offset="0.41" stop-color="#8BABC8" />
          <stop offset="0.52" stop-color="#759CBE" />
          <stop offset="0.64" stop-color="#6691B7" />
          <stop offset="0.79" stop-color="#5D8AB3" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint19_linear_0_1" x1="94.7301" y1="38.2867" x2="70.9575" y2="79.6084" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFFF52" />
          <stop offset="0.17" stop-color="#BDBD3D" />
          <stop offset="0.33" stop-color="#84842A" />
          <stop offset="0.49" stop-color="#54541B" />
          <stop offset="0.64" stop-color="#30300F" />
          <stop offset="0.78" stop-color="#151506" />
          <stop offset="0.9" stop-color="#050501" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient id="paint20_linear_0_1" x1="88.9351" y1="46.7778" x2="53.4965" y2="108.378" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.15" stop-color="#CEE5FB" />
          <stop offset="0.35" stop-color="#BED8F1" />
          <stop offset="0.57" stop-color="#A4C3E0" />
          <stop offset="0.8" stop-color="#80A6C9" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint21_linear_0_1" x1="71.2791" y1="101.075" x2="71.2791" y2="41.5956" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.33" stop-color="#A0C0DE" />
          <stop offset="0.62" stop-color="#7AA2C6" />
          <stop offset="0.85" stop-color="#638FB7" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint22_linear_0_1" x1="69.8495" y1="67.4636" x2="69.8495" y2="120.548" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.33" stop-color="#A0C0DE" />
          <stop offset="0.62" stop-color="#7AA2C6" />
          <stop offset="0.85" stop-color="#638FB7" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint23_linear_0_1" x1="82.5885" y1="103.739" x2="82.5885" y2="-166.402" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.33" stop-color="#A0C0DE" />
          <stop offset="0.62" stop-color="#7AA2C6" />
          <stop offset="0.85" stop-color="#638FB7" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint24_linear_0_1" x1="67.5133" y1="66.3322" x2="67.5133" y2="9.55866" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.33" stop-color="#A0C0DE" />
          <stop offset="0.62" stop-color="#7AA2C6" />
          <stop offset="0.85" stop-color="#638FB7" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
        <linearGradient id="paint25_linear_0_1" x1="69.4755" y1="66.3322" x2="69.4755" y2="9.55866" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D4EAFF" />
          <stop offset="0.33" stop-color="#A0C0DE" />
          <stop offset="0.62" stop-color="#7AA2C6" />
          <stop offset="0.85" stop-color="#638FB7" />
          <stop offset="1" stop-color="#5B89B2" />
        </linearGradient>
      </defs>
    </svg>
  );
}