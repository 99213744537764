import "./CreateModal.style.css";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal, Grid, Drawer } from "antd";
import { useCallback, useReducer } from "react";
import Start from "./start/Start";
import CreateIcon from "./createIcon/CreateIcon";
import CreateKratong from "./createKratong/CreateYourKratong";
import Confirm from "./confirm/Confirm";
import { CREATE_STATE } from "./configure/modalState";
import { CloseOutlined, LeftOutlined } from "@ant-design/icons";
import AnimateClickableComponent from "../animateClickableComponent/AnimateClickableComponent";
import { createAvatar, createKratong } from "./configure/api";
import AlertPickup from "./alertPickup/AlertPickup";

const INITIAL_KRATONG_DATA = {
  name: "",
  wish: "",
  noTypeKrathong: "",
  idAvatar: "",
  avatar: { icon: "" },
};

export default function CreateModal({
  open,
  setOpen,
  addKratong,
  type,
  kratongData,
  setUserId,
  setIsPicked,
  setError,
  error
}) {
  const [createState, setCreateState] = useState(CREATE_STATE[0]);
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const contentRef = useRef();

  const reducer = (state, action) => {
    switch (action?.type) {
      case "SET_WISH":
        return {
          ...state,
          wish: action?.data?.wish,
        };
      case "SET_NAME":
        return {
          ...state,
          name: action?.data?.name,
        };
      case "SET_USER":
        return {
          ...state,
          name: action?.data?.name,
          avatar: action?.data?.avatar,
          idAvatar: action?.data?.idAvatar,
        };
      case "SET_KRATONG":
        return {
          ...state,
          wish: action?.data?.text,
          noTypeKrathong: action?.data?.kratong,
        };
      case "RESET":
        return INITIAL_KRATONG_DATA;
      case "SET_DATA":
        return action?.data;
      default:
        return state;
    }
  };

  const [data, dispatch] = useReducer(reducer, INITIAL_KRATONG_DATA);

  const onLoy = useCallback(async () => {
    const resCreateAvatar = await createAvatar(data.avatar);
    if (resCreateAvatar.message !== undefined) {
      setError({
        message: resCreateAvatar.message,
        status: resCreateAvatar.response.status,
      });
    } else {
      const resCreateKratong = await createKratong({
        name: data.name,
        idAvatar: resCreateAvatar.id,
        wish: data.wish,
        icon: data.avatar.icon,
        noTypeKrathong: data.noTypeKrathong,
      });
      if (resCreateKratong.message !== undefined) {
        setError({
          message: resCreateKratong.message,
          status: resCreateKratong.response.status,
        });
      } else {
        setError(false);
        data.avatar.id = resCreateAvatar.id;
        data.id = resCreateKratong.id;
        data.status = "Active";
        setUserId((prep) => [...prep, resCreateKratong.id]);
        addKratong(data);
        setIsPicked(false);
        setCreateState(CREATE_STATE[6]);
      }
    }
  }, [addKratong, data, setIsPicked, setUserId, setError]);

  const handleBack = useCallback(() => {
    const currentIndex = CREATE_STATE?.findIndex(
      (state) => state === createState
    );
    if (currentIndex > 0) setCreateState(CREATE_STATE[currentIndex - 1]);
  }, [createState]);
  const modalState = useMemo(() => {
    switch (createState) {
      case CREATE_STATE[0]:
        return <Start setCreateState={setCreateState} />;
      case CREATE_STATE[1]:
        return (
          <CreateIcon
            setCreateState={setCreateState}
            dispatch={dispatch}
            data={data}
          />
        );
      case CREATE_STATE[2]:
        return (
          <CreateKratong
            setCreateState={setCreateState}
            dispatch={dispatch}
            data={data}
          />
        );
      case CREATE_STATE[3]:
        return (
          <Confirm
            setCreateState={setCreateState}
            data={data}
            onLoy={onLoy}
            canLoy={type !== CREATE_STATE[4]}
          />
        );
      case CREATE_STATE[6]:
        return <AlertPickup setOpen={setOpen} />;
      default:
        return <Start setCreateState={setCreateState} />;
    }
  }, [createState, data, onLoy, setOpen, type]);

  useEffect(() => {
    if (!open) {
      dispatch({ type: "RESET" });
    }
    return () => {
      setCreateState(CREATE_STATE[0]);
    };
  }, [open]);

  useEffect(() => {
    if (type === CREATE_STATE[4] && open) {
      setCreateState(CREATE_STATE[3]);
      dispatch({ type: "SET_DATA", data: kratongData });
    }
  }, [kratongData, open, type]);

  const backButtonShow = useMemo(() => {
    if (
      type === CREATE_STATE[4] ||
      createState === CREATE_STATE[0] ||
      createState === CREATE_STATE[6]
    ) {
      return false;
    } else {
      return true;
    }
  }, [createState, type]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(()=>{
    contentRef?.current?.scroll({ 
      top: 0, 
      behavior: "smooth" 
    })
  },[createState])

  useEffect(()=>{
    if(error){
      setOpen(false)
    }
  },[error, setOpen])

  const content = useMemo(() => {
    return (
      <>
        {backButtonShow && (
          <AnimateClickableComponent
            onClick={handleBack}
            className={`back-button`}
          >
            <LeftOutlined className={`navigate-button`} /> {/*Back*/}
          </AnimateClickableComponent>
        )}
        <AnimateClickableComponent
          onClick={handleClose}
          className="close-button"
        >
          <CloseOutlined className={`navigate-button`} />
        </AnimateClickableComponent>
        {xs ? (
          <div className="drawer-body scrollbar-hide" ref={contentRef}>{modalState}</div>
        ) : (
          modalState
        )}
      </>
    );
  }, [backButtonShow, handleBack, handleClose, modalState, xs]);

  return xs ? (
    <Drawer
      open={open}
      placement="bottom"
      onClose={() => {
        setOpen(false);
      }}
      closeIcon={false}
      className={"drawer-container"}
      maskClosable={false}
    >
      {content}
    </Drawer>
  ) : (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      footer={false}
      closeIcon={false}
      centered
      maskClosable={false}
      width={createState === CREATE_STATE[6] ? 430 : null}
      className="modal-container"
    >
      {content}
    </Modal>
  );
}
