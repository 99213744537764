import './Loading.style.css';
import { Row, Col, Flex } from "antd";
// import { motion } from "framer-motion";
import { KRATONGS } from "../configure/modalState";

export default function Loading() {
    return (
        <Flex className="modal-body loading-container" justify="center" align="center" vertical="true">
            <h1 justify="center" align="center" vertical="true" className="loading-white">
                Loading
            </h1>
            <Row className="loadings" gutter={{ xs: 8, sm: 8, md: 10, lg: 12 }}>
                {KRATONGS?.slice(0,6).map((image, index) => {
                    return (
                        <Col className="gutter-row loading" span={4} key={index}>
                            {image?.kratong}
                        </Col>
                    );
                })}
            </Row>
        </Flex>
    );
}