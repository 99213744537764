import React from 'react';

export default function Kratong6() {
    return (
        <svg width="134" height="135" viewBox="0 0 144 145" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="63.5996" y="40.5002" width="6.79719" height="37.3845" fill="url(#paint0_linear_265_155)" />
            <path d="M63.5682 30.7293C61.1892 37.5265 64.5595 38.9425 66.542 38.8009C72.9144 38.8009 71.6399 32.4286 70.7903 30.7293C70.1105 29.3698 67.6749 23.6489 66.542 20.9583C66.542 22.2328 66.542 22.2328 63.5682 30.7293Z" fill="#FFE92E" />
            <path d="M63.5682 30.7293C61.1892 37.5265 64.5595 38.9425 66.542 38.8009C72.9144 38.8009 71.6399 32.4286 70.7903 30.7293C70.1105 29.3698 67.6749 23.6489 66.542 20.9583C66.542 22.2328 66.542 22.2328 63.5682 30.7293Z" fill="#FFE92E" />
            <path d="M63.5682 30.7293C61.1892 37.5265 64.5595 38.9425 66.542 38.8009C72.9144 38.8009 71.6399 32.4286 70.7903 30.7293C70.1105 29.3698 67.6749 23.6489 66.542 20.9583C66.542 22.2328 66.542 22.2328 63.5682 30.7293Z" fill="#FFE92E" />
            <path d="M65.0054 33.0318C63.3883 37.8844 65.6792 38.8954 67.0267 38.7943C71.3581 38.7943 70.4919 34.245 69.9143 33.0318C69.4523 32.0613 67.7968 27.977 67.0267 26.0562C67.0267 26.9661 67.0267 26.9661 65.0054 33.0318Z" fill="#FFA217" />
            <path d="M65.6961 35.3395C64.5411 38.251 66.1774 38.8576 67.1399 38.797C70.2338 38.797 69.615 36.0674 69.2025 35.3395C68.8725 34.7572 67.6899 32.3066 67.1399 31.1541C67.1399 31.7 67.1399 31.7 65.6961 35.3395Z" fill="#FAEE8D" />
            <rect x="75.146" y="13.6667" width="2.1875" height="65.625" fill="#EBC2EE" />
            <rect x="78.7915" y="13.6667" width="2.1875" height="65.625" fill="#EBC2EE" />
            <g filter="url(#filter0_d_265_155)">
                <path d="M112.623 73.6565C113.915 77.0958 112.171 82.934 106.894 87.4518C103.613 90.2607 99.711 91.872 96.1889 91.872C96.1886 91.872 96.1889 91.872 96.1883 91.872C94.6163 91.872 92.458 91.5395 91.1032 89.9572C88.5293 86.9513 89.9122 79.9626 96.0113 74.7405C96.461 74.3553 96.9234 73.9951 97.3939 73.6562H86.7422C81.5162 80.7975 80.7006 89.4566 85.3478 94.8849C91.0746 101.574 102.928 100.823 111.822 93.2072C118.304 87.6572 121.422 79.9943 120.421 73.6562H112.623V73.6565Z" fill="#F9BB32" />
                <path d="M120.363 69.8327C114.636 63.1439 102.783 63.895 93.8888 71.5105C84.9942 79.1259 82.426 90.7217 88.1527 97.4102C93.8795 104.099 105.733 103.348 114.627 95.7325C123.522 88.117 126.09 76.5212 120.363 69.8327ZM109.7 89.9771C106.419 92.786 102.516 94.3973 98.9944 94.3973C98.9941 94.3973 98.9943 94.3973 98.9938 94.3973C97.4218 94.3973 95.2635 94.0648 93.9087 92.4825C91.3348 89.4766 92.7177 82.4879 98.8168 77.2658C102.098 74.4566 106 72.8456 109.523 72.8456C111.095 72.8456 113.253 73.1778 114.608 74.7604C117.182 77.7664 115.799 84.7551 109.7 89.9771Z" fill="#FFEACF" />
                <path d="M93.8892 71.5105C84.9947 79.1259 82.4265 90.7217 88.1532 97.4102C92.8582 102.905 101.697 103.375 109.635 99.1457L112.096 87.5623C111.398 88.3933 110.602 89.2047 109.7 89.9771C106.419 92.786 102.517 94.3973 98.9946 94.3973C98.9943 94.3973 98.9946 94.3973 98.994 94.3973C97.422 94.3973 95.2637 94.0648 93.9089 92.4825C91.335 89.4766 92.7179 82.4879 98.817 77.2658C102.098 74.4566 106 72.8456 109.523 72.8456C111.095 72.8456 113.253 73.1778 114.608 74.7605C114.666 74.8275 114.719 74.899 114.772 74.97L116.503 66.8263C110.244 63.6776 101.096 65.3401 93.8892 71.5105Z" fill="#FFD59A" />
                <path d="M86.6629 115.332H57.0662C52.221 115.332 48.0362 111.943 47.0293 107.204L36.5583 57.9258C36.1708 56.1022 37.5616 54.3846 39.4261 54.3846H104.304C106.168 54.3846 107.559 56.1019 107.171 57.9258L96.7004 107.204C95.6929 111.943 91.5081 115.332 86.6629 115.332Z" fill="#FFEACF" />
                <path d="M58.2516 107.204L47.7806 57.9253C47.3931 56.1016 48.7839 54.3841 50.6485 54.3841H39.4261C37.5618 54.3841 36.1708 56.1016 36.5583 57.9253L47.0293 107.204C48.0362 111.943 52.2213 115.332 57.0662 115.332H68.2886C63.4433 115.332 59.2585 111.943 58.2516 107.204Z" fill="#FFD59A" />
                <path d="M117.713 124.435H25.2105C23.6172 124.435 22.1238 123.597 21.2089 122.189L17.8986 117.095C17.3936 116.318 17.9084 115.249 18.788 115.249H124.942C125.873 115.249 126.372 116.43 125.763 117.189L121.408 122.614C120.479 123.771 119.131 124.435 117.713 124.435Z" fill="#FFD59A" />
                <path d="M43.5889 70.7661L51.1454 106.329C51.7357 109.107 54.2257 111.124 57.066 111.124H86.6627C89.5031 111.124 91.993 109.107 92.5833 106.329L100.14 70.7661H43.5889Z" fill="#A56021" />
                <path d="M100.648 58.593H43.0805C42.0099 58.593 41.2114 59.5792 41.4339 60.6262L43.5886 70.7664H100.14L102.295 60.6262C102.517 59.5789 101.719 58.593 100.648 58.593Z" fill="#DB9C2E" />
                <path d="M50.509 70.7662L47.9223 58.5927H43.081C42.0104 58.5927 41.2119 59.5789 41.4344 60.6259L43.5891 70.7662H50.509V70.7662Z" fill="#BF6C0D" />
                <path d="M93.9383 79.4755C93.8225 79.4755 93.7046 79.4634 93.5868 79.4385C92.6775 79.2452 92.0967 78.3513 92.2901 77.442L93.1626 73.3355C93.3559 72.4262 94.2495 71.8452 95.159 72.0388C96.0683 72.2321 96.6491 73.1259 96.4558 74.0352L95.5832 78.1417C95.4149 78.9329 94.7163 79.4755 93.9383 79.4755Z" fill="#FFD59A" />
                <path d="M89.0226 105.417C88.9067 105.417 88.7889 105.405 88.6711 105.38C87.7618 105.187 87.181 104.293 87.3746 103.384L91.3874 84.5084C91.5807 83.5992 92.474 83.0176 93.3839 83.212C94.2932 83.4053 94.8739 84.2992 94.6804 85.2084L90.6675 104.084C90.4992 104.875 89.8003 105.417 89.0226 105.417Z" fill="#FFD59A" />
                <path d="M62.3256 98.657C64.6498 98.657 66.5339 96.7728 66.5339 94.4486C66.5339 92.1244 64.6498 90.2402 62.3256 90.2402C60.0013 90.2402 58.1172 92.1244 58.1172 94.4486C58.1172 96.7728 60.0013 98.657 62.3256 98.657Z" fill="#8C4C17" />
                <path d="M70.7426 108.171C73.0668 108.171 74.9509 106.287 74.9509 103.962C74.9509 101.638 73.0668 99.754 70.7426 99.754C68.4183 99.754 66.5342 101.638 66.5342 103.962C66.5342 106.287 68.4183 108.171 70.7426 108.171Z" fill="#8C4C17" />
                <path d="M58.2514 107.204L50.5088 70.7661H43.5889L51.1454 106.329C51.7357 109.107 54.2257 111.124 57.066 111.124H60.0032C59.1691 109.983 58.5603 108.657 58.2514 107.204Z" fill="#8C4C17" />
            </g>
            <defs>
                <filter id="filter0_d_265_155" x="14.783" y="54.3841" width="114.164" height="75.8843" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2.91667" />
                    <feGaussianBlur stdDeviation="1.45833" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_265_155" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_265_155" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_265_155" x1="66.9982" y1="40.5002" x2="66.9982" y2="77.8848" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFE92E" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
            </defs>
        </svg>
    );
}