import Avatar1 from "../../../assets/avatar/Avatar1";
import Avatar2 from "../../../assets/avatar/Avatar2";
import Avatar3 from "../../../assets/avatar/Avatar3";
import Avatar4 from "../../../assets/avatar/Avatar4";
import Avatar5 from "../../../assets/avatar/Avatar5";
import Avatar6 from "../../../assets/avatar/Avatar6";
import Kratong1 from "../../../assets/kratong/Kratong1";
import Kratong2 from "../../../assets/kratong/Kratong2";
import Kratong3 from "../../../assets/kratong/Kratong3";
import Kratong4 from "../../../assets/kratong/Kratong4";
import Kratong5 from "../../../assets/kratong/Kratong5";
import Kratong6 from "../../../assets/kratong/Kratong6";
import Kratong7 from "../../../assets/kratong/Kratong7";
import Kratong8 from "../../../assets/kratong/Kratong8";
import TreeGray from "../../../assets/tree/TreeGray";
import TreeGreen from "../../../assets/tree/TreeGreen";
import TreeRed from "../../../assets/tree/TreeRed";
import TreeYellow from "../../../assets/tree/TreeYellow";
import React from "react";

export const CREATE_STATE = [
  "START",
  "CREATE_ICON",
  "CREATE_KRATONG",
  "CONFIRM",
  "COLLECT",
  "LOADING",
  "ALERT_PICKUP",
];

// export const CUSTOM_ICONS = [
//   { icon: "icon1", face: "#F4D5CC", neck: "#D6B7AE", hairType1: "#393939" },
//   { icon: "icon2", face: "#F4D4C9", neck: "#D6B6AB", hairType1: "#613F2B" },
//   { icon: "icon3", face: "#EED4CC", neck: "#D0B6AE", hairType1: "#E7939E", hairType2: "#FFBBC6" },
//   { icon: "icon4", face: "#F6DDD6", neck: "#D8BFB8", hairType1: "#62360D", hairType2: "#8A5E35", hairType3: "#9E7249", hairType4: "#4E2200" },
//   { icon: "icon5", face: "#F6DDD6", neck: "#D8BFB8", hairType1: "#543415", hairType4: "#402001" },
//   { icon: "icon6", face: "#F6DDD6", neck: "#D8BFB8", hairType1: "#D47581" },
// ];

export const CUSTOM_ICONS = {
  icon1: {
    icon: "icon1",
    face: "#F4D5CC",
    neck: "#D6B7AE",
    hairType1: "#393939",
  },
  icon2: {
    icon: "icon2",
    face: "#F4D4C9",
    neck: "#D6B6AB",
    hairType1: "#613F2B",
  },
  icon3: {
    icon: "icon3",
    face: "#EED4CC",
    neck: "#D0B6AE",
    hairType1: "#E7939E",
    hairType2: "#FFBBC6",
  },
  icon4: {
    icon: "icon4",
    face: "#F6DDD6",
    neck: "#D8BFB8",
    hairType1: "#62360D",
    hairType2: "#8A5E35",
    hairType3: "#9E7249",
    hairType4: "#4E2200",
  },
  icon5: {
    icon: "icon5",
    face: "#F6DDD6",
    neck: "#D8BFB8",
    hairType1: "#543415",
    hairType4: "#402001",
  },
  icon6: {
    icon: "icon6",
    face: "#F6DDD6",
    neck: "#D8BFB8",
    hairType1: "#D47581",
  },
};

export const USER_ICONS = [
  {
    icon: <Avatar1 custom={CUSTOM_ICONS.icon1} />,
    key: "icon1",
    image: require("../../../assets/avatar/images/Avatar1.png"),
  },
  {
    icon: <Avatar2 custom={CUSTOM_ICONS.icon2} />,
    key: "icon2",
    image: require("../../../assets/avatar/images/Avatar2.png"),
  },
  {
    icon: <Avatar3 custom={CUSTOM_ICONS.icon3} />,
    key: "icon3",
    image: require("../../../assets/avatar/images/Avatar3.png"),
  },
  {
    icon: <Avatar4 custom={CUSTOM_ICONS.icon4} />,
    key: "icon4",
    image: require("../../../assets/avatar/images/Avatar4.png"),
  },
  {
    icon: <Avatar5 custom={CUSTOM_ICONS.icon5} />,
    key: "icon5",
    image: require("../../../assets/avatar/images/Avatar5.png"),
  },
  {
    icon: <Avatar6 custom={CUSTOM_ICONS.icon6} />,
    key: "icon6",
    image: require("../../../assets/avatar/images/Avatar6.png"),
  },
];

export const DETAIL_KRATONGS = [
  {
    key: "Krathong6",
    name: "Traditional Krathong (Banana Tree & Leaves)",
    co2: 0.1,
    level: 5,
    link: "https://www.forbes.com/sites/alanohnsman/2023/11/11/planes-trains-and-climate-change/#:~:text=Data%20compiled%20by%20the%20U,4%20grams%20per%20passenger",
    description:
      "A banana tree & leaves Krathong is a greener choice, with only 0.1 kg CO2e emissions, comparable to printing 100 standard pages. While this is only an estimate, it reflects the potential for more sustainable celebration practices.",
  },
  {
    key: "Krathong3",
    name: "Traditional Krathong (Foam & Plastic)",
    co2: 1,
    level: 3,
    link: "https://www.thaienquirer.com/35133/loy-krathong-a-sacred-river-offering-or-an-ecological-menace/#:~:text=URL%3A%20https%3A%2F%2Fwww.thaienquirer.com%2F35133%2Floy",
    description:
      "Choosing a foam and plastic Krathong equates to a CO2e output akin to watching TV non-stop for 40 hours. As with all estimates, consult detailed life cycle assessments for a comprehensive understanding.",
  },
  {
    key: "Krathong5",
    name: "Traditional Krathong (Ice or Bread)",
    co2: 0.2,
    level: 4,
    link: "https://www.thaienquirer.com/35133/loy-krathong-a-sacred-river-offering-or-an-ecological-menace/#:~:text=URL%3A%20https%3A%2F%2Fwww.thaienquirer.com%2F35133%2Floy",
    description:
      "Opting for ice or bread Krathongs during festivities emits about 0.2 kg Carbon emission (CO2e), a minor but noteworthy contribution to your carbon footprint.",
  },
  {
    key: "Krathong2",
    name: "Salmon (served portion) from Norway",
    co2: 6,
    level: 2,
    link: "https://www.sintef.no/en/latest-news/2023/reducing-the-climate-footprint-of-the-salmon-farming-industry/",
    description:
      "Enjoying Norwegian salmon on a dining table in Bangkok has a carbon footprint similar to a 6 km car journey. These values are based on estimates and further research and life cycle assessments are advised for precise figures.",
  },
  {
    key: "Krathong8",
    name: "Plastic Bag",
    co2: 1.58,
    level: 3,
    link: "https://www.co2everything.com/co2e-of/plastic-bag",
    description:
      "The environmental toll of a single-use plastic grocery bag is significant, amounting to 1.58 kg CO2e. It's a stark reminder to choose reusable options whenever possible. These figures are based on general studies and should be further verified with detailed analyses.",
  },
  {
    key: "Krathong7",
    name: "Cup of Coffee",
    co2: 0.05,
    level: 5,
    link: "https://foodanddrink.scotsman.com/drink/new-study-shows-recyclable-coffee-cups-can-reduce-carbon-footprint-and-save-water-heres-how/",
    description:
      "Using a single-use plastic coffee cup results in 0.06 kg CO2e emissions—less than driving a car for 600 meters. This data is an estimate and detailed assessments are recommended for accuracy.",
  },
  {
    key: "Krathong1",
    name: "Smart Phone (annual replacement)",
    co2: 70,
    level: 1,
    link: "https://www.compareandrecycle.co.uk/blog/iphone-lifecycle-what-is-the-carbon-footprint-of-an-iphone",
    description:
      "Switching out your smartphone less frequently can have a notable environmental impact, reducing CO2e emissions equivalent to driving 175 km. Remember, these are average estimates; specific models may vary.",
  },
  {
    key: "Krathong4",
    name: "BTS Train (10 km)",
    co2: 0.4,
    level: 4,
    link: "https://www.forbes.com/sites/alanohnsman/2023/11/11/planes-trains-and-climate-change/#:~:text=Data%20compiled%20by%20the%20U,4%20grams%20per%20passenger",
    description:
      "A journey on the BTS Skytrain in Bangkok for 10 km per passenger can emit 0.4 kg of CO2e. This figure is indicative and should be used as a guideline.",
  },
];

export const KRATONGS = [
  {
    kratong: <Kratong1 />,
    key: "Krathong6",
    image: require("../../../assets/kratong/image/kratong1.png"),
  },
  {
    kratong: <Kratong2 />,
    key: "Krathong3",
    image: require("../../../assets/kratong/image/kratong2.png"),
  },
  {
    kratong: <Kratong3 />,
    key: "Krathong5",
    image: require("../../../assets/kratong/image/kratong3.png"),
  },
  {
    kratong: <Kratong4 />,
    key: "Krathong2",
    image: require("../../../assets/kratong/image/kratong4.png"),
  },
  {
    kratong: <Kratong5 />,
    key: "Krathong8",
    image: require("../../../assets/kratong/image/kratong5.png"),
  },
  {
    kratong: <Kratong6 />,
    key: "Krathong7",
    image: require("../../../assets/kratong/image/kratong6.png"),
  },
  {
    kratong: <Kratong7 />,
    key: "Krathong1",
    image: require("../../../assets/kratong/image/kratong7.png"),
  },
  {
    kratong: <Kratong8 />,
    key: "Krathong4",
    image: require("../../../assets/kratong/image/kratong8.png"),
  },
];

export const TREES = {
  gray: {
    svg: <TreeGray />,
    image: require("../../../assets/tree/image/TreeGray.png"),
  },
  green: {
    svg: <TreeGreen />,
    image: require("../../../assets/tree/image/TreeGreen.png"),
  },
  red: {
    svg: <TreeRed />,
    image: require("../../../assets/tree/image/TreeRed.png"),
  },
  yellow: {
    svg: <TreeYellow />,
    image: require("../../../assets/tree/image/TreeYellow.png"),
  },
};
