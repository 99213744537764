import React from 'react';
export default function Avatar3({ custom }) {
  const face = custom.face;
  const neck = custom.neck;
  const hair = custom.hairType1;
  const hair2 = custom.hairType2;
  return (
    <svg width="104" height="175" viewBox="0 0 104 175" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M73.4678 107.566C77.2665 105.423 82.0832 106.765 84.2261 110.564C89.129 119.256 93.5139 131.966 96.2726 144.74C99.1466 158.048 99.8908 169.259 97.5759 175.625C96.0854 179.724 91.5542 181.839 87.4553 180.348C83.3974 178.873 81.2844 174.417 82.6887 170.351L82.7324 170.228C82.7755 170.109 82.8328 169.89 82.8898 169.584L82.902 169.517C82.9826 169.066 83.047 168.513 83.09 167.873C83.1848 166.456 83.1683 164.705 83.0346 162.691C82.7632 158.602 82.0244 153.643 80.8982 148.372L80.8341 148.074C78.3846 136.732 74.4979 125.466 70.4695 118.324C68.3266 114.526 69.669 109.709 73.4678 107.566Z" fill={face} />
      <path d="M20.1367 110.564C22.2796 106.765 27.0963 105.423 30.8951 107.566C34.6938 109.709 36.0362 114.526 33.8933 118.324C29.8649 125.466 25.9781 136.732 23.5286 148.074L23.4011 148.67C22.3106 153.83 21.5944 158.679 21.3281 162.691C21.1944 164.705 21.1779 166.456 21.2728 167.873C21.3126 168.468 21.371 168.986 21.4437 169.418L21.4607 169.517C21.5176 169.836 21.5758 170.068 21.6209 170.201L21.6303 170.228C23.1209 174.327 21.0063 178.858 16.9074 180.348C12.8085 181.839 8.27734 179.724 6.78683 175.625C4.47187 169.259 5.21607 158.048 8.09012 144.74C10.8488 131.966 15.2337 119.256 20.1367 110.564Z" fill={face} />
      <path d="M91.5402 112.842C90.3449 111.391 89.4058 110.53 89.0856 110.388L80.4414 110.601L75.3188 145.818L103.493 139.202C100.611 129.704 92.7354 114.293 91.5402 112.842Z" fill="#FAFAFB" />
      <path d="M16.3228 110.494C17.5181 109.214 27.9196 104.767 32.9711 102.704L48.1252 106.226L68.9355 102.704C74.9828 104.305 86.6508 108.573 88.7852 110.067C90.2323 111.08 85.4769 111.988 85.0501 116.577C84.5186 122.29 79.0539 141.123 79.0539 146.032C79.0539 162.787 82.0619 187.546 80.9947 190.64C80.7917 191.229 69.1016 190.355 53.804 190.477C43.4463 190.56 29.8348 190.404 21.979 190.534C20.4849 172.071 24.8538 146.083 23.2596 133.546C20.9117 115.083 14.8287 112.095 16.3228 110.494Z" fill="#FAFAFB" />
      <path d="M31.6707 107.877C31.6707 104.313 34.5602 101.423 38.1246 101.423H64.0625C67.6269 101.423 70.5164 104.313 70.5164 107.877C70.5164 117.279 62.8946 124.901 53.4922 124.901H48.6949C39.2925 124.901 31.6707 117.279 31.6707 107.877Z" fill="#F2F0F5" />
      <path d="M13.7418 112.629C14.5102 111.775 15.9829 110.708 16.6232 110.494L26.2279 120.099V145.391L0.295166 138.561C4.99081 130.486 12.9734 113.482 13.7418 112.629Z" fill="#FAFAFB" />
      <path d="M30.9982 102.505C32.1081 101.907 33.2393 101.829 33.6662 101.864C34.5199 104.71 36.4836 111.896 37.5081 117.872C38.5326 123.848 47.6818 130.75 52.1286 133.453C55.4369 131.141 62.9072 125.278 66.3223 120.327C69.7373 115.375 70.591 105.955 70.591 101.864C70.9112 101.864 72.2985 101.352 73.5792 101.864C74.8598 102.377 79.3064 104.923 81.5831 105.813C78.4882 110.615 77.8479 117.445 77.8479 121.714C77.8479 127.05 80.7293 142.097 80.089 144.979C79.4487 147.86 81.5831 165.896 81.5831 172.406C81.5831 178.916 84.2511 189.161 81.5831 191.082C78.9151 193.002 63.9744 192.149 59.0654 191.082C54.1563 190.014 39.7492 191.082 37.4014 192.149C35.0535 193.216 26.3026 190.228 23.3144 191.082C20.3262 191.935 22.2472 187.026 22.2472 184.678C22.2472 182.331 21.3934 176.034 22.2472 170.378C22.9302 165.853 23.1364 151.666 23.2077 145.085C24.1325 141.777 26.1318 132.856 26.3026 124.062C26.4733 115.268 25.3064 108.232 24.7018 105.813C26.338 104.959 29.8883 103.102 30.9982 102.505Z" fill="black" />
      <mask id="mask0_793_2360" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="21" y="101" width="62" height="92">
        <path d="M30.9982 102.505C32.1081 101.907 33.2393 101.829 33.6662 101.864C34.5199 104.71 36.4836 111.896 37.5081 117.872C38.5326 123.848 47.6818 130.75 52.1286 133.453C55.4369 131.141 62.9072 125.278 66.3223 120.327C69.7373 115.375 70.591 105.955 70.591 101.864C70.9112 101.864 72.2985 101.352 73.5792 101.864C74.8598 102.377 79.3064 104.923 81.5831 105.813C78.4883 110.615 77.8479 117.445 77.8479 121.714C77.8479 127.05 80.7294 142.097 80.089 144.979C79.4487 147.86 81.5831 165.896 81.5831 172.406C81.5831 178.916 84.2511 189.161 81.5831 191.082C78.9151 193.002 63.9744 192.149 59.0654 191.082C54.1563 190.014 39.7492 191.082 37.4014 192.149C35.0535 193.216 26.3026 190.228 23.3144 191.082C20.3262 191.935 22.2472 187.026 22.2472 184.678C22.2472 182.331 21.3934 176.034 22.2472 170.378C22.9302 165.853 23.1364 151.666 23.2077 145.085C24.1325 141.777 26.1318 132.856 26.3026 124.062C26.4733 115.268 25.3064 108.232 24.7018 105.813C26.338 104.959 29.8883 103.102 30.9982 102.505Z" fill="white" />
      </mask>
      <g mask="url(#mask0_793_2360)">
        <rect x="22.9436" y="141.146" width="59.7627" height="3.41501" fill="#E30000" />
        <rect x="22.9436" y="144.561" width="59.7627" height="3.41501" fill="#FF993A" />
        <rect x="22.9436" y="147.976" width="59.7627" height="3.41501" fill="#FFF50A" />
        <rect x="22.9436" y="151.391" width="59.7627" height="3.41501" fill="#2C8A00" />
        <rect x="22.9436" y="154.806" width="59.7627" height="3.41501" fill="#00239F" />
        <rect x="22.9436" y="158.221" width="59.7627" height="3.41501" fill="#5A0084" />
      </g>
      <path d="M22.5997 106.332C22.9412 106.161 24.0227 105.834 24.5206 105.692C25.8013 106.652 27.6155 118.946 27.1886 125.862C26.7617 132.777 24.5206 142.297 23.0266 145.712C22.8842 145.214 22.5997 144.047 22.5997 143.364C22.5997 142.51 24.076 131.723 23.3467 124.795C22.7064 118.712 22.1728 109.961 20.572 107.399C21.1056 107.115 22.2582 106.503 22.5997 106.332Z" fill="white" />
      <mask id="mask1_793_2360" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="19" y="105" width="8" height="41">
        <path fillRule="evenodd" clipRule="evenodd" d="M22.5996 106.332C23.2399 106.012 24.0226 105.834 24.5206 105.692C25.481 109.391 27.2953 118.52 26.8684 125.435C26.4415 132.35 24.1293 141.834 23.0265 145.712C22.8841 145.214 22.5996 144.047 22.5996 143.364C22.5996 142.51 22.2795 128.21 22.2795 122.874C22.2795 117.538 21.1056 110.708 19.9316 107.933C20.2518 107.399 21.6391 106.812 22.5996 106.332Z" fill="white" />
      </mask>
      <g mask="url(#mask1_793_2360)">
        <path d="M24.6273 121.273C24.6273 113.675 23.24 108.289 22.2795 105.905L18.8645 106.332C19.4337 120.775 21.5042 149.279 22.493 145.818C23.7736 141.336 24.6273 130.771 24.6273 121.273Z" fill="#A5A4A4" />
      </g>
      <path d="M80.3503 104.846C80.0045 104.673 78.9094 104.342 78.405 104.198C77.1084 105.17 75.2711 117.62 75.7033 124.623C76.1357 131.626 78.405 141.265 79.9181 144.723C80.0622 144.219 80.3503 143.038 80.3503 142.346C80.3503 141.481 78.8554 130.558 79.5939 123.542C80.2423 117.382 80.7827 108.521 82.4036 105.927C81.8634 105.639 80.6961 105.019 80.3503 104.846Z" fill="white" />
      <mask id="mask2_793_2360" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="75" y="104" width="9" height="41">
        <path fillRule="evenodd" clipRule="evenodd" d="M80.3505 104.846C79.7021 104.522 78.9096 104.342 78.4054 104.198C77.4328 107.944 75.5955 117.188 76.0279 124.19C76.4602 131.193 78.8016 140.797 79.9183 144.723C80.0624 144.219 80.3505 143.038 80.3505 142.346C80.3505 141.481 80.6747 127 80.6747 121.597C80.6747 116.194 81.8636 109.277 83.0522 106.467C82.728 105.927 81.3231 105.332 80.3505 104.846Z" fill="white" />
      </mask>
      <g mask="url(#mask2_793_2360)">
        <path d="M78.2974 119.976C78.2974 112.281 79.7022 106.828 80.6749 104.414L84.133 104.846C83.5567 119.472 81.4599 148.336 80.4586 144.832C79.1618 140.293 78.2974 129.594 78.2974 119.976Z" fill="#A5A4A4" />
      </g>
      <path d="M39.794 118.101C37.4888 112.893 37.2325 104.334 38.193 100.706C35.9519 100.172 33.7464 101.346 33.4973 101.773C32.5371 104.975 32.9832 114.427 34.3513 118.421C36.9 125.862 48.4256 135.04 52.3742 136.747L56.3229 133.973C53.3347 131.625 42.0991 123.309 39.794 118.101Z" fill="white" />
      <mask id="mask3_793_2360" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="32" y="100" width="25" height="37">
        <path fillRule="evenodd" clipRule="evenodd" d="M39.794 118.101C37.4889 112.893 37.2325 104.334 38.193 100.706C35.9519 100.172 33.7464 101.346 33.4973 101.773C32.5371 104.975 32.857 114.473 34.3513 118.421C37.1012 125.688 45.0106 132.692 52.1732 136.961L56.3229 133.973C53.228 131.518 42.0991 123.309 39.794 118.101Z" fill="white" />
      </mask>
      <g mask="url(#mask3_793_2360)">
        <path d="M38.0741 119.992C35.5128 114.955 34.9792 106.652 36.0462 99.6089L39.248 100.036L41.5958 117.218L59.8446 133.759L56.5363 136.854C52.6944 134.399 40.6353 125.029 38.0741 119.992Z" fill="#7E7E7E" />
      </g>
      <path d="M64.5403 118.101C66.8455 112.893 67.1018 104.334 66.1413 100.706C68.3824 100.172 70.5879 101.346 70.837 101.773C71.7972 104.975 71.4771 114.473 69.983 118.421C67.2331 125.688 59.987 131.868 56.3229 134.002L52.1609 130.801C55.3268 128.738 62.2352 123.309 64.5403 118.101Z" fill="white" />
      <mask id="mask4_793_2360" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="52" y="100" width="20" height="35">
        <path fillRule="evenodd" clipRule="evenodd" d="M64.5403 118.101C66.8455 112.893 67.1018 104.334 66.1413 100.706C68.3824 100.172 70.5879 101.346 70.837 101.773C71.7972 104.975 71.4771 114.473 69.983 118.421C67.2331 125.688 59.987 131.868 56.3229 134.002L52.1609 130.801C55.3268 128.738 62.2352 123.309 64.5403 118.101Z" fill="white" />
      </mask>
      <g mask="url(#mask4_793_2360)">
        <path d="M66.2604 119.992C68.8216 114.955 69.355 106.652 68.2883 99.6089L65.0865 100.036L62.7386 117.218L50.0391 130.557L52.4938 133.439C56.3357 130.984 63.6991 125.029 66.2604 119.992Z" fill="#7E7E7E" />
      </g>
      <rect x="42.0164" y="93.6652" width="20.9831" height="27.4211" rx="10.4915" transform="rotate(-1 42.0164 93.6652)" fill={neck} />
      <path d="M21.2064 75.7944C27.9006 75.7944 33.3274 70.647 33.3274 64.2973C33.3274 57.9477 27.9006 52.8003 21.2064 52.8003C14.5122 52.8003 9.08545 57.9477 9.08545 64.2973C9.08545 70.647 14.5122 75.7944 21.2064 75.7944Z" fill={neck} />
      <path d="M84.1002 76.6855C90.7944 76.6855 96.2212 71.5381 96.2212 65.1884C96.2212 58.8388 90.7944 53.6913 84.1002 53.6913C77.406 53.6913 71.9792 58.8388 71.9792 65.1884C71.9792 71.5381 77.406 76.6855 84.1002 76.6855Z" fill={neck} />
      <path d="M18.1146 53.9503C16.0095 34.1621 31.2365 16.7877 51.1298 16.2791C71.1578 15.767 87.304 32.5593 86.0067 52.5519L85.5758 59.1928L84.518 73.3436C83.2653 90.1021 69.3026 103.06 52.4971 103.06C36.2993 103.06 22.6376 90.996 20.6336 74.9226L18.6723 59.1928L18.1146 53.9503Z" fill={face} />
      <path d="M34.1229 59.3312C35.9076 58.439 38.7176 58.2372 41.3624 59.2403C42.5197 59.6793 43.102 60.9733 42.663 62.1306C42.2314 63.2686 40.9729 63.8506 39.8307 63.4523L39.7728 63.4312C38.9632 63.1241 38.1477 63.0165 37.3719 63.0722C37.0623 63.0945 36.773 63.1423 36.5201 63.208C36.3518 63.2516 36.2185 63.2984 36.1466 63.331L36.1269 63.3404C35.0198 63.8938 33.6737 63.4449 33.1203 62.3378C32.5669 61.2307 33.0157 59.8846 34.1229 59.3312Z" fill="black" />
      <path d="M61.2296 59.5446C63.0143 58.6525 65.8243 58.4506 68.4691 59.4538C69.6264 59.8928 70.2087 61.1868 69.7697 62.344C69.338 63.482 68.0796 64.064 66.9374 63.6657L66.8794 63.6446C66.0699 63.3376 65.2544 63.2299 64.4786 63.2857C64.169 63.3079 63.8797 63.3558 63.6268 63.4214C63.4585 63.4651 63.3252 63.5118 63.2533 63.5445L63.2336 63.5538C62.1265 64.1072 60.7804 63.6584 60.227 62.5512C59.6736 61.4441 60.1224 60.098 61.2296 59.5446Z" fill="black" />
      <path d="M75.0266 31.7868C74.3464 26.0762 73.7321 21.9759 73.2785 18.1688C72.9573 15.4722 72.9238 14.7844 80.5897 20.4348C88.2555 26.0852 89.4049 38.4819 87.3194 49.0187L85.0144 61.5309L82.6476 60.9459L82.7095 45.0673C78.8471 41.1793 75.707 37.4973 75.0266 31.7868Z" fill={hair2} />
      <path d="M23.1586 37.8784C23.4752 32.1367 22.8283 32.4323 23.0394 28.6045C23.1889 25.8932 24.1231 26.4863 19.283 31.4579C16.6116 34.2019 17.6174 45.9912 18.1861 53.7014L18.8618 60.8076L19.9416 60.8672L20.429 52.0278C22.3371 45.9028 22.842 43.6199 23.1586 37.8784Z" fill={hair2} />
      <path d="M51.446 29.9708C35.2562 29.9708 22.3112 29.9262 21.6437 41.8442C18.9126 45.72 17.2022 45.2886 16.5646 38.2351C14.5225 15.6459 29.8723 6.8889 45.4556 11.9444C45.4556 11.9444 67.6376 29.9708 51.446 29.9708Z" fill={hair} />
      <path d="M50.8911 29.8893C68.2887 29.8893 82.1997 29.8449 82.9169 41.7383C85.8519 45.606 87.6898 45.1757 88.3751 38.1367C90.5695 15.5938 59.2705 -9.0429 31.2827 12.6479C31.2827 12.6479 33.4914 29.8893 50.8911 29.8893Z" fill={hair} />
      <path d="M81.0937 42.1325C81.6537 34.735 85.4399 33.4311 84.3924 30.5035L50.8795 20.6886L48.851 14.7142L32.9433 13.2206C32.9433 13.2206 27.6049 32.1041 41.833 33.7205C56.0608 35.3369 51.8121 38.8272 55.5688 42.988C55.5688 37.9013 57.751 38.0164 59.6741 36.9773C59.3938 41.7867 69.3386 43.7423 74.0558 43.7423C69.634 41.7867 68.9159 38.227 71.0132 36.4924C77.4642 36.9773 76.2873 35.6587 81.0937 42.1325Z" fill={hair} />
      <path d="M57.6393 47.2809L69.534 45.608C69.6647 45.5896 69.797 45.6299 69.895 45.7183L73.911 49.3369C74.2162 49.6121 74.0192 50.1188 73.6082 50.1151L67.8852 50.0656L57.7454 50.9798C57.2046 51.0284 56.7457 50.587 56.7734 50.0447L56.872 48.1195C56.8938 47.6922 57.2157 47.3404 57.6393 47.2809Z" fill={hair} />
      <path d="M43.766 47.5536L32.037 45.4768C31.9063 45.4538 31.7721 45.4898 31.6707 45.5756L27.5616 49.0506C27.2483 49.3157 27.4282 49.8273 27.8384 49.838L33.5125 49.9844L43.5289 51.2439C44.0676 51.3116 44.5415 50.887 44.5331 50.3441L44.5033 48.4191C44.4966 47.9913 44.1876 47.6283 43.766 47.5536Z" fill={hair} />
      <path d="M48.2319 60.4972L51.9918 61.0134C51.9105 61.6054 51.6959 62.2414 51.3162 63.1389L51.2401 63.3169C51.008 63.8513 50.298 65.4208 50.1854 65.6763L50.0576 65.9685C49.7055 66.7804 49.437 67.4588 49.22 68.1016C48.6264 69.8599 48.5197 71.1196 48.8565 71.8242C49.2118 72.5675 50.3119 73.0695 52.6832 73.0845L52.7935 73.0849V76.88C49.0318 76.88 46.5308 75.7589 45.4324 73.4608C44.5615 71.6388 44.7427 69.499 45.6242 66.8877C45.8931 66.0912 46.2165 65.2801 46.6372 64.3174L46.7868 63.9792C47.0153 63.4687 47.5942 62.1869 47.7668 61.7874L47.8846 61.5082C47.9816 61.2735 48.058 61.075 48.1164 60.9057L48.1482 60.8109C48.1871 60.6912 48.2124 60.5982 48.2249 60.537L48.2319 60.4972Z" fill={neck} />
      <path d="M40.8567 3.20553L36.5493 9.13206L62.5009 14.4047L87.5425 32.7364L89.6043 27.1593L87.9107 25.5879L88.6547 23.4322L86.1121 22.5545L87.2621 19.223L83.9928 18.0945L84.1457 15.296L81.2397 14.293L80.3936 11.1498L77.1261 10.8993L76.7592 9.01806L73.6716 7.95232L74.2803 6.18855L69.4942 6.51043L69.0131 4.37052L66.5882 5.5074L65.3806 3.11672L61.321 3.68938L59.7503 1.17332L56.7803 2.12212L55.1458 1.5579L52.7883 2.4988L52.6029 0.680237L48.3655 2.94606L46.6381 1.47258L42.6855 4.38506L40.8567 3.20553Z" fill={hair} />
      <path d="M61.3094 79.4486C62.2093 79.336 63.0316 79.9598 63.1712 80.8494L63.1786 80.9021C63.2485 81.4605 63.5926 82.2793 64.044 82.7251L64.0946 82.7731C64.7789 83.3951 64.8294 84.454 64.2074 85.1382C63.5854 85.8225 62.5265 85.873 61.8423 85.2511C60.6978 84.2108 60.0194 82.6243 59.8559 81.3178C59.7411 80.4003 60.3918 79.5634 61.3094 79.4486Z" fill="#C99584" />
      <path d="M40.5474 81.5421C41.0482 80.7648 42.0844 80.5407 42.8617 81.0415C43.9829 81.764 44.9025 82.2452 46.1204 82.6665C47.7915 83.2445 49.7257 83.5651 52.0295 83.5651C54.3425 83.5651 56.3752 83.1984 58.1285 82.563C59.3094 82.1351 60.2483 81.6283 60.8893 81.1612L60.9593 81.1094C61.6991 80.5547 62.7486 80.7047 63.3033 81.4445C63.8581 82.1843 63.7081 83.2338 62.9682 83.7885C62.0669 84.4644 60.8113 85.1525 59.2694 85.7113C57.1552 86.4774 54.737 86.9138 52.0295 86.9138C49.3556 86.9138 47.0514 86.5318 45.0258 85.8312C43.5176 85.3095 42.3752 84.7117 41.0479 83.8564C40.2706 83.3555 40.0465 82.3194 40.5474 81.5421Z" fill="#C99584" />
    </svg>
  );
}
