import { Grid } from "antd";
import "./Confirm.style.css";
import React, { useState } from "react";
import ReactGA from "react-ga4";
import { KRATONGS, USER_ICONS, DETAIL_KRATONGS } from "../configure/modalState";
import CustomButton from "../../button/CustomButton";
import CO2Level from "../../co2/CO2Level";
import Loading from "../loading/Loading";

export default function Confirm({ data, onLoy, canLoy }) {
  const { useBreakpoint } = Grid;
  const { xs } = useBreakpoint();
  const { level, description, name, link } = DETAIL_KRATONGS?.find(
    (k) => k?.key === data?.noTypeKrathong
  );
  const [loading, setLoading] = useState(false);
  return (
    <>
      <div className="wrapper">
        <div className={`${xs ? "mobile-container" : "container"}`}>
          <section></section>
          <section>
            <span className="title slideinplain" style={{"--delay": "100ms"}} data-mobile={xs ? true : false}>
              {canLoy ? `This is your Krathong, ${data?.name}!` : `Thanks for picking up ${name}!`}
            </span>
          </section>
          <section id="preivew-left" className="slideinplain" style={{"--delay": "100ms"}}>
            <div className="kratong-container" data-mobile={xs ? true : false} >
              <div className="kratong-circle">
                {
                  <img src={KRATONGS?.find((k) => k?.key === data?.noTypeKrathong)?.image} alt={data?.noTypeKrathong} />
                }
              </div>
            </div>
            <div className="name-selected-krathong">
              {
                DETAIL_KRATONGS?.find((k) => k?.key === data?.noTypeKrathong)
                  ?.name
              }
            </div>
          </section>
          <section className="user-information-container slideinplain" style={{"--delay": "400ms"}}>
            <div className="user-information">
              <div className="icon-container">
                {React.cloneElement(
                  USER_ICONS.find((i) => i?.key === data?.avatar.icon)?.icon,
                  { custom: data.avatar }
                )}
              </div>
              <span className="name">{data?.name}</span>
            </div>
            <div className="message-box">{data?.wish}</div>
          </section>
          <section className="slideinplain" style={{"--delay": "600ms"}}>
            <div>
              <span className="tree-header">
                Eco-friendliness Score
              </span>
              <div className="gray-container-tree tree-box">
                <CO2Level level={level} height={30} />
              </div>
            </div>
          </section>
          <section className="carbon-message slideinplain" style={{"--delay": "800ms"}}>
            <div className="gray-container-message co2-message-box">
              <div>
                {description}
                &nbsp;<a className="learn-more" href={link} rel="noreferrer" target="_blank">
                  Learn more
                </a>
              </div>
            </div>
          </section>
        </div>
        <section id="button" className="button-container slideinplain" style={{"--delay": "1000ms"}}>
          <CustomButton
            onClick={() => {
              localStorage?.setItem("tempKratong", JSON?.stringify(data));
              window.open("/download", "_blank");
            }}
            block
          >
            Save as picture
          </CustomButton>
          {canLoy ? <CustomButton
            type="primary"
            onClick={async () => {
              setLoading(true);
              ReactGA.event({
                category: "user action",
                action: "loy-kratong",
                label: "test label 2",
                value: 2,
              });
              await onLoy();
              setLoading(false);
            }}
            block
          >
            Let’s float your Krathong!
          </CustomButton> : (
            <CustomButton
              type="primary"
              onClick={async () => window.open("https://www.accenture.com/cr-en/about/sustainability/sustainability-value-promise", "_blank")}
              block
            >
              More about our sustainability mission
            </CustomButton>
          )}
        </section>
      </div>
      {loading && <Loading />}
    </>
  );
}
