import React from 'react';

export default function Kratong8() {
    return (
        <svg width="139" height="104" viewBox="0 0 139 104" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="75.3335" y="0.8125" width="2.1875" height="65.625" fill="#EBC2EE" />
            <rect x="78.979" y="0.8125" width="2.1875" height="65.625" fill="#EBC2EE" />
            <rect x="65.9746" y="26.9169" width="6.79719" height="37.3845" fill="url(#paint0_linear_0_1)" />
            <path d="M65.9432 17.146C63.5642 23.9431 66.9345 25.3592 68.917 25.2176C75.2894 25.2176 74.0149 18.8452 73.1653 17.146C72.4855 15.7865 70.0499 10.0656 68.917 7.375C68.917 8.64947 68.917 8.64947 65.9432 17.146Z" fill="#FFE92E" />
            <path d="M65.9432 17.146C63.5642 23.9431 66.9345 25.3592 68.917 25.2176C75.2894 25.2176 74.0149 18.8452 73.1653 17.146C72.4855 15.7865 70.0499 10.0656 68.917 7.375C68.917 8.64947 68.917 8.64947 65.9432 17.146Z" fill="#FFE92E" />
            <path d="M65.9432 17.146C63.5642 23.9431 66.9345 25.3592 68.917 25.2176C75.2894 25.2176 74.0149 18.8452 73.1653 17.146C72.4855 15.7865 70.0499 10.0656 68.917 7.375C68.917 8.64947 68.917 8.64947 65.9432 17.146Z" fill="#FFE92E" />
            <path d="M67.3804 19.4485C65.7633 24.3011 68.0542 25.312 69.4017 25.2109C73.7331 25.2109 72.8669 20.6616 72.2893 19.4485C71.8273 18.478 70.1718 14.3937 69.4017 12.4729C69.4017 13.3827 69.4017 13.3827 67.3804 19.4485Z" fill="#FFA217" />
            <path d="M68.0711 21.7561C66.9161 24.6677 68.5524 25.2743 69.5149 25.2136C72.6088 25.2136 71.99 22.484 71.5775 21.7561C71.2475 21.1738 70.0649 18.7233 69.5149 17.5708C69.5149 18.1167 69.5149 18.1167 68.0711 21.7561Z" fill="#FAEE8D" />
            <path d="M52.5543 48.9726C52.5543 49.7971 51.8845 50.4661 51.059 50.4661H32.9328C32.1073 50.4661 31.4375 49.7971 31.4375 48.9726C31.4375 48.1482 32.1073 47.4792 32.9328 47.4792H51.059C51.8845 47.4792 52.5543 48.1482 52.5543 48.9726Z" fill="#CBCBCB" />
            <path d="M108.06 48.9726C108.06 49.7971 107.39 50.4661 106.564 50.4661H88.4382C87.6127 50.4661 86.9429 49.7971 86.9429 48.9726C86.9429 48.1482 87.6127 47.4792 88.4382 47.4792H106.564C107.39 47.4792 108.06 48.1482 108.06 48.9726Z" fill="#CBCBCB" />
            <path d="M138.771 86.8017C138.771 91.7483 134.755 95.7623 129.802 95.7623H9.19741C4.24466 95.7623 0.229004 91.7516 0.229004 86.8017V57.9332C0.229004 52.9833 4.24466 48.9726 9.19741 48.9726H122.075C127.028 48.9726 129.373 52.7265 131.044 57.9332L138.767 86.8017H138.771Z" fill="white" />
            <path d="M0.229004 82.1355V86.8017C0.229004 91.7482 4.24466 95.7622 9.19741 95.7622H129.802C134.755 95.7622 138.771 91.7516 138.771 86.8017L137.522 82.1355H0.229004Z" fill="#D9D9D9" />
            <path d="M130.712 56.9365C129.061 52.2535 126.71 48.9726 122.079 48.9726H9.19751C4.58306 48.9726 0.783926 52.4562 0.286621 56.9365H130.712Z" fill="#E6E6E6" />
            <path d="M0.997056 54.3246C0.635073 55.1389 0.388112 56.0174 0.286621 56.9364H130.712C130.384 56.0039 130.026 55.1288 129.626 54.3246H0.997056Z" fill="#E84E1B" />
            <path d="M41.279 59.7375H28.2036V95.7622H41.279V59.7375Z" fill="#ECECEC" />
            <path d="M27.2595 59.7375H14.1841V95.7622H27.2595V59.7375Z" fill="#ECECEC" />
            <path d="M39.5974 64.4036H29.8848V91.0928H39.5974V64.4036Z" fill="#61B9D9" />
            <path d="M25.5817 64.4036H15.8589V91.0928H25.5817V64.4036Z" fill="#61B9D9" />
            <path d="M133.788 80.828H137.171L131.044 57.9332H120.952C118.337 57.9332 116.818 60.5315 118.611 63.765C120.81 67.7317 123.496 72.658 124.552 74.9387C126.473 79.0845 131.541 80.828 133.784 80.828H133.788Z" fill="#007CA7" />
            <path d="M138.473 85.6799H133.291C132.326 85.6799 131.548 84.8994 131.548 83.9364C131.548 82.9735 132.33 82.193 133.291 82.193H137.54L138.473 85.6765V85.6799Z" fill="#F8B133" />
            <path d="M131.233 82.3518C131.233 82.7809 130.885 83.1289 130.455 83.1289C130.026 83.1289 129.677 82.7809 129.677 82.3518C129.677 81.9227 130.026 81.5747 130.455 81.5747C130.885 81.5747 131.233 81.9227 131.233 82.3518Z" fill="#E84E1B" />
            <path d="M115.908 59.7375H102.833V95.7622H115.908V59.7375Z" fill="#ECECEC" />
            <path d="M101.889 59.7375H88.8105V95.7622H101.889V59.7375Z" fill="#ECECEC" />
            <path d="M114.227 64.4036H104.514V91.0928H114.227V64.4036Z" fill="#61B9D9" />
            <path d="M100.211 64.4036H90.4883V91.0928H100.211V64.4036Z" fill="#61B9D9" />
            <path d="M83.1605 64.4036H47.835V80.828H83.1605V64.4036Z" fill="#007CA7" />
            <path d="M47.835 64.4036L83.1639 80.828H47.835V64.4036Z" fill="#096A8E" />
            <rect x="0.229004" y="95.7589" width="138.542" height="8.11253" fill="url(#paint1_linear_0_4)" />
            <defs>
                <linearGradient id="paint0_linear_0_1" x1="69.3732" y1="26.9169" x2="69.3732" y2="64.3014" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFE92E" />
                    <stop offset="1" stop-color="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint1_linear_0_4" x1="69.4998" y1="88.052" x2="69.4998" y2="99.8151" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#D9D9D9" />
                    <stop offset="1" stop-color="white" />
                </linearGradient>
            </defs>
        </svg>
    );
}