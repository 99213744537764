import "./LoyKratong.style.css";
import { motion } from "framer-motion";
import {
  CREATE_STATE,
  KRATONGS,
  USER_ICONS,
  CUSTOM_ICONS,
} from "../modal/configure/modalState";
import React, { useState } from "react";
import CreateModal from "../modal/CreateModal";

export default function LoyKratong({
  index,
  data,
  isExport,
  removeKratong,
  userId,
  isPicked,
  setWave,
}) {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(isExport ? true : false);

  const pickUpKratong = () => {
    setIsCreateModalOpen(true);
    removeKratong(data, setWave, index);
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
    setTimeout(() => {
      setIsOpen(false);
    }, 5000);
  }

  const isShow =
    !isExport &&
    isOpen &&
    !isPicked &&
    userId.findIndex((item) => item === data.id) === -1;
  return (
    <div>
      {data && (isExport ? true : data.status === "Active") && (
        <div className="kratong_group">
          {KRATONGS?.find((i) => i?.key === data.noTypeKrathong)?.kratong}
          {!isExport && (
            <motion.div
              layout
              data-isopen={isOpen}
              data-isexport={isExport}
              initial={{ borderRadius: 50 }}
              className="kratong-details"
              onClick={handleOpen}
              style={isOpen && !isShow ? { top: "-80px" } : {}}
            >
              <div className="detail-icon-name">
                <motion.div layout className="detail-icon" data-isopen={isOpen}>
                  {React.cloneElement(
                    USER_ICONS.find(
                      (i) =>
                        i?.key === "icon" + data?.avatar?.icon ||
                        i?.key === data?.avatar?.icon
                    )?.icon,
                    {
                      custom:
                        data?.avatar?.icon === undefined
                          ? CUSTOM_ICONS.icon1
                          : data?.avatar,
                    }
                  )}
                </motion.div>
                {isOpen && (
                  <div
                    className={`detail-name ${isOpen ? "visible" : "hidden"}`}
                  >
                    {data.name}
                  </div>
                )}
              </div>
              {isExport && <div className="detail-wish">{data?.wish}</div>}
              {isShow && (
                <div
                  className={`detail-pickup ${isOpen ? "visible" : "hidden"}`}
                  onClick={pickUpKratong}
                >
                  Click to pick this Krathong
                </div>
              )}
            </motion.div>
          )}
        </div>
      )}
      <CreateModal
        open={isCreateModalOpen}
        setOpen={setIsCreateModalOpen}
        type={CREATE_STATE[4]}
        kratongData={data}
      />
    </div>
  );
}
