import "./EmissionCounter.style.css";
import { animate } from "framer-motion";
import { useEffect, useRef } from "react";

export default function EmissionCounter({ emission }) {
    function Counter({ from, to }) {
        const nodeRef = useRef();

        useEffect(() => {
            const node = nodeRef.current;

            const controls = animate(from, to, {
                duration: 1,
                onUpdate(value) {
                    node.textContent = value?.toFixed(2)
                }
            });

            return () => controls?.stop();
        }, [from, to]);

        return <div ref={nodeRef} />;
    }
    return <div className="emission-container">
        <div className="co2-emission-summary-container">
            <div>Total carbon saved from floating Krathongs with Accenture: </div>
            <Counter from={emission?.from} to={emission?.to} />
            <div>&nbsp;kg</div>
        </div>
        <div className="co2-emission-summary-container">
            <div>Floating Krathongs in Accenture River: </div>
            <div>{emission?.response?.krathongActive}</div>
        </div>
        <div className="co2-emission-summary-container">
            <div>Krathongs picked up from Accenture River: </div>
            <div>{emission?.response?.krathongInactive}</div>
        </div>
        <div className="co2-emission-summary-container">
            <div>Equivalent to planting trees: </div>
            <div>{(emission?.to/25).toFixed(2)}</div>
        </div>
    </div>
}