import React from 'react';

export default function Kratong3() {
    return (
        <svg width="126" height="114" viewBox="0 0 126 114" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="56.0576" y="29.0002" width="6.79719" height="37.3845" fill="url(#paint0_linear_0_5)" />
            <path d="M56.0267 19.2293C53.6477 26.0265 57.018 27.4426 59.0005 27.301C65.3729 27.301 64.0984 20.9286 63.2488 19.2293C62.569 17.8699 60.1334 12.1489 59.0005 9.45834C59.0005 10.7328 59.0005 10.7328 56.0267 19.2293Z" fill="#FFE92E" />
            <path d="M56.0267 19.2293C53.6477 26.0265 57.018 27.4426 59.0005 27.301C65.3729 27.301 64.0984 20.9286 63.2488 19.2293C62.569 17.8699 60.1334 12.1489 59.0005 9.45834C59.0005 10.7328 59.0005 10.7328 56.0267 19.2293Z" fill="#FFE92E" />
            <path d="M56.0267 19.2293C53.6477 26.0265 57.018 27.4426 59.0005 27.301C65.3729 27.301 64.0984 20.9286 63.2488 19.2293C62.569 17.8699 60.1334 12.1489 59.0005 9.45834C59.0005 10.7328 59.0005 10.7328 56.0267 19.2293Z" fill="#FFE92E" />
            <path d="M57.4639 21.5318C55.8468 26.3844 58.1377 27.3954 59.4852 27.2943C63.8166 27.2943 62.9504 22.745 62.3728 21.5318C61.9108 20.5613 60.2553 16.477 59.4852 14.5562C59.4852 15.4661 59.4852 15.4661 57.4639 21.5318Z" fill="#FFA217" />
            <path d="M58.1546 23.8395C56.9996 26.751 58.6359 27.3576 59.5984 27.297C62.6923 27.297 62.0735 24.5674 61.661 23.8395C61.331 23.2572 60.1484 20.8066 59.5984 19.6541C59.5984 20.2 59.5984 20.2 58.1546 23.8395Z" fill="#FAEE8D" />
            <rect x="66.146" y="0.708344" width="2.1875" height="65.625" fill="#EBC2EE" />
            <rect x="69.7915" y="0.708344" width="2.1875" height="65.625" fill="#EBC2EE" />
            <g filter="url(#filter0_d_0_1)">
                <rect x="3.4375" y="94.0417" width="119.583" height="13.8542" rx="2.91667" fill="url(#paint1_linear_0_1)" />
            </g>
            <path d="M22.6034 53.7175C14.0236 53.1213 5.74181 59.4109 4.63797 66.4648C3.53117 73.5188 -7.02911 95.4687 15.8741 99.1876C38.7803 102.909 40.8995 99.8339 42.5906 95.9528C44.2818 92.0746 47.5904 65.8893 40.9113 60.4232C33.8573 54.6502 30.4867 54.2636 22.6034 53.7146V53.7175Z" fill="#F7A037" />
            <path d="M59.0217 56.439C50.4448 57.1208 42.9186 64.5909 42.5939 71.7482C42.2693 78.9054 34.1971 102.24 57.3305 102.523C80.464 102.809 82.2289 99.4445 83.4833 95.345C84.7347 91.2484 85.1479 64.7945 77.9228 60.3674C70.2933 55.6923 66.905 55.8103 59.0247 56.439H59.0217Z" fill="#F7A037" />
            <path d="M97.842 55.2759C89.2651 55.9577 81.7389 63.4278 81.4143 70.5851C81.0896 77.7424 73.0174 101.077 96.1508 101.36C119.284 101.646 121.049 98.2815 122.304 94.1819C123.558 90.0853 123.968 63.6315 116.743 59.2043C109.114 54.5292 105.725 54.6473 97.845 55.2759H97.842Z" fill="#F7A037" />
            <path d="M44.6834 75.5317C44.5801 68.9441 43.556 62.7667 40.6842 60.4144C33.6303 54.6414 30.2568 54.2547 22.3764 53.7058C13.7965 53.1096 5.51477 59.3991 4.41093 66.4531C4.31058 67.0935 4.13054 67.858 3.90918 68.7168C6.96393 67.8019 12.3533 72.6482 14.3662 73.1056C16.8247 73.6635 20.1835 74.1859 22.6951 73.9527C24.9353 73.7461 26.8065 72.1612 29.0762 71.8218C33.9254 71.0957 36.0121 74.1298 40.2297 75.9567C42.1954 76.8067 43.6475 76.5057 44.6834 75.5288V75.5317Z" fill="#F5892F" />
            <path d="M122.841 78.3857C122.321 70.1394 120.648 61.3323 116.746 58.9416C109.117 54.2665 105.728 54.3846 97.8479 55.0132C89.271 55.695 81.7448 63.1651 81.4201 70.3224C81.3434 72.0283 80.824 74.6551 80.396 77.6715L81.8806 79.2534C83.5777 77.9342 85.5168 77.2022 87.5769 77.3586C89.7432 77.521 90.9209 79.1738 93.0193 79.8467C95.2831 80.5727 98.4382 81.467 100.132 79.6076C102.364 77.1579 102.588 75.632 105.964 75.1421C111.395 74.354 117.944 83.8282 122.841 78.3857Z" fill="#F5892F" />
            <path d="M122.221 94.722C122.549 93.6477 122.82 91.0415 122.909 87.6975C122.448 87.9455 121.991 88.3498 121.524 88.9194C119.151 91.8266 119.703 95.5897 115.819 97.0536C112.646 98.249 108.709 97.5819 105.401 98.2519C101.95 98.9514 99.3502 100.079 95.6904 99.9165C93.8251 99.8339 89.755 99.7306 87.9901 99.1698C86.4789 98.6887 83.3652 95.817 83.3652 95.817C83.3652 95.817 82.6716 97.5972 78.4244 99.8462C75.7121 101.284 70.3286 102.399 67.445 102.792C63.9623 103.261 59.9188 102.933 56.4007 102.39C53.3047 101.915 47.5521 101.333 45.7047 99.7576C44.3204 98.2317 43.1771 96.5938 42.4479 94.7709C42.3771 91.8548 41.735 96.3158 39.6011 97.2425C33.3115 99.9815 24.7819 99.5801 18.0201 98.1427C14.7528 97.4491 10.9661 96.2213 7.88187 94.9729C5.7037 94.0904 4.41687 91.5521 2.38037 90.3007C3.9771 94.7751 7.60148 98.3936 15.7888 99.7247C34.3741 102.744 39.2735 101.289 41.366 98.5589C42.6234 104.565 46.6816 104.388 57.2448 104.518C77.4479 103.885 73.8021 103.885 83.3976 102.455C83.6367 101.676 83.8433 100.082 83.9732 97.9863C86.4642 100.318 90.2922 101.823 96.0623 101.894C119.196 102.18 120.961 98.8156 122.215 94.7161L122.221 94.722Z" fill="#DB772C" />
            <path d="M41.9477 85.8204C41.753 83.8459 41.5641 82.2285 41.7116 80.2068C41.8238 78.6573 41.7795 76.1249 42.5676 74.8676V74.9857C43.1195 74.4898 42.2518 74.0383 41.7559 75.1952C41.4696 75.8652 41.2866 76.6414 41.0682 77.3823C40.841 78.1496 40.3628 79.0498 40.3126 79.9235C40.2625 80.8118 40.5074 81.8065 40.5281 82.7391C40.5724 84.5897 40.6816 86.6026 40.8675 88.4679C41.08 90.59 41.4726 92.2192 40.3038 94.052C39.7401 94.9404 34.9794 98.9219 36.1216 100.091C37.0985 101.088 41.4903 98.553 41.7175 97.3783C42.4761 93.4677 42.3314 89.7134 41.9477 85.8234V85.8204Z" fill="#DB772C" />
            <path opacity="0.2" d="M6.62739 72.7455H6.52704C4.21311 71.5384 4.42856 76.8805 4.23967 78.2352C3.91796 80.5403 3.36014 82.928 3.55494 85.3364C3.72612 87.4378 5.25497 89.5304 6.71003 90.7021C7.29737 91.1744 8.74653 92.2015 9.49325 91.6584C10.1721 91.1626 10.1691 89.1231 10.1898 88.282C10.2547 85.9001 9.9094 83.2114 10.6266 80.9683C11.1844 79.2269 13.0173 78.6337 13.1235 76.9514C13.3951 72.7396 8.52517 73.8848 6.62739 72.7455Z" fill="#FFF3AE" />
            <path opacity="0.2" d="M48.3728 93.385C47.7795 91.5197 47.7795 84.9556 45.3711 85.4781L45.1704 85.4722C44.2142 85.7525 44.9402 89.9377 44.9107 90.95C44.8546 92.9364 44.4798 94.843 44.3411 96.7673C44.1965 98.795 44.4887 100.976 45.4479 102.679C46.3746 104.323 47.9478 105.474 49.1342 103.476C50.9996 100.333 49.3703 96.5224 48.3698 93.385H48.3728Z" fill="#FFF3AE" />
            <path opacity="0.2" d="M99.3383 88.2553C98.6683 86.0211 97.2782 84.6811 95.5663 83.3559C94.4123 82.4646 93.3321 81.2456 92.0364 80.6317C91.2926 80.2805 90.419 79.8201 89.5572 79.8408H89.6575C87.6417 79.8555 88.8105 83.7721 88.9079 84.9291C89.1056 87.2784 89.0791 89.5245 88.8134 91.8413C88.5773 93.8956 88.3353 95.9114 90.1563 97.1156C91.7619 98.1781 93.6361 97.7177 95.3568 97.6823C96.1153 97.6675 97.1306 97.9922 97.8596 97.7797C98.993 97.4461 99.8784 95.5631 100.171 94.353C100.684 92.2073 99.9433 90.2712 99.3353 88.2524L99.3383 88.2553Z" fill="#FFF3AE" />
            <path opacity="0.2" d="M29.1499 55.7658C27.3348 54.7742 24.6991 55.2671 22.7305 55.2907C20.6851 55.3143 18.7992 55.7806 16.8217 56.058C15.163 56.2912 10.5115 56.8372 8.25955 61.2998C7.15276 63.4927 7.35641 64.9744 9.37225 66.1608C11.2021 67.2381 13.2652 67.2706 15.101 68.4276C16.4793 69.2982 19.1563 69.555 20.086 67.6926C21.1397 65.5824 20.6025 62.7873 22.1904 60.8984C23.7251 59.0744 25.98 58.6229 28.0667 58.4871C28.7013 58.4458 30.3246 58.6878 30.5754 57.761C30.7909 56.9612 29.6605 56.0403 29.1528 55.7629L29.1499 55.7658Z" fill="#FFF3AE" />
            <path opacity="0.2" d="M102.169 56.2705C101.759 56.182 101.283 56.2558 100.971 56.0846L99.5539 56.5007C95.1355 56.8343 88.6955 59.0213 86.6708 64.207C85.9831 65.966 85.4961 68.2593 85.6171 70.2397C85.7529 72.474 88.114 73.2237 89.7167 73.9822C91.9155 75.024 94.3682 74.0855 95.531 71.6535C96.7146 69.1743 95.8055 66.2759 96.8356 63.8735C97.9837 61.1876 100.168 59.9569 102.491 59.1452C104.651 58.3897 104.276 56.7251 102.169 56.2705Z" fill="#FFF3AE" />
            <path d="M78.7785 61.1183C70.8126 56.4314 67.2767 56.5436 59.057 57.1575C50.1082 57.8275 43.0454 60.0942 41.9356 72.4401C41.9297 72.4903 41.9297 72.5404 41.9268 72.5936L41.9858 72.7352C42.3193 83.7884 61.8726 78.0095 67.2797 77.3159C71.332 76.7964 75.3224 78.2131 79.236 79.0189C80.939 79.3701 83.3857 80.2201 85.0858 79.5029C84.4571 71.5783 82.6774 63.4087 78.7815 61.1154L78.7785 61.1183Z" fill="#F5892F" />
            <path d="M85.2454 86.6911C85.3811 84.6841 85.7855 83.0667 85.4018 81.0922C85.0329 79.1885 84.8528 77.6951 84.1268 75.9065C83.7313 74.9384 83.3535 71.7361 82.3943 71.5384C82.3884 71.6564 82.3677 72.0313 82.3647 71.9899L82.5655 71.9958C82.347 73.6221 83.7342 75.4402 83.8228 77.0635C83.9261 79.0085 84.2153 80.8797 84.1592 82.7981C84.0412 86.7413 83.5335 90.525 83.4214 94.4505C83.3771 95.9704 83.3063 97.1805 84.7112 97.7531C84.8853 96.8824 84.5813 95.9173 84.5045 95.0762C84.416 94.0815 84.4632 93.0633 84.4927 92.0391C84.5459 90.0941 85.1244 88.5239 85.2483 86.694L85.2454 86.6911Z" fill="#DB772C" />
            <path opacity="0.2" d="M66.4209 59.4503C64.718 57.7207 61.3769 58.3494 59.4142 58.8393C57.4987 59.3175 55.3707 59.4355 53.3785 59.6776L53.0715 59.8989C51.3508 59.7366 49.2317 61.3805 48.0777 62.75C46.6905 64.3999 46.233 66.4069 45.5247 68.4994C44.8665 70.4415 45.1676 73.1981 47.1273 73.9035C49.1048 74.6148 51.3597 74.4377 53.4169 74.7417C58.2572 75.4589 56.1853 67.7527 58.2661 64.7629C59.3552 63.1957 61.5127 62.4903 63.136 61.9856C64.1011 61.6875 68.2774 61.3363 66.418 59.4473L66.4209 59.4503Z" fill="#FFF3AE" />
            <path d="M18.0317 58.1389C18.2737 58.1477 18.2855 57.7109 18.0435 57.702C17.8015 57.6932 17.7897 58.13 18.0317 58.1389Z" fill="#6D3A17" />
            <path d="M18.0823 58.1329C18.5929 58.0001 19.0976 57.8378 19.5994 57.6636C19.8296 57.5839 19.744 57.156 19.5108 57.2386C19.0091 57.4128 18.5044 57.578 17.9938 57.7079C17.7577 57.7699 17.8433 58.1949 18.0823 58.1329Z" fill="#6D3A17" />
            <path d="M17.4858 56.1909C18.0879 56.2115 18.687 56.2351 19.2891 56.2558C19.5311 56.2646 19.5429 55.8278 19.3009 55.819C18.6988 55.7983 18.0997 55.7747 17.4976 55.754C17.2556 55.7452 17.2438 56.182 17.4858 56.1909Z" fill="#6D3A17" />
            <path d="M20.3163 57.1707C20.9686 57.1943 21.6179 57.2179 22.2702 57.2415C22.5122 57.2504 22.524 56.8136 22.282 56.8047C21.6297 56.7811 20.9804 56.7575 20.3282 56.7339C20.0861 56.725 20.0743 57.1618 20.3163 57.1707Z" fill="#6D3A17" />
            <path d="M21.1073 59.095C21.5648 58.7792 22.0783 58.6435 22.586 58.4693C22.8192 58.3896 22.7306 57.9646 22.4974 58.0443C21.9632 58.2273 21.4143 58.3749 20.9302 58.7114C20.7207 58.856 20.9007 59.2397 21.1103 59.095H21.1073Z" fill="#6D3A17" />
            <path d="M23.7519 57.9942C23.9939 58.0031 24.0057 57.5662 23.7637 57.5574C23.5217 57.5485 23.5099 57.9853 23.7519 57.9942Z" fill="#6D3A17" />
            <path d="M23.8019 57.9883C24.221 57.8555 24.6784 57.8525 25.1094 57.8673C25.3514 57.8762 25.3632 57.4393 25.1212 57.4305C24.6578 57.4157 24.1619 57.4187 23.7133 57.5633C23.4802 57.6371 23.5657 58.065 23.8019 57.9883Z" fill="#6D3A17" />
            <path d="M21.7536 56.1407C21.9897 56.2824 22.2523 56.365 22.5032 56.4565C22.636 56.5067 22.7718 56.5539 22.9046 56.6041C22.9548 56.6218 23.005 56.6424 23.0551 56.6631C23.1496 56.7015 23.126 56.731 23.0669 56.6247C23.0669 56.5509 23.0699 56.4772 23.0728 56.4034C23.2263 56.3384 23.2676 56.306 23.1939 56.3089C22.9518 56.3089 22.9371 56.7457 23.1821 56.7457C23.2617 56.7457 23.3473 56.7251 23.3916 56.6336C23.4211 56.5687 23.4359 56.4801 23.3975 56.4122C23.2913 56.2233 22.9961 56.1761 22.8249 56.1112C22.5357 56.0049 22.2228 55.9252 21.9513 55.7659C21.7358 55.6389 21.5351 56.0079 21.7506 56.1377L21.7536 56.1407Z" fill="#6D3A17" />
            <path d="M23.3828 55.1785C23.6248 55.1874 23.6366 54.7506 23.3946 54.7417C23.1525 54.7329 23.1407 55.1697 23.3828 55.1785Z" fill="#6D3A17" />
            <path d="M23.333 55.1697C23.8023 55.3822 24.2096 55.7334 24.6169 56.0699C24.8147 56.2351 25.0124 55.8633 24.8176 55.698C24.3837 55.3379 23.9469 54.9778 23.4452 54.7506C23.2179 54.6473 23.1087 55.0664 23.333 55.1697Z" fill="#6D3A17" />
            <path d="M24.8879 56.8018C25.3896 56.6306 25.9032 56.5037 26.4049 56.3325C26.6381 56.2528 26.5496 55.8278 26.3164 55.9075C25.8147 56.0787 25.3011 56.2056 24.7994 56.3768C24.5662 56.4565 24.6547 56.8815 24.8879 56.8018Z" fill="#6D3A17" />
            <path d="M27.7304 55.6861C28.2351 55.7157 28.7221 55.8898 29.2268 55.9164C29.4688 55.9282 29.4806 55.4913 29.2386 55.4795C28.7339 55.453 28.2469 55.2788 27.7422 55.2493C27.5002 55.2346 27.4884 55.6714 27.7304 55.6861Z" fill="#6D3A17" />
            <path d="M28.12 57.8023C28.7074 57.8319 29.4305 57.885 29.9706 58.189C30.186 58.31 30.3897 57.9411 30.1713 57.8171C29.5721 57.4777 28.793 57.395 28.1348 57.3626C27.8928 57.3508 27.881 57.7876 28.123 57.7994L28.12 57.8023Z" fill="#6D3A17" />
            <path d="M26.5556 59.8477C27.2286 59.8595 27.8572 59.6794 28.4682 59.3607C28.6895 59.2456 28.5095 58.8619 28.2881 58.977C27.7362 59.2633 27.1695 59.4197 26.5645 59.4108C26.3225 59.4079 26.3107 59.8447 26.5527 59.8477H26.5556Z" fill="#6D3A17" />
            <path d="M26.1013 58.0503C26.4791 57.8555 26.8805 57.7256 27.2731 57.5898C27.5033 57.5102 27.4177 57.0822 27.1845 57.1648C26.7595 57.3124 26.3286 57.4541 25.9213 57.6666C25.7 57.7817 25.88 58.1654 26.1013 58.0503Z" fill="#6D3A17" />
            <path d="M28.9018 56.7812C29.4035 56.7989 29.9023 56.8166 30.4041 56.8343C30.6461 56.8432 30.6579 56.4064 30.4159 56.3975C29.9141 56.3798 29.4153 56.3621 28.9136 56.3444C28.6716 56.3355 28.6598 56.7723 28.9018 56.7812Z" fill="#6D3A17" />
            <path d="M32.2104 56.7251C32.4524 56.7339 32.4642 56.2971 32.2222 56.2883C31.9802 56.2794 31.9684 56.7162 32.2104 56.7251Z" fill="#6D3A17" />
            <path d="M32.1634 56.7162C32.6091 56.9081 33.0548 57.0999 33.5004 57.2888C33.7277 57.3862 33.8398 56.9671 33.6126 56.8697C33.1669 56.6779 32.7212 56.486 32.2756 56.2971C32.0483 56.1997 31.9362 56.6188 32.1634 56.7162Z" fill="#6D3A17" />
            <path d="M31.6913 59.16C32.0426 59.1718 32.3938 59.1865 32.7421 59.1983C32.9841 59.2072 32.9959 58.7704 32.7539 58.7615C32.4026 58.7497 32.0514 58.735 31.7032 58.7232C31.4611 58.7143 31.4493 59.1511 31.6913 59.16Z" fill="#6D3A17" />
            <path d="M32.7685 58.3217C33.0105 58.3306 33.0223 57.8938 32.7803 57.8849C32.5383 57.8761 32.5265 58.3129 32.7685 58.3217Z" fill="#6D3A17" />
            <path d="M32.6739 58.2894C33.0281 58.496 33.4236 58.5225 33.8161 58.5373C34.0582 58.5461 34.07 58.1093 33.828 58.1005C33.5121 58.0887 33.1639 58.0887 32.8746 57.9204C32.6592 57.7965 32.4585 58.1654 32.6739 58.2923V58.2894Z" fill="#6D3A17" />
            <path d="M36.2128 58.797C36.4548 58.8058 36.4667 58.369 36.2246 58.3602C35.9826 58.3513 35.9708 58.7881 36.2128 58.797Z" fill="#6D3A17" />
            <path d="M36.1155 58.9386C36.4313 59.1482 36.6822 59.464 36.998 59.6706C37.2076 59.8093 37.4083 59.4404 37.1987 59.2987C36.8829 59.0892 36.632 58.7734 36.3162 58.5668C36.1067 58.428 35.906 58.797 36.1155 58.9386Z" fill="#6D3A17" />
            <path d="M34.8051 60.6741C34.8553 60.6741 34.9055 60.6771 34.9556 60.68C35.1977 60.6889 35.2095 60.252 34.9674 60.2432C34.9173 60.2432 34.8671 60.2402 34.8169 60.2373C34.5749 60.2284 34.5631 60.6652 34.8051 60.6741Z" fill="#6D3A17" />
            <path d="M34.8051 60.6741C35.0944 60.6978 35.3511 60.866 35.602 61.0224C35.8145 61.1552 36.0152 60.7863 35.8027 60.6505C35.4898 60.4557 35.177 60.2609 34.8169 60.2344C34.5749 60.2167 34.5631 60.6535 34.8051 60.6712V60.6741Z" fill="#6D3A17" />
            <path d="M31.018 61.5862C31.0682 61.5862 31.1184 61.5891 31.1685 61.5921C31.4105 61.6009 31.4224 61.1641 31.1803 61.1553C31.1302 61.1553 31.08 61.1523 31.0298 61.1494C30.7878 61.1405 30.776 61.5773 31.018 61.5862Z" fill="#6D3A17" />
            <path d="M31.1689 61.592C31.4699 61.6038 31.771 61.6126 32.0691 61.6244C32.3111 61.6333 32.3229 61.1965 32.0809 61.1876C31.7798 61.1758 31.4788 61.167 31.1807 61.1552C30.9387 61.1463 30.9269 61.5831 31.1689 61.592Z" fill="#6D3A17" />
            <path d="M28.0434 60.4291C28.2854 60.438 28.2972 60.0012 28.0552 59.9923C27.8132 59.9835 27.8014 60.4203 28.0434 60.4291Z" fill="#6D3A17" />
            <path d="M28.0434 60.4291C28.1437 60.4321 28.2441 60.435 28.3444 60.4409C28.5865 60.4498 28.5983 60.013 28.3562 60.0041C28.2559 60.0012 28.1556 59.9982 28.0552 59.9923C27.8132 59.9835 27.8014 60.4203 28.0434 60.4291Z" fill="#6D3A17" />
            <path d="M12.6069 58.4665C12.9669 58.4665 13.2916 58.2953 13.6133 58.1241C13.8347 58.006 13.6546 57.6223 13.4333 57.7404C13.1735 57.8791 12.9079 58.0296 12.6187 58.0326C12.3767 58.0326 12.3649 58.4724 12.6069 58.4694V58.4665Z" fill="#6D3A17" />
            <path d="M13.5634 56.5745C14.1891 56.5981 14.806 56.6011 15.4228 56.4594C15.6619 56.4033 15.5734 55.9783 15.3343 56.0344C14.7499 56.1702 14.1685 56.1584 13.5752 56.1377C13.3332 56.1289 13.3214 56.5657 13.5634 56.5745Z" fill="#6D3A17" />
            <path d="M15.331 57.8643C15.6822 57.8762 16.0334 57.8909 16.3817 57.9027C16.6237 57.9116 16.6355 57.4748 16.3935 57.4659C16.0423 57.4541 15.6911 57.4393 15.3428 57.4275C15.1008 57.4187 15.089 57.8555 15.331 57.8643Z" fill="#6D3A17" />
            <path d="M14.7997 60.6475C15.2749 60.6652 15.7442 60.6652 16.2076 60.5147C16.4407 60.4409 16.3552 60.0129 16.119 60.0897C15.6881 60.2284 15.2543 60.2254 14.8115 60.2107C14.5695 60.2018 14.5577 60.6386 14.7997 60.6475Z" fill="#6D3A17" />
            <path d="M19.1269 61.8547C19.5637 61.8695 20.0153 61.896 20.4285 61.6983C20.6528 61.5891 20.4728 61.2054 20.2485 61.3146C19.9061 61.4799 19.4988 61.4326 19.1358 61.4208C18.8937 61.412 18.8819 61.8488 19.124 61.8576L19.1269 61.8547Z" fill="#6D3A17" />
            <path d="M18.1704 59.1305C18.4331 59.4256 18.8581 59.3961 19.1975 59.4079C19.4395 59.4167 19.4513 58.9799 19.2093 58.9711C18.9998 58.9622 18.6102 59.0183 18.4449 58.8324C18.2708 58.6376 17.9963 58.9357 18.1704 59.1334V59.1305Z" fill="#6D3A17" />
            <path d="M22.9047 61.2939C23.2117 61.3175 23.4921 61.4769 23.802 61.5005C24.044 61.5182 24.0558 61.0814 23.8138 61.0637C23.5068 61.0401 23.2235 60.8807 22.9165 60.8571C22.6745 60.8394 22.6627 61.2762 22.9047 61.2939Z" fill="#6D3A17" />
            <path d="M26.1542 63.3363C26.4051 63.3452 26.656 63.354 26.9069 63.3629C27.1489 63.3717 27.1607 62.9349 26.9187 62.926C26.6678 62.9172 26.4169 62.9083 26.166 62.8995C25.924 62.8906 25.9122 63.3274 26.1542 63.3363Z" fill="#6D3A17" />
            <path d="M25.3076 61.3795C25.5496 61.3884 25.5614 60.9516 25.3194 60.9427C25.0773 60.9338 25.0655 61.3707 25.3076 61.3795Z" fill="#6D3A17" />
            <path d="M25.3076 61.3795C25.3577 61.3795 25.4079 61.3825 25.4581 61.3854C25.7001 61.3943 25.7119 60.9575 25.4699 60.9486C25.4197 60.9486 25.3695 60.9457 25.3194 60.9427C25.0773 60.9338 25.0655 61.3707 25.3076 61.3795Z" fill="#6D3A17" />
            <path d="M25.7587 61.3972C26.107 61.409 26.4346 61.3913 26.7622 61.229C26.9865 61.1168 26.8065 60.7331 26.5822 60.8453C26.3225 60.9752 26.048 60.9722 25.7705 60.9604C25.5285 60.9515 25.5167 61.3884 25.7587 61.3972Z" fill="#6D3A17" />
            <path d="M30.9741 63.1622C31.2161 63.1711 31.2279 62.7342 30.9859 62.7254C30.7438 62.7165 30.732 63.1533 30.9741 63.1622Z" fill="#6D3A17" />
            <path d="M30.9741 63.1622C31.3636 63.1769 31.7591 63.1769 32.1281 62.9998C32.3524 62.8906 32.1723 62.507 31.948 62.6162C31.6499 62.7637 31.3046 62.7372 30.9859 62.7254C30.7438 62.7165 30.732 63.1533 30.9741 63.1622Z" fill="#6D3A17" />
            <path d="M49.0193 65.1944C49.1993 64.8344 49.5623 64.6012 49.8752 64.4271C50.0936 64.3061 49.9165 63.9224 49.6951 64.0434C49.3233 64.247 48.913 64.5363 48.6975 64.9613C48.5736 65.2063 48.8923 65.4365 49.0163 65.1944H49.0193Z" fill="#6D3A17" />
            <path d="M71.6918 60.8706C72.0844 60.8853 72.6009 60.8617 72.9019 61.2011C73.0761 61.3959 73.3505 61.0978 73.1764 60.9001C72.7898 60.4662 72.2113 60.4515 71.7036 60.4308C71.4616 60.4219 71.4498 60.8588 71.6918 60.8676V60.8706Z" fill="#6D3A17" />
            <path d="M67.1706 59.4716C67.7609 59.7165 68.3866 59.8641 69.0123 59.8966C69.2543 59.9084 69.2661 59.4716 69.0241 59.4598C68.4309 59.4273 67.8406 59.2856 67.2798 59.0554C67.0496 58.961 66.9404 59.3801 67.1676 59.4745L67.1706 59.4716Z" fill="#6D3A17" />
            <path d="M67.7934 60.5518C68.0354 60.5607 68.0472 60.1239 67.8052 60.115C67.5632 60.1061 67.5514 60.543 67.7934 60.5518Z" fill="#6D3A17" />
            <path d="M67.7934 60.5518C68.1446 60.5636 68.4958 60.5784 68.8441 60.5902C69.0861 60.599 69.0979 60.1622 68.8559 60.1534C68.5047 60.1416 68.1535 60.1268 67.8052 60.115C67.5632 60.1061 67.5514 60.543 67.7934 60.5518Z" fill="#6D3A17" />
            <path d="M67.7817 62.6474C68.2982 62.4555 68.7733 62.1486 69.3046 62.001C69.5407 61.9361 69.4522 61.5111 69.2161 61.576C68.6848 61.7206 68.2096 62.0305 67.6931 62.2224C67.4629 62.308 67.5485 62.733 67.7817 62.6474Z" fill="#6D3A17" />
            <path d="M65.4115 63.0871C65.863 63.2819 66.3176 63.4649 66.8046 63.4944C67.0466 63.5091 67.0584 63.0723 66.8164 63.0576C66.3648 63.0281 65.9457 62.848 65.5236 62.668C65.2964 62.5706 65.1842 62.9897 65.4115 63.0871Z" fill="#6D3A17" />
            <path d="M69.3486 63.9372C69.8267 63.9549 70.3107 63.9549 70.6944 63.5712C70.8774 63.3882 70.6206 63.0694 70.4377 63.2524C70.1396 63.5505 69.7264 63.5122 69.3604 63.5004C69.1184 63.4915 69.1066 63.9283 69.3486 63.9372Z" fill="#6D3A17" />
            <path d="M70.6 62.2312C70.9513 62.243 71.3025 62.2578 71.6508 62.2696C71.8928 62.2785 71.9046 61.8416 71.6626 61.8328C71.3113 61.821 70.9601 61.8062 70.6118 61.7944C70.3698 61.7856 70.358 62.2224 70.6 62.2312Z" fill="#6D3A17" />
            <path d="M69.7558 60.2743C69.9978 60.2832 70.0096 59.8464 69.7676 59.8375C69.5256 59.8287 69.5138 60.2655 69.7558 60.2743Z" fill="#6D3A17" />
            <path d="M69.6618 60.242C70.0218 60.4545 70.4055 60.4751 70.801 60.4899C71.043 60.4987 71.0548 60.0619 70.8128 60.0531C70.4911 60.0413 70.1546 60.0472 69.8595 59.873C69.644 59.7461 69.4433 60.1151 69.6588 60.2449L69.6618 60.242Z" fill="#6D3A17" />
            <path d="M73.0853 63.0133C73.5782 63.2199 74.0799 63.3852 74.5728 63.5918C74.8001 63.6892 74.9122 63.2701 74.685 63.1727C74.1921 62.9661 73.6903 62.8008 73.1975 62.5942C72.9702 62.4968 72.858 62.9159 73.0853 63.0133Z" fill="#6D3A17" />
            <path d="M71.8666 65.2564C72.2651 65.2712 72.6369 65.3095 73.0147 65.466C73.2449 65.5604 73.3541 65.1413 73.1269 65.0469C72.7196 64.8786 72.3093 64.8343 71.8814 64.8196C71.6394 64.8107 71.6275 65.2475 71.8696 65.2564H71.8666Z" fill="#6D3A17" />
            <path d="M76.6005 62.8008C76.8248 62.8097 77.0698 62.8008 77.182 63.0635C77.2912 63.3144 77.6217 63.1048 77.5125 62.854C77.3325 62.4408 76.9754 62.3758 76.6123 62.3611C76.3703 62.3522 76.3585 62.789 76.6005 62.7979V62.8008Z" fill="#6D3A17" />
            <path d="M76.9725 65.1561C77.1436 65.5634 77.4831 65.7995 77.8667 65.8231C78.1088 65.8379 78.1206 65.401 77.8786 65.3863C77.6218 65.3715 77.4152 65.218 77.3001 64.9495C77.1938 64.6956 76.8633 64.9052 76.9695 65.159L76.9725 65.1561Z" fill="#6D3A17" />
            <path d="M58.6586 64.25C59.2283 64.2707 59.8126 64.3031 60.3675 64.129C60.6007 64.0552 60.5151 63.6302 60.279 63.704C59.7566 63.8693 59.2076 63.8309 58.6704 63.8132C58.4284 63.8044 58.4166 64.2412 58.6586 64.25Z" fill="#6D3A17" />
            <path d="M58.9068 62.674C59.3997 62.5146 59.9192 62.4998 60.4209 62.3788C60.6571 62.3227 60.5715 61.8977 60.3324 61.9538C59.8306 62.0748 59.3141 62.0896 58.8183 62.249C58.5851 62.3257 58.6707 62.7507 58.9068 62.674Z" fill="#6D3A17" />
            <path d="M62.0558 62.9632C62.4867 62.8245 62.9205 62.8275 63.3633 62.8422C63.6053 62.8511 63.6171 62.4143 63.3751 62.4054C62.8999 62.3877 62.4306 62.3877 61.9672 62.5382C61.7341 62.612 61.8197 63.04 62.0558 62.9632Z" fill="#6D3A17" />
            <path d="M64.0688 64.4477C64.5204 64.4654 64.969 64.4801 65.4205 64.4978C65.6626 64.5067 65.6744 64.0699 65.4324 64.061C64.9808 64.0433 64.5322 64.0286 64.0806 64.0108C63.8386 64.002 63.8268 64.4388 64.0688 64.4477Z" fill="#6D3A17" />
            <path d="M62.7963 61.5966C63.2449 61.6114 63.6434 61.5878 63.9917 61.2247C64.1747 61.0358 63.9149 60.7171 63.7349 60.906C63.4663 61.1864 63.1475 61.1716 62.8081 61.1598C62.5661 61.1509 62.5543 61.5878 62.7963 61.5966Z" fill="#6D3A17" />
            <path d="M61.3354 60.1446C61.3856 60.1446 61.4357 60.1475 61.4859 60.1505C61.7279 60.1593 61.7397 59.7225 61.4977 59.7137C61.4475 59.7137 61.3974 59.7107 61.3472 59.7078C61.1052 59.6989 61.0934 60.1357 61.3354 60.1446Z" fill="#6D3A17" />
            <path d="M61.4863 60.1475C61.7873 60.1593 62.0884 60.1682 62.3865 60.18C62.6285 60.1888 62.6403 59.752 62.3983 59.7432C62.0972 59.7314 61.7962 59.7225 61.4981 59.7107C61.2561 59.7018 61.2442 60.1387 61.4863 60.1475Z" fill="#6D3A17" />
            <path d="M64.3608 59.5513C65.1488 59.5808 65.9398 59.6074 66.7101 59.8052C66.9462 59.8642 67.0584 59.448 66.8223 59.386C66.0165 59.1824 65.196 59.144 64.3726 59.1145C64.1306 59.1057 64.1188 59.5425 64.3608 59.5513Z" fill="#6D3A17" />
            <path d="M65.9692 61.1864C66.4119 61.2041 66.8457 61.2307 67.2678 61.4019C67.498 61.4934 67.6072 61.0743 67.3799 60.9828C66.9284 60.7998 66.4591 60.7673 65.9839 60.7496C65.7419 60.7408 65.7301 61.1776 65.9721 61.1864H65.9692Z" fill="#6D3A17" />
            <path d="M68.5253 66.3603C68.9031 66.3544 69.3016 66.0976 69.576 65.8084C69.7561 65.6195 69.4993 65.3007 69.3193 65.4896C69.1215 65.6992 68.8116 65.9205 68.5371 65.9235C68.2951 65.9235 68.2833 66.3633 68.5253 66.3603Z" fill="#6D3A17" />
            <path d="M56.5161 65.5723C56.9676 65.6018 57.3867 65.7818 57.8088 65.9619C58.036 66.0593 58.1482 65.6402 57.9209 65.5428C57.4694 65.348 57.0148 65.165 56.5279 65.1355C56.2858 65.1207 56.274 65.5575 56.5161 65.5723Z" fill="#6D3A17" />
            <path d="M51.8234 66.6289C52.151 66.6289 52.3959 66.437 52.6793 66.2806C52.8977 66.1596 52.7206 65.7759 52.4992 65.8969C52.2838 66.015 52.0801 66.195 51.8322 66.192C51.5902 66.192 51.5754 66.6289 51.8204 66.6289H51.8234Z" fill="#6D3A17" />
            <path d="M55.9228 60.1209C56.6754 60.1475 57.4251 60.174 58.1777 60.2035C58.4197 60.2124 58.4315 59.7756 58.1895 59.7667C57.4369 59.7402 56.6872 59.7136 55.9346 59.6841C55.6926 59.6752 55.6808 60.1121 55.9228 60.1209Z" fill="#6D3A17" />
            <path d="M59.2221 60.4161C59.4641 60.4249 59.4759 59.9881 59.2339 59.9793C58.9919 59.9704 58.9801 60.4072 59.2221 60.4161Z" fill="#6D3A17" />
            <path d="M59.2221 60.4161C59.6235 60.4308 60.0249 60.4456 60.4233 60.4603C60.6654 60.4692 60.6772 60.0324 60.4351 60.0235C60.0338 60.0088 59.6324 59.994 59.2339 59.9793C58.9919 59.9704 58.9801 60.4072 59.2221 60.4161Z" fill="#6D3A17" />
            <path d="M57.7732 61.7353C58.0713 61.5789 58.4107 61.4491 58.7383 61.4491C58.9804 61.4491 58.9922 61.0122 58.7502 61.0122C58.3517 61.0122 57.9533 61.1628 57.5932 61.3517C57.3718 61.4668 57.5519 61.8504 57.7732 61.7353Z" fill="#6D3A17" />
            <path d="M56.4716 61.3045C56.5365 61.2396 56.6103 61.1982 56.6871 61.1569C56.7756 61.1097 56.8582 61.0477 56.932 60.971C57.115 60.7821 56.8553 60.4633 56.6752 60.6522C56.6103 60.7172 56.5365 60.7585 56.4598 60.7998C56.3712 60.847 56.2886 60.909 56.2148 60.9857C56.0318 61.1746 56.2916 61.4934 56.4716 61.3045Z" fill="#6D3A17" />
            <path d="M52.9448 62.2607C53.3373 62.0453 53.7062 61.7796 54.1224 61.6262C54.3526 61.5406 54.267 61.1155 54.0339 61.2011C53.5852 61.3664 53.1868 61.6439 52.7647 61.877C52.5463 61.998 52.7234 62.3817 52.9448 62.2607Z" fill="#6D3A17" />
            <path d="M54.7833 63.0576C55.2378 63.0635 55.6864 62.9278 56.141 62.9307C56.383 62.9307 56.3948 62.4969 56.1528 62.4939C55.6982 62.488 55.2496 62.6238 54.7951 62.6208C54.5531 62.6208 54.5383 63.0547 54.7833 63.0576Z" fill="#6D3A17" />
            <path d="M89.1498 59.1423C89.3919 59.1512 89.4037 58.7144 89.1616 58.7055C88.9196 58.6967 88.9078 59.1335 89.1498 59.1423Z" fill="#6D3A17" />
            <path d="M89.2476 59.1157C89.7966 58.735 90.4105 58.49 91.0303 58.3277C91.2664 58.2657 91.1808 57.8407 90.9418 57.9027C90.2924 58.0739 89.6402 58.3336 89.0676 58.7321C88.861 58.8767 89.0381 59.2633 89.2476 59.1157Z" fill="#6D3A17" />
            <path d="M91.4135 58.8737C92.0038 58.8944 92.5882 58.9268 93.1608 59.1039C93.3939 59.1748 93.5061 58.7586 93.2729 58.6848C92.6679 58.4989 92.051 58.4576 91.4253 58.4339C91.1833 58.4251 91.1715 58.8619 91.4135 58.8708V58.8737Z" fill="#6D3A17" />
            <path d="M90.7792 60.0779C91.1777 60.0779 91.5761 59.9274 91.9362 59.7385C92.1576 59.6234 91.9775 59.2397 91.7562 59.3548C91.4581 59.5112 91.1187 59.6411 90.791 59.6411C90.549 59.6411 90.5372 60.0779 90.7792 60.0779Z" fill="#6D3A17" />
            <path d="M89.4307 60.2698C89.593 59.9363 89.8409 59.6647 90.139 59.4995C90.3574 59.3755 90.1774 58.9918 89.959 59.1158C89.6078 59.3135 89.3038 59.6441 89.1119 60.0396C88.9939 60.2845 89.3097 60.5177 89.4307 60.2727V60.2698Z" fill="#6D3A17" />
            <path d="M90.8235 61.8016C91.2013 61.6068 91.6027 61.4769 91.9952 61.3412C92.2255 61.2615 92.1399 60.8335 91.9067 60.9161C91.4817 61.0637 91.0508 61.2054 90.6435 61.4179C90.4221 61.533 90.6022 61.9167 90.8235 61.8016Z" fill="#6D3A17" />
            <path d="M93.3114 60.8719C93.7099 60.8866 94.0818 60.925 94.4596 61.0814C94.6898 61.1759 94.799 60.7568 94.5717 60.6623C94.1644 60.4941 93.7542 60.4498 93.3262 60.4351C93.0842 60.4262 93.0724 60.863 93.3144 60.8719H93.3114Z" fill="#6D3A17" />
            <path d="M93.5597 59.4493C93.9139 59.6559 94.3093 59.6825 94.7019 59.6972C94.9439 59.7061 94.9557 59.2693 94.7137 59.2604C94.3979 59.2486 94.0496 59.2486 93.7604 59.0804C93.5449 58.9564 93.3442 59.3254 93.5597 59.4523V59.4493Z" fill="#6D3A17" />
            <path d="M92.9487 57.879C93.4239 57.8967 93.8931 57.8967 94.3565 57.7462C94.5897 57.6724 94.5041 57.2445 94.268 57.3212C93.8371 57.4599 93.4032 57.457 92.9605 57.4422C92.7185 57.4334 92.7066 57.8702 92.9487 57.879Z" fill="#6D3A17" />
            <path d="M95.4425 56.5598C95.9649 56.3945 96.5139 56.4329 97.051 56.4506C97.293 56.4594 97.3049 56.0226 97.0628 56.0138C96.4932 55.9931 95.9088 55.9606 95.3539 56.1348C95.1208 56.2086 95.2064 56.6336 95.4425 56.5598Z" fill="#6D3A17" />
            <path d="M98.999 56.6985C99.2321 56.7073 99.604 56.6867 99.7309 56.9671C99.8431 57.2179 100.174 57.0084 100.061 56.7575C99.8608 56.3118 99.3945 56.2735 99.0108 56.2617C98.7687 56.2528 98.7569 56.6896 98.999 56.6985Z" fill="#6D3A17" />
            <path d="M97.0097 57.8525C97.3108 57.8643 97.6118 57.8732 97.9099 57.885C98.1519 57.8938 98.1637 57.457 97.9217 57.4482C97.6207 57.4364 97.3196 57.4275 97.0215 57.4157C96.7795 57.4068 96.7677 57.8437 97.0097 57.8525Z" fill="#6D3A17" />
            <path d="M95.4216 59.0125C95.9204 59.2132 96.4457 59.2338 96.9681 59.2515C97.2102 59.2604 97.222 58.8236 96.9799 58.8147C96.49 58.797 96.0001 58.7852 95.5308 58.5934C95.3006 58.5019 95.1914 58.918 95.4186 59.0125H95.4216Z" fill="#6D3A17" />
            <path d="M98.0808 60.4881C98.4202 60.3229 98.801 60.3435 99.1433 60.1753C99.3677 60.0661 99.1876 59.6824 98.9633 59.7916C98.6239 59.9569 98.2432 59.9362 97.9008 60.1045C97.6765 60.2137 97.8565 60.5973 98.0808 60.4881Z" fill="#6D3A17" />
            <path d="M101.466 59.765C101.791 59.765 102.116 59.6351 102.364 59.3843C102.547 59.1954 102.29 58.8796 102.107 59.0655C101.936 59.2396 101.705 59.3282 101.481 59.3282C101.239 59.3282 101.224 59.765 101.469 59.765H101.466Z" fill="#6D3A17" />
            <path d="M101.186 58.4635C101.316 58.3307 101.499 58.3631 101.658 58.369C101.9 58.3779 101.912 57.9411 101.67 57.9322C101.398 57.9234 101.144 57.9234 100.929 58.1447C100.749 58.3336 101.006 58.6524 101.186 58.4635Z" fill="#6D3A17" />
            <path d="M101.584 55.7393C101.944 55.754 102.355 55.7334 102.688 55.9252C102.904 56.0492 103.107 55.6773 102.889 55.5533C102.488 55.3231 102.036 55.3172 101.599 55.3025C101.357 55.2936 101.345 55.7304 101.587 55.7393H101.584Z" fill="#6D3A17" />
            <path d="M104.899 55.5121C105.389 55.5416 105.905 55.7924 106.251 56.1968C106.422 56.3975 106.696 56.0964 106.525 55.8957C106.109 55.4088 105.501 55.1077 104.911 55.0723C104.669 55.0575 104.657 55.4944 104.899 55.5091V55.5121Z" fill="#6D3A17" />
            <path d="M104.654 57.0704C105.106 57.2652 105.56 57.4482 106.047 57.4777C106.289 57.4925 106.301 57.0556 106.059 57.0409C105.607 57.0114 105.188 56.8313 104.766 56.6513C104.539 56.5539 104.427 56.973 104.654 57.0704Z" fill="#6D3A17" />
            <path d="M104.297 60.745C104.704 60.7509 105.097 60.6092 105.504 60.6151C105.746 60.6151 105.758 60.1813 105.516 60.1783C105.108 60.1724 104.716 60.3141 104.309 60.3082C104.067 60.3082 104.055 60.742 104.297 60.745Z" fill="#6D3A17" />
            <path d="M100.324 62.8759C100.726 62.8907 101.127 62.9055 101.525 62.9202C101.767 62.9291 101.779 62.4923 101.537 62.4834C101.136 62.4686 100.737 62.4539 100.336 62.4391C100.094 62.4303 100.082 62.8671 100.324 62.8759Z" fill="#6D3A17" />
            <path d="M94.9286 62.1558C95.2061 62.1676 95.4776 62.1823 95.7314 62.3299C95.9469 62.4568 96.1476 62.0849 95.9321 61.958C95.6163 61.7721 95.2917 61.7308 94.9405 61.716C94.6984 61.7072 94.6866 62.144 94.9286 62.1528V62.1558Z" fill="#6D3A17" />
            <path d="M89.3388 63.0028C89.5395 63.0087 89.7402 63.0175 89.9409 63.0234C90.1829 63.0323 90.1947 62.5955 89.9527 62.5866C89.752 62.5807 89.5513 62.5719 89.3506 62.566C89.1086 62.5571 89.0968 62.9939 89.3388 63.0028Z" fill="#6D3A17" />
            <path d="M87.571 62.1291C87.754 61.769 88.1141 61.5359 88.4269 61.3617C88.6453 61.2407 88.4683 60.857 88.2469 60.978C87.875 61.1817 87.4648 61.4709 87.2493 61.8959C87.1253 62.1409 87.4441 62.3711 87.5681 62.1291H87.571Z" fill="#6D3A17" />
            <path d="M106.611 58.7262C106.941 58.7498 107.269 58.9033 107.558 59.0803C107.771 59.2102 107.974 58.8413 107.759 58.7085C107.408 58.493 107.021 58.3159 106.623 58.2864C106.381 58.2687 106.369 58.7085 106.611 58.7232V58.7262Z" fill="#6D3A17" />
            <path d="M108.47 56.8667C108.869 56.8815 109.24 56.9199 109.618 57.0763C109.848 57.1707 109.958 56.7516 109.73 56.6572C109.323 56.4889 108.913 56.4447 108.485 56.4299C108.243 56.4211 108.231 56.8579 108.473 56.8667H108.47Z" fill="#6D3A17" />
            <path d="M107.154 55.5917C107.514 55.6065 107.924 55.5858 108.258 55.7776C108.473 55.9016 108.674 55.5297 108.458 55.4058C108.057 55.1755 107.605 55.1696 107.168 55.1549C106.926 55.146 106.915 55.5828 107.157 55.5917H107.154Z" fill="#6D3A17" />
            <path d="M111.109 57.4806C111.602 57.6902 112.109 57.8643 112.546 58.2126C112.747 58.372 112.945 58.0001 112.747 57.8407C112.283 57.4718 111.743 57.2829 111.221 57.0615C110.994 56.9641 110.881 57.3832 111.109 57.4806Z" fill="#6D3A17" />
            <path d="M109.131 60.0425C109.432 60.0543 109.733 60.0631 110.031 60.0749C110.274 60.0838 110.285 59.647 110.043 59.6381C109.742 59.6263 109.441 59.6175 109.143 59.6057C108.901 59.5968 108.889 60.0336 109.131 60.0425Z" fill="#6D3A17" />
            <path d="M107.67 63.6698C108.116 63.6757 108.512 63.5075 108.934 63.3569C109.164 63.2743 109.078 62.8493 108.845 62.9319C108.461 63.0677 108.089 63.2359 107.682 63.233C107.44 63.233 107.428 63.6668 107.67 63.6698Z" fill="#6D3A17" />
            <path d="M99.0224 55.8219C99.5419 55.8396 100.073 55.8573 100.581 55.695C100.814 55.6212 100.728 55.1962 100.492 55.27C100.017 55.4205 99.5212 55.4028 99.0342 55.3851C98.7922 55.3763 98.7804 55.8131 99.0224 55.8219Z" fill="#6D3A17" />
            <path d="M101.248 56.9553C101.49 56.9641 101.502 56.5273 101.26 56.5185C101.018 56.5096 101.006 56.9464 101.248 56.9553Z" fill="#6D3A17" />
            <path d="M101.248 56.9553C101.691 56.973 102.125 56.9996 102.547 57.1707C102.777 57.2622 102.886 56.8431 102.659 56.7516C102.207 56.5686 101.738 56.5362 101.263 56.5185C101.021 56.5096 101.009 56.9464 101.251 56.9553H101.248Z" fill="#6D3A17" />
            <path d="M103.63 57.7404C103.931 57.7522 104.232 57.761 104.53 57.7728C104.772 57.7817 104.784 57.3449 104.542 57.336C104.241 57.3242 103.94 57.3153 103.642 57.3035C103.4 57.2947 103.388 57.7315 103.63 57.7404Z" fill="#6D3A17" />
            <path d="M103.199 56.4358C103.37 56.2617 103.6 56.1731 103.824 56.1731C104.066 56.1731 104.081 55.7363 103.836 55.7363C103.511 55.7363 103.187 55.8662 102.939 56.117C102.756 56.3059 103.013 56.6217 103.196 56.4358H103.199Z" fill="#6D3A17" />
            <path d="M107.098 57.516C107.505 57.5426 107.865 57.7344 108.24 57.8997C108.467 58.0001 108.576 57.581 108.352 57.4806C107.942 57.3006 107.555 57.1058 107.109 57.0763C106.867 57.0615 106.856 57.4983 107.098 57.5131V57.516Z" fill="#6D3A17" />
            <defs>
                <filter id="filter0_d_0_1" x="0.520833" y="94.0417" width="125.417" height="19.6875" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2.91667" />
                    <feGaussianBlur stdDeviation="1.45833" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_0_5" x1="59.4562" y1="29.0002" x2="59.4562" y2="66.3848" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFE92E" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint1_linear_0_1" x1="63.2292" y1="107.896" x2="63.2292" y2="90.5781" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F7A037" />
                    <stop offset="0.759679" stopColor="#DB772C" />
                </linearGradient>
            </defs>
        </svg>

    );
}