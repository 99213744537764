import React from 'react';

export default function Kratong4() {
    return (
        <svg width="139" height="117" viewBox="0 0 139 117" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="70.9585" y="0.8125" width="2.1875" height="65.625" fill="#EBC2EE" />
            <rect x="74.604" y="0.8125" width="2.1875" height="65.625" fill="#EBC2EE" />
            <rect x="61.5996" y="26.9169" width="6.79719" height="37.3845" fill="url(#paint0_linear_857_14195)" />
            <path d="M61.5682 17.146C59.1892 23.9431 62.5595 25.3592 64.542 25.2176C70.9144 25.2176 69.6399 18.8452 68.7903 17.146C68.1105 15.7865 65.6749 10.0656 64.542 7.375C64.542 8.64947 64.542 8.64947 61.5682 17.146Z" fill="#FFE92E" />
            <path d="M61.5682 17.146C59.1892 23.9431 62.5595 25.3592 64.542 25.2176C70.9144 25.2176 69.6399 18.8452 68.7903 17.146C68.1105 15.7865 65.6749 10.0656 64.542 7.375C64.542 8.64947 64.542 8.64947 61.5682 17.146Z" fill="#FFE92E" />
            <path d="M61.5682 17.146C59.1892 23.9431 62.5595 25.3592 64.542 25.2176C70.9144 25.2176 69.6399 18.8452 68.7903 17.146C68.1105 15.7865 65.6749 10.0656 64.542 7.375C64.542 8.64947 64.542 8.64947 61.5682 17.146Z" fill="#FFE92E" />
            <path d="M63.0054 19.4485C61.3883 24.3011 63.6792 25.3121 65.0267 25.211C69.3581 25.211 68.4919 20.6617 67.9143 19.4485C67.4523 18.478 65.7968 14.3937 65.0267 12.4729C65.0267 13.3828 65.0267 13.3828 63.0054 19.4485Z" fill="#FFA217" />
            <path d="M63.6961 21.7561C62.5411 24.6677 64.1774 25.2743 65.1399 25.2136C68.2338 25.2136 67.615 22.484 67.2025 21.7561C66.8725 21.1738 65.6899 18.7233 65.1399 17.5708C65.1399 18.1167 65.1399 18.1167 63.6961 21.7561Z" fill="#FAEE8D" />
            <g filter="url(#filter0_d_857_14195)">
                <rect x="3.875" y="76.6458" width="131.25" height="33.5417" rx="16.7708" fill="white" />
                <rect x="3.875" y="76.6458" width="131.25" height="33.5417" rx="16.7708" fill="url(#paint1_linear_857_14195)" fillOpacity="0.5" />
            </g>
            <g filter="url(#filter1_d_857_14195)">
                <path d="M72.1883 60.6456C70.897 63.1576 77.4944 66.354 83.7461 69.3175C89.9978 72.2811 106.89 80.3885 109.931 82.9075C112.972 85.4336 122.039 88.9828 128.002 92.6802C129.469 93.5904 138.579 93.1459 133.703 80.9742C129.462 70.3901 112.21 59.7001 95.064 58.7476C85.4043 58.2113 73.6277 57.8303 72.1883 60.6386V60.6456Z" fill="url(#paint2_linear_857_14195)" />
                <path d="M113.565 63.3693C110.559 66.3681 92.4886 60.321 91.3667 69.5363C93.3212 67.9698 96.0167 66.0717 101.993 66.5092C110.764 67.1443 116.211 66.4034 116.966 65.0133L113.565 63.3763V63.3693Z" fill="url(#paint3_linear_857_14195)" />
                <path d="M97.3716 58.8393C90.8941 58.2395 80.1265 60.688 83.0548 66.0153C84.5577 64.4277 86.7875 62.7977 89.4264 62.1204C96.3978 60.3281 99.6013 60.695 103.496 59.9965L97.3716 58.8393Z" fill="url(#paint4_linear_857_14195)" />
                <path d="M122.159 68.1956C118.977 71.0463 98.4369 65.0839 99.961 74.3626C101.916 72.7962 102.388 69.7832 108.28 70.8064C119.386 72.7397 123.352 71.1521 125.215 70.4042L122.159 68.1956Z" fill="url(#paint5_linear_857_14195)" />
                <path d="M128.771 73.8334C124.318 76.4301 107.525 71.0251 107.306 78.6668C109.973 77.2415 111.737 76.296 117.594 77.0722C125.2 78.0882 130.641 79.7111 131.494 78.2647C132.108 77.2274 128.771 73.8334 128.771 73.8334Z" fill="url(#paint6_linear_857_14195)" />
                <path opacity="0.2" d="M103.637 60.5398C99.8267 59.7213 93.8502 58.7969 89.7648 59.4814C84.2751 60.4057 101.146 66.1776 106.001 68.8801C119.379 76.3313 123.458 82.3995 127.084 81.4963C130.711 80.5861 132.313 76.2819 124.975 70.7711C117.636 65.2603 111.124 62.1556 103.637 60.5398Z" fill="white" />
                <path d="M62.8462 62.3885C61.7102 64.9216 66.8964 67.9275 71.8145 70.7076C76.7326 73.4877 90.0051 81.1012 92.3547 83.5285C94.7044 85.9558 101.859 89.2439 106.516 92.7649C107.659 93.6328 115.005 92.9483 111.554 80.9671C108.548 70.5524 95.0784 60.3775 81.3261 59.8906C73.5785 59.6155 64.1163 59.5449 62.8533 62.3814L62.8462 62.3885Z" fill="url(#paint7_linear_857_14195)" />
                <path d="M96.0239 63.9902C93.4908 67.0596 79.1881 61.5206 77.9321 70.7146C79.5621 69.1059 81.8059 67.1513 86.597 67.4195C93.6248 67.8146 98.0349 66.9396 98.6982 65.5284L96.0239 63.9902Z" fill="url(#paint8_linear_857_14195)" />
                <path d="M83.1676 59.9189C77.9814 59.4955 69.2248 62.2191 71.3769 67.4406C72.647 65.8248 74.5027 64.1384 76.6478 63.3975C82.3279 61.4289 84.8893 61.704 88.0504 60.9138L83.1676 59.9259V59.9189Z" fill="url(#paint9_linear_857_14195)" />
                <path d="M102.748 68.5696C100.081 71.4837 83.7816 66.107 84.6565 75.294C86.2865 73.6852 86.7874 70.6723 91.4797 71.5331C100.342 73.1631 103.588 71.4767 105.119 70.6794L102.748 68.5625V68.5696Z" fill="url(#paint10_linear_857_14195)" />
                <path d="M107.85 73.9957C104.167 76.6982 90.8661 71.766 90.4004 79.3795C92.6019 77.8907 94.0554 76.9028 98.7336 77.5238C104.816 78.3282 109.127 79.8029 109.868 78.3352C110.397 77.2839 107.85 74.0028 107.85 74.0028V73.9957Z" fill="url(#paint11_linear_857_14195)" />
                <path opacity="0.2" d="M88.1419 61.443C85.1078 60.7303 80.3379 59.9682 77.0286 60.7656C72.5763 61.831 85.9263 67.1231 89.7296 69.6844C100.208 76.7405 103.256 82.6747 106.206 81.6727C109.155 80.6707 110.616 76.3454 104.921 71.0604C99.227 65.7754 94.1114 62.8612 88.149 61.45L88.1419 61.443Z" fill="white" />
                <path d="M59.565 64.1807C58.4854 67.2007 61.6113 70.5735 64.5043 74.2286C67.8983 78.5046 71.7438 83.0769 77.1276 89.2933C79.195 91.6782 83.3722 96.0883 86.6956 99.9409C87.5141 100.886 94.8101 97.7676 93.0743 83.9801C91.5643 71.9918 81.9257 60.8573 71.2922 61.0408C65.3016 61.1396 60.7575 60.7938 59.5509 64.1737L59.565 64.1807Z" fill="url(#paint12_linear_857_14195)" />
                <path d="M82.3845 65.0274C80.176 68.746 69.5213 63.0658 67.8208 73.8687C69.2108 71.9071 71.1019 69.4939 74.7993 69.5433C80.2183 69.6209 83.7111 68.3508 84.332 66.6786L82.3845 65.0274Z" fill="url(#paint13_linear_857_14195)" />
                <path d="M72.7385 60.9773C68.7518 60.7656 61.7451 64.4206 63.0011 70.3971C64.116 68.4426 65.6824 66.3752 67.4112 65.3873C71.9694 62.7766 73.938 62.96 76.45 61.8593L72.7455 60.9702L72.7385 60.9773Z" fill="url(#paint14_linear_857_14195)" />
                <path d="M87.239 69.9949C84.9387 73.5441 72.7247 68.1604 72.6753 78.8362C74.0653 76.8746 74.6933 73.3254 78.2637 74.0733C85.0023 75.4916 87.6624 73.3395 88.9113 72.3305L87.239 69.9949Z" fill="url(#paint15_linear_857_14195)" />
                <path d="M90.767 76.049C87.6976 79.4077 77.7767 74.3768 76.8101 83.2815C78.6305 81.4258 79.8371 80.191 83.4216 80.6567C88.0716 81.2635 91.3032 82.7453 91.9947 80.9954C92.4887 79.7394 90.774 76.049 90.774 76.049H90.767Z" fill="url(#paint16_linear_857_14195)" />
                <path opacity="0.2" d="M76.4786 62.4802C74.1854 61.8099 70.5445 61.1889 67.9125 62.2967C64.3775 63.7856 74.3124 69.2258 77.0572 72.0059C84.6284 79.6688 86.5194 86.4215 88.8903 85.0879C91.254 83.7543 92.7288 78.6316 88.728 72.775C84.7272 66.9185 80.9945 63.7997 76.4786 62.4802Z" fill="white" />
                <path d="M66.8191 60.6456C68.1104 63.1576 61.5129 66.354 55.2613 69.3175C49.0096 72.2811 32.1173 80.3885 29.0762 82.9075C26.035 85.4336 16.9679 88.9828 11.0056 92.6802C9.5379 93.5904 0.428506 93.1459 5.30425 80.9742C9.54495 70.3901 26.797 59.7001 43.9433 58.7476C53.6031 58.2113 65.3797 57.8303 66.8191 60.6386V60.6456Z" fill="url(#paint17_linear_857_14195)" />
                <path d="M25.442 63.3693C28.4479 66.3681 46.5185 60.321 47.6404 69.5363C45.6859 67.9698 42.9905 66.0717 37.014 66.5092C28.2433 67.1443 22.796 66.4034 22.041 65.0133L25.442 63.3763V63.3693Z" fill="url(#paint18_linear_857_14195)" />
                <path d="M41.6359 58.8393C48.1134 58.2395 58.8809 60.688 55.9527 66.0153C54.4497 64.4277 52.22 62.7977 49.581 62.1204C42.6096 60.3281 39.4062 60.695 35.5112 59.9965L41.6359 58.8393Z" fill="url(#paint19_linear_857_14195)" />
                <path d="M16.8478 68.1956C20.03 71.0463 40.5703 65.0839 39.0462 74.3626C37.0916 72.7962 36.6189 69.7832 30.7271 70.8064C19.6208 72.7397 15.6553 71.1521 13.7925 70.4042L16.8478 68.1956Z" fill="url(#paint20_linear_857_14195)" />
                <path d="M10.236 73.8334C14.6884 76.4301 31.4819 71.0251 31.7006 78.6668C29.0334 77.2415 27.2694 76.296 21.4128 77.0722C13.8064 78.0882 8.36617 79.7111 7.51239 78.2647C6.89851 77.2274 10.236 73.8334 10.236 73.8334Z" fill="url(#paint21_linear_857_14195)" />
                <path opacity="0.2" d="M35.3699 60.5398C39.1802 59.7213 45.1566 58.7969 49.2421 59.4814C54.7317 60.4057 37.8607 66.1776 33.0061 68.8801C19.6278 76.3313 15.5494 82.3995 11.9225 81.4963C8.29572 80.5861 6.69399 76.2819 14.0323 70.7711C21.3706 65.2603 27.8834 62.1556 35.3699 60.5398Z" fill="white" />
                <path d="M76.1609 62.3885C77.297 64.9216 72.1108 67.9275 67.1927 70.7076C62.2746 73.4877 49.0021 81.1012 46.6524 83.5285C44.3028 85.9558 37.1479 89.2439 32.4909 92.7649C31.3478 93.6328 24.0024 92.9483 27.4529 80.9671C30.4588 70.5524 43.9288 60.3775 57.6811 59.8906C65.4286 59.6155 74.8908 59.5449 76.1539 62.3814L76.1609 62.3885Z" fill="url(#paint22_linear_857_14195)" />
                <path d="M42.9833 63.9902C45.5165 67.0596 59.8191 61.5206 61.0751 70.7146C59.4451 69.1059 57.2013 67.1513 52.4102 67.4195C45.3824 67.8146 40.9724 66.9396 40.3091 65.5284L42.9833 63.9902Z" fill="url(#paint23_linear_857_14195)" />
                <path d="M55.8398 59.9189C61.026 59.4955 69.7826 62.2191 67.6305 67.4406C66.3604 65.8248 64.5047 64.1384 62.3596 63.3975C56.6795 61.4289 54.1182 61.704 50.957 60.9138L55.8398 59.9259V59.9189Z" fill="url(#paint24_linear_857_14195)" />
                <path d="M36.259 68.5696C38.9262 71.4837 55.2257 66.107 54.3508 75.294C52.7208 73.6852 52.2199 70.6723 47.5276 71.5331C38.6651 73.1631 35.4194 71.4767 33.8882 70.6794L36.259 68.5625V68.5696Z" fill="url(#paint25_linear_857_14195)" />
                <path d="M31.1573 73.9957C34.8406 76.6982 48.1413 71.766 48.607 79.3795C46.4055 77.8907 44.9519 76.9028 40.2737 77.5238C34.1914 78.3282 29.8801 79.8029 29.1393 78.3352C28.61 77.2839 31.1573 74.0028 31.1573 74.0028V73.9957Z" fill="url(#paint26_linear_857_14195)" />
                <path opacity="0.2" d="M50.8653 61.443C53.8994 60.7303 58.6693 59.9682 61.9786 60.7656C66.431 61.831 53.0809 67.1231 49.2777 69.6844C38.7994 76.7405 35.7512 82.6747 32.8018 81.6727C29.8523 80.6707 28.3917 76.3454 34.086 71.0604C39.7802 65.7754 44.8959 62.8612 50.8583 61.45L50.8653 61.443Z" fill="white" />
                <path d="M79.4423 64.1807C80.5219 67.2007 77.396 70.5735 74.503 74.2286C71.1091 78.5046 67.2635 83.0769 61.8797 89.2933C59.8123 91.6782 55.6351 96.0883 52.3117 99.9409C51.4932 100.886 44.1972 97.7676 45.933 83.9801C47.443 71.9918 57.0816 60.8573 67.7151 61.0408C73.7057 61.1396 78.2498 60.7938 79.4564 64.1737L79.4423 64.1807Z" fill="url(#paint27_linear_857_14195)" />
                <path d="M56.6228 65.0274C58.8313 68.746 69.486 63.0658 71.1865 73.8687C69.7965 71.9071 67.9054 69.4939 64.208 69.5433C58.789 69.6209 55.2962 68.3508 54.6753 66.6786L56.6228 65.0274Z" fill="url(#paint28_linear_857_14195)" />
                <path d="M66.2686 60.9773C70.2553 60.7656 77.262 64.4206 76.006 70.3971C74.8911 68.4426 73.3247 66.3752 71.596 65.3873C67.0377 62.7766 65.0691 62.96 62.5571 61.8593L66.2616 60.9702L66.2686 60.9773Z" fill="url(#paint29_linear_857_14195)" />
                <path d="M51.768 69.9949C54.0683 73.5441 66.2823 68.1604 66.3317 78.8362C64.9417 76.8746 64.3137 73.3254 60.7433 74.0733C54.0048 75.4916 51.3446 73.3395 50.0957 72.3305L51.768 69.9949Z" fill="url(#paint30_linear_857_14195)" />
                <path d="M48.24 76.049C51.3094 79.4077 61.2302 74.3768 62.1969 83.2815C60.3764 81.4258 59.1698 80.191 55.5853 80.6567C50.9354 81.2635 47.7037 82.7453 47.0122 80.9954C46.5183 79.7394 48.2329 76.049 48.2329 76.049H48.24Z" fill="url(#paint31_linear_857_14195)" />
                <path opacity="0.2" d="M62.5286 62.4802C64.8218 61.8099 68.4628 61.1889 71.0947 62.2967C74.6298 63.7856 64.6948 69.2258 61.95 72.0059C54.3788 79.6688 52.4878 86.4215 50.117 85.0879C47.7532 83.7543 46.2785 78.6316 50.2793 72.775C54.2801 66.9185 58.0127 63.7997 62.5286 62.4802Z" fill="white" />
                <path d="M65.0762 61.1819C62.0774 63.1647 61.1883 67.109 60.772 70.6723C60.1793 75.6962 60.017 80.826 61.0048 85.7864C62.0068 90.8315 64.8363 100.322 71.1303 100.978C75.0817 101.394 76.4435 96.427 77.2761 93.5058C79.5976 85.3066 80.0845 76.5289 77.7348 68.2874C77.0927 66.0153 76.0837 61.316 73.8469 60.06C71.6101 58.804 66.9743 59.933 65.0762 61.1819Z" fill="url(#paint32_linear_857_14195)" />
                <path d="M60.9617 68.0673C65.153 69.1398 68.674 57.589 77.5082 64.0242C75.1303 63.622 72.0962 63.2551 69.5136 65.9011C65.7316 69.789 62.3729 71.3625 60.75 70.6146L60.9617 68.0673Z" fill="url(#paint33_linear_857_14195)" />
                <path d="M60.75 75.8212C64.8849 76.7032 69.7183 64.2634 77.2965 71.7781C74.9186 71.3759 71.9762 69.3085 69.9793 72.3638C66.2114 78.1286 62.8174 78.4884 61.2157 78.6578L60.75 75.8212Z" fill="url(#paint34_linear_857_14195)" />
                <path d="M61.479 83.774C66.0231 83.9786 69.4877 73.4086 76.4661 79.0182C73.8624 78.9971 72.1337 78.9759 69.9322 81.8336C67.0745 85.5521 65.8397 88.8826 64.1109 88.1347C62.8761 87.5984 61.479 83.767 61.479 83.767V83.774Z" fill="url(#paint35_linear_857_14195)" />
                <path opacity="0.2" d="M62.7866 64.1927C61.4274 66.6423 60.5972 69.71 60.7735 72.4649C60.9131 74.5944 61.0821 76.7686 61.5229 78.8534C62.456 83.2836 63.0731 83.9314 64.0797 86.4257C64.7997 88.2202 65.8062 89.9104 67.0478 91.4293C67.9368 92.5164 69.2226 93.6109 70.6699 93.3875C71.963 93.1865 73.2046 92.0622 74.0128 91.1315C75.3867 89.553 75.6365 88.0489 76.1361 86.5747C77.9655 81.1392 78.6855 75.4804 77.9875 69.8217C77.701 67.506 76.9663 65.1904 75.5116 63.2917C74.1304 61.4898 71.5736 59.4199 69.0315 59.9635C66.4895 60.507 64.0356 61.9589 62.7939 64.1927H62.7866Z" fill="white" />
            </g>
            <path opacity="0.2" d="M77.2412 60.321C78.1091 63.0023 79.4709 56.1015 84.5019 56.6519C89.5329 57.2023 102.001 62.5931 104.908 63.8844C107.815 65.1756 118.216 67.6946 119.493 68.0192C120.77 68.3438 121.814 54.1258 117.087 47.9376C111.908 41.1567 95.841 37.0572 85.6803 43.3723C80.868 46.3641 74.5105 51.889 77.2412 60.321Z" fill="#3F1F06" />
            <path d="M77.2405 60.321C78.1084 63.0023 79.4702 56.1015 84.5012 56.6519C89.5322 57.2023 103.172 58.7828 106.079 60.0741C108.986 61.3653 118.222 67.6946 119.499 68.0192C120.776 68.3438 121.821 54.1258 117.093 47.9376C111.914 41.1567 95.8474 37.0572 85.6866 43.3723C80.8744 46.3641 74.5169 51.889 77.2476 60.321H77.2405Z" fill="url(#paint36_linear_857_14195)" />
            <path d="M113.163 62.078C108.457 57.7385 103.341 57.1105 96.6802 55.8404C91.0565 54.7679 81.9048 51.0987 78.1792 56.4966C77.4101 57.6115 76.7327 62.2262 78.8848 59.7989C80.423 58.0631 81.9613 56.3767 84.5014 56.6519C89.5324 57.2022 102.826 59.7495 105.733 61.0407C108.055 62.0709 116.381 66.1846 119.493 68.0121C117.813 65.4155 115.478 64.2019 113.163 62.0709V62.078Z" fill="url(#paint37_linear_857_14195)" />
            <path d="M99.9332 40.3171C99.404 44.2332 85.9269 45.0164 89.1797 54.1258C89.6948 51.9455 90.5063 49.1866 94.2954 47.4437C99.8485 44.8894 102.819 42.2363 102.678 40.6487L99.9332 40.3241V40.3171Z" fill="url(#paint38_linear_857_14195)" />
            <path d="M88.241 41.8624C84.0779 43.6264 78.6306 49.8075 82.6738 53.7871C82.9066 51.7479 83.5487 49.4053 84.8541 47.8177C88.2904 43.6123 90.379 42.8079 92.4323 40.7545L88.2481 41.8624H88.241Z" fill="url(#paint39_linear_857_14195)" />
            <path d="M119.725 54.0976C117.432 50.7389 108.951 55.3959 107.575 58.0349C109.268 56.9835 111.872 54.6621 115.64 55.2195C119.161 55.7416 120.516 59.6366 120.516 59.6366L119.725 54.1046V54.0976Z" fill="url(#paint40_linear_857_14195)" />
            <path d="M107.173 41.7847C106.467 45.6162 91.5504 47.3802 96.4191 55.5935C96.9342 53.4131 95.9322 50.3931 99.9189 49.243C107.441 47.0768 109.148 44.1485 109.953 42.7655L107.173 41.7777V41.7847Z" fill="url(#paint41_linear_857_14195)" />
            <path d="M113.559 44.7271C111.978 48.7844 99.5593 49.7158 102.678 57.0117C103.68 54.7044 104.336 53.1732 108.196 51.8114C113.213 50.0333 117.185 49.6099 117.079 47.9376C117.002 46.7381 113.551 44.7342 113.551 44.7342L113.559 44.7271Z" fill="url(#paint42_linear_857_14195)" />
            <path opacity="0.2" d="M92.743 41.2132C90.097 41.8059 86.1032 43.083 83.937 45.2069C81.0229 48.0576 93.6532 47.4366 97.7316 48.2481C108.972 50.4707 114.017 54.7397 115.816 52.5805C117.616 50.4143 116.748 45.7785 109.974 43.21C103.2 40.6416 97.9574 40.056 92.75 41.2202L92.743 41.2132Z" fill="white" />
            <path opacity="0.2" d="M61.6118 63.7856C62.4797 66.4669 66.297 62.0216 71.328 62.5719C77.9678 63.2987 86.6679 64.8652 89.6738 66.2058C92.5809 67.4971 102.586 71.1592 103.864 71.4838C105.134 71.8083 106.185 57.5903 101.457 51.4022C96.2783 44.6213 80.2116 40.5217 70.0509 46.8369C65.2386 49.8287 58.8811 55.3536 61.6118 63.7856Z" fill="#3F1F06" />
            <path d="M61.6116 63.7856C62.4795 66.4669 63.8413 59.566 68.8723 60.1164C73.9033 60.6668 87.5427 62.2473 90.4498 63.5386C93.3569 64.8299 102.593 71.1592 103.87 71.4838C105.141 71.8083 106.192 57.5903 101.464 51.4022C96.2851 44.6213 80.2185 40.5217 70.0577 46.8369C65.2455 49.8287 58.888 55.3536 61.6187 63.7856H61.6116Z" fill="url(#paint43_linear_857_14195)" />
            <path d="M97.5343 65.5425C92.8279 61.203 87.7122 60.575 81.0513 59.305C75.4276 58.2324 66.2759 54.5633 62.5503 59.9612C61.7812 61.076 61.1038 65.6907 63.2559 63.2634C64.7941 61.5276 66.3323 59.8412 68.8725 60.1164C73.9035 60.6668 87.1548 63.0023 90.0619 64.2936C92.3834 65.3238 100.752 69.6492 103.864 71.4837C102.184 68.8871 99.8487 67.6735 97.5343 65.5425Z" fill="url(#paint44_linear_857_14195)" />
            <path d="M84.3043 43.7745C83.7751 47.6907 70.298 48.4739 73.5508 57.5833C74.0659 55.4029 74.8774 52.644 78.6665 50.9012C84.2196 48.3469 87.1902 45.6938 87.0491 44.1062L84.3043 43.7816V43.7745Z" fill="url(#paint45_linear_857_14195)" />
            <path d="M72.6121 45.3198C68.449 47.0839 63.0017 53.265 67.0449 57.2446C67.2777 55.2054 67.9198 52.8628 69.2252 51.2752C72.6615 47.0697 74.7501 46.2654 76.8034 44.212L72.6192 45.3198H72.6121Z" fill="url(#paint46_linear_857_14195)" />
            <path d="M101.796 51.6562C98.3103 49.5676 91.487 57.3222 91.3389 60.2999C92.4326 58.6346 93.8297 55.4382 97.4776 54.3657C100.893 53.3637 103.758 56.3273 103.758 56.3273L101.796 51.6632V51.6562Z" fill="url(#paint47_linear_857_14195)" />
            <path d="M92.2134 45.3057C91.4584 49.5888 79.456 52.9333 83.9508 59.4814C84.48 57.0259 84.8257 55.3959 88.3467 53.3003C92.919 50.5766 96.7364 49.3841 96.3059 47.7612C95.9955 46.597 92.2205 45.3057 92.2205 45.3057H92.2134Z" fill="url(#paint48_linear_857_14195)" />
            <path opacity="0.2" d="M77.1068 44.6707C74.4607 45.2634 70.467 46.5405 68.3008 48.6644C65.3866 51.5151 78.017 50.8941 82.0954 51.7056C93.3357 53.9282 98.3808 58.1972 100.18 56.038C101.979 53.8788 101.112 49.2359 94.3377 46.6675C87.5639 44.0991 82.3212 43.5135 77.1138 44.6777L77.1068 44.6707Z" fill="white" />
            <path opacity="0.2" d="M61.5268 60.321C60.6589 63.0023 59.2971 56.1015 54.2661 56.6519C49.2351 57.2023 36.7671 62.5931 33.86 63.8844C30.9529 65.1756 20.5522 67.6946 19.2751 68.0192C17.9979 68.3438 16.9536 54.1258 21.6812 47.9376C26.8603 41.1567 42.927 37.0572 53.0878 43.3723C57.9 46.3641 64.2575 51.889 61.5268 60.321Z" fill="#3F1F06" />
            <path d="M61.5265 60.321C60.6586 63.0023 59.2968 56.1015 54.2659 56.6519C49.2349 57.2023 35.5955 58.7828 32.6884 60.0741C29.7813 61.3653 20.5449 67.6946 19.2677 68.0192C17.9906 68.3438 16.9463 54.1258 21.6738 47.9376C26.853 41.1567 42.9197 37.0572 53.0804 43.3723C57.8927 46.3641 64.2502 51.889 61.5195 60.321H61.5265Z" fill="url(#paint49_linear_857_14195)" />
            <path d="M25.6042 62.078C30.3106 57.7385 35.4263 57.1105 42.0872 55.8404C47.7109 54.7679 56.8626 51.0987 60.5882 56.4966C61.3573 57.6115 62.0347 62.2262 59.8826 59.7989C58.3444 58.0631 56.8062 56.3767 54.266 56.6519C49.235 57.2022 35.9413 59.7495 33.0342 61.0407C30.7128 62.0709 22.3866 66.1846 19.2749 68.0121C20.9542 65.4155 23.2898 64.2019 25.6042 62.0709V62.078Z" fill="url(#paint50_linear_857_14195)" />
            <path d="M38.8346 40.3171C39.3638 44.2332 52.8409 45.0164 49.588 54.1258C49.0729 51.9455 48.2615 49.1866 44.4724 47.4437C38.9193 44.8894 35.9486 42.2363 36.0898 40.6487L38.8346 40.3241V40.3171Z" fill="url(#paint51_linear_857_14195)" />
            <path d="M50.5263 41.8624C54.6894 43.6264 60.1366 49.8075 56.0935 53.7871C55.8607 51.7479 55.2186 49.4053 53.9132 47.8177C50.4769 43.6123 48.3883 42.8079 46.335 40.7545L50.5192 41.8624H50.5263Z" fill="url(#paint52_linear_857_14195)" />
            <path d="M19.0422 54.0976C21.3355 50.7389 29.8169 55.3959 31.1928 58.0349C29.4993 56.9835 26.8956 54.6621 23.1277 55.2195C19.6067 55.7416 18.252 59.6366 18.252 59.6366L19.0422 54.1046V54.0976Z" fill="url(#paint53_linear_857_14195)" />
            <path d="M31.595 41.7847C32.3006 45.6162 47.2172 47.3802 42.3485 55.5935C41.8334 53.4131 42.8354 50.3931 38.8487 49.243C31.3269 47.0768 29.6193 44.1485 28.8149 42.7655L31.595 41.7777V41.7847Z" fill="url(#paint54_linear_857_14195)" />
            <path d="M25.2091 44.7271C26.7896 48.7844 39.2083 49.7158 36.0895 57.0117C35.0876 54.7044 34.4314 53.1732 30.5717 51.8114C25.5548 50.0333 21.5823 49.6099 21.6881 47.9376C21.7657 46.7381 25.2161 44.7342 25.2161 44.7342L25.2091 44.7271Z" fill="url(#paint55_linear_857_14195)" />
            <path opacity="0.2" d="M46.0246 41.2132C48.6706 41.8059 52.6644 43.083 54.8306 45.2069C57.7447 48.0576 45.1144 47.4366 41.036 48.2481C29.7956 50.4707 24.7505 54.7397 22.9512 52.5805C21.1519 50.4143 22.0198 45.7785 28.7937 43.21C35.5746 40.6346 40.8172 40.0419 46.0246 41.2132Z" fill="white" />
            <path opacity="0.2" d="M77.1562 63.7856C76.2883 66.4669 72.471 62.0216 67.44 62.5719C60.8002 63.2987 52.1001 64.8652 49.0942 66.2058C46.1871 67.4971 36.1816 71.1592 34.9045 71.4838C33.6344 71.8083 32.583 57.5903 37.3106 51.4022C42.4897 44.6213 58.5564 40.5217 68.7172 46.8369C73.5294 49.8287 79.8869 55.3536 77.1562 63.7856Z" fill="#3F1F06" />
            <path d="M77.1559 63.7856C76.2881 66.4669 74.9262 59.566 69.8952 60.1164C64.8643 60.6668 51.2249 62.2473 48.3178 63.5386C45.4107 64.8299 36.1743 71.1592 34.8971 71.4838C33.627 71.8083 32.5757 57.5903 37.3033 51.4022C42.4824 44.6213 58.5491 40.5217 68.7098 46.8369C73.5221 49.8287 79.8796 55.3536 77.1489 63.7856H77.1559Z" fill="url(#paint56_linear_857_14195)" />
            <path d="M41.2336 65.5425C45.94 61.203 51.0556 60.575 57.7166 59.305C63.3403 58.2324 72.492 54.5633 76.2176 59.9612C76.9867 61.076 77.6641 65.6907 75.512 63.2634C73.9738 61.5276 72.4355 59.8412 69.8954 60.1164C64.8644 60.6668 51.6131 63.0023 48.706 64.2936C46.3845 65.3238 38.016 69.6492 34.9043 71.4837C36.5836 68.8871 38.9192 67.6735 41.2336 65.5425Z" fill="url(#paint57_linear_857_14195)" />
            <path d="M54.4635 43.7745C54.9927 47.6907 68.4698 48.4739 65.2169 57.5833C64.7018 55.4029 63.8904 52.644 60.1013 50.9012C54.5482 48.3469 51.5776 45.6938 51.7187 44.1062L54.4635 43.7816V43.7745Z" fill="url(#paint58_linear_857_14195)" />
            <path d="M66.1557 45.3198C70.3187 47.0839 75.766 53.265 71.7229 57.2446C71.4901 55.2054 70.8479 52.8628 69.5426 51.2752C66.1063 47.0697 64.0177 46.2654 61.9644 44.212L66.1486 45.3198H66.1557Z" fill="url(#paint59_linear_857_14195)" />
            <path d="M34.6716 57.5551C36.9649 54.1964 45.4463 58.8534 46.8222 61.4923C45.1287 60.441 42.525 58.1195 38.7571 58.677C35.2361 59.1991 33.8813 63.0941 33.8813 63.0941L34.6716 57.5621V57.5551Z" fill="url(#paint60_linear_857_14195)" />
            <path d="M47.2244 45.2492C47.93 49.0807 62.8466 50.8447 57.9779 59.058C57.4628 56.8777 58.4648 53.8577 54.4781 52.7075C46.9563 50.5413 45.2487 47.613 44.4443 46.23L47.2244 45.2422V45.2492Z" fill="url(#paint61_linear_857_14195)" />
            <path d="M40.8385 48.1917C42.419 52.2489 54.8377 53.1803 51.7189 60.4763C50.717 58.1689 50.0608 56.6378 46.2011 55.2759C41.1842 53.4978 37.2117 53.0745 37.3175 51.4022C37.3951 50.2026 40.8455 48.1987 40.8455 48.1987L40.8385 48.1917Z" fill="url(#paint62_linear_857_14195)" />
            <path opacity="0.2" d="M61.654 44.6707C64.3 45.2634 68.2938 46.5405 70.46 48.6644C73.3741 51.5151 60.7438 50.8941 56.6654 51.7056C45.425 53.9282 40.3799 58.1972 38.5806 56.038C36.7813 53.8788 37.6492 49.2359 44.4231 46.6675C51.1969 44.0991 56.4396 43.5135 61.6469 44.6777L61.654 44.6707Z" fill="white" />
            <path opacity="0.2" d="M58.8738 50.9012C53.5606 53.9423 49.4399 59.2132 48.4873 65.2109C48.3956 65.7824 48.3391 66.3963 48.5861 66.9184C48.9248 67.6311 49.7362 67.991 50.4983 68.2238C57.0181 70.2277 63.9189 67.2995 70.6928 66.4598C75.759 65.8318 80.9805 66.4034 85.7786 68.118C86.4278 68.3508 87.084 68.6049 87.7755 68.5978C88.9539 68.5907 90.0123 67.8075 90.6756 66.8479C92.3549 64.4347 91.9598 61.1325 90.8308 58.43C85.5881 45.8772 69.592 44.7553 58.8597 50.9012H58.8738Z" fill="#3F1F06" />
            <path d="M58.8738 48.9608C53.5606 52.0019 49.4399 57.2728 48.4873 63.2705C48.3956 63.842 48.3391 64.4559 48.5861 64.9781C48.9248 65.6907 49.7362 66.0506 50.4983 66.2834C57.0181 68.2873 63.9189 65.3591 70.6928 64.5194C75.759 63.8914 80.9805 64.463 85.7786 66.1776C86.4278 66.4104 87.084 66.6645 87.7755 66.6574C88.9539 66.6503 90.0123 65.8671 90.6756 64.9075C92.3549 62.4943 91.9598 59.1921 90.8308 56.4896C85.5881 43.9368 69.592 42.8149 58.8597 48.9608H58.8738Z" fill="url(#paint63_linear_857_14195)" />
            <path d="M67.5947 45.7997C68.0604 49.5111 55.9169 53.5049 61.1031 61.0549C61.0396 58.9381 61.1173 56.2074 64.1655 53.6954C68.632 50.0051 70.7065 46.851 70.1914 45.4327L67.5947 45.7997Z" fill="url(#paint64_linear_857_14195)" />
            <path d="M57.3001 49.8569C53.9202 52.4817 50.4345 59.4672 55.0986 62.1274C54.8164 60.2081 54.8375 57.9008 55.6419 56.1297C57.7658 51.4445 59.4804 50.2026 60.8634 47.8177L57.3001 49.8498V49.8569Z" fill="url(#paint65_linear_857_14195)" />
            <path d="M84.6213 49.7934C81.5237 50.7318 78.292 56.6025 78.4119 58.9451C79.2869 57.2728 80.2959 54.1258 83.6334 52.764C86.7593 51.4939 89.3065 54.401 89.3065 54.401L88.0717 52.1924C87.9024 51.889 87.7118 51.5997 87.5002 51.3175L86.9921 50.6401C86.4417 49.9063 85.4962 49.5252 84.6142 49.7934H84.6213Z" fill="url(#paint66_linear_857_14195)" />
            <path d="M75.6813 45.2845C75.8365 49.4053 65.3794 54.8879 70.8408 60.06C70.8408 57.6821 70.8408 56.1015 73.6844 53.4625C77.3818 50.0262 80.677 48.1705 79.9573 46.7522C79.4422 45.7361 75.6813 45.2916 75.6813 45.2916V45.2845Z" fill="url(#paint67_linear_857_14195)" />
            <path opacity="0.2" d="M62.0843 47.3167C59.276 48.3046 53.6523 51.6139 50.6252 57.421C48.7201 61.0761 56.4253 57.0329 67.842 57.2305C79.2588 57.4281 91.9738 62.3744 90.8519 56.4967C90.3156 53.6672 85.7645 48.0788 79.2658 46.3147C74.2066 44.9459 68.5688 45.0447 62.0843 47.3238V47.3167Z" fill="white" />
            <defs>
                <filter id="filter0_d_857_14195" x="0.958333" y="76.6458" width="137.083" height="39.375" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2.91667" />
                    <feGaussianBlur stdDeviation="1.45833" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_857_14195" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_857_14195" result="shape" />
                </filter>
                <filter id="filter1_d_857_14195" x="3.88232" y="58.3697" width="131.243" height="44.7498" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2.11682" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.584314 0 0 0 0 0.129412 0 0 0 0.26 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_857_14195" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_857_14195" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_857_14195" x1="64.9982" y1="26.9169" x2="64.9982" y2="64.3014" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFE92E" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint1_linear_857_14195" x1="69.5" y1="82.0963" x2="69.4998" y2="96.679" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF9521" />
                    <stop offset="1" stopColor="#FF9521" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_857_14195" x1="103.208" y1="84.2491" x2="108.771" y2="65.6151" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E95704" />
                    <stop offset="1" stopColor="#FF9521" />
                </linearGradient>
                <linearGradient id="paint3_linear_857_14195" x1="106.487" y1="60.4263" x2="104.766" y2="73.4088" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint4_linear_857_14195" x1="91.4854" y1="57.6461" x2="89.7691" y2="70.5952" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint5_linear_857_14195" x1="114.216" y1="66.8816" x2="114.424" y2="73.1287" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint6_linear_857_14195" x1="121.631" y1="71.0461" x2="121.608" y2="78.4744" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint7_linear_857_14195" x1="87.2634" y1="85.9296" x2="93.0282" y2="66.6239" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E95704" />
                    <stop offset="1" stopColor="#FF9521" />
                </linearGradient>
                <linearGradient id="paint8_linear_857_14195" x1="90.9072" y1="61.3081" x2="89.1837" y2="74.3116" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint9_linear_857_14195" x1="77.9033" y1="58.8105" x2="76.1843" y2="71.7801" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint10_linear_857_14195" x1="96.8342" y1="67.0049" x2="97.078" y2="74.0314" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint11_linear_857_14195" x1="102.685" y1="70.9762" x2="102.656" y2="79.1047" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint12_linear_857_14195" x1="74.8128" y1="94.956" x2="82.4609" y2="69.3296" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E95704" />
                    <stop offset="1" stopColor="#FF9521" />
                </linearGradient>
                <linearGradient id="paint13_linear_857_14195" x1="79.1405" y1="62.2184" x2="77.1055" y2="77.5722" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint14_linear_857_14195" x1="67.9867" y1="59.8538" x2="65.9597" y2="75.1473" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint15_linear_857_14195" x1="83.1685" y1="67.6707" x2="83.4932" y2="77.2399" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint16_linear_857_14195" x1="87.475" y1="71.9783" x2="87.4338" y2="82.8374" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint17_linear_857_14195" x1="35.4408" y1="84.3039" x2="29.8772" y2="65.6628" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E95704" />
                    <stop offset="1" stopColor="#FF9521" />
                </linearGradient>
                <linearGradient id="paint18_linear_857_14195" x1="32.162" y1="60.481" x2="33.8817" y2="73.4565" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint19_linear_857_14195" x1="47.5829" y1="57.6454" x2="49.3001" y2="70.6013" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint20_linear_857_14195" x1="24.4385" y1="66.9355" x2="24.222" y2="73.1829" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint21_linear_857_14195" x1="17.0169" y1="71.1008" x2="17.0403" y2="78.5291" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint22_linear_857_14195" x1="51.3853" y1="85.9843" x2="45.6205" y2="66.6786" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E95704" />
                    <stop offset="1" stopColor="#FF9521" />
                </linearGradient>
                <linearGradient id="paint23_linear_857_14195" x1="47.7415" y1="61.3628" x2="49.465" y2="74.3663" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint24_linear_857_14195" x1="61.1658" y1="58.8166" x2="62.8839" y2="71.7794" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint25_linear_857_14195" x1="41.8137" y1="67.0527" x2="41.5699" y2="74.0791" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint26_linear_857_14195" x1="35.9633" y1="71.0239" x2="35.993" y2="79.1595" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint27_linear_857_14195" x1="63.8361" y1="95.0108" x2="56.1879" y2="69.3844" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E95704" />
                    <stop offset="1" stopColor="#FF9521" />
                </linearGradient>
                <linearGradient id="paint28_linear_857_14195" x1="59.5083" y1="62.2731" x2="61.5433" y2="77.6269" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint29_linear_857_14195" x1="71.0822" y1="59.86" x2="73.1083" y2="75.1466" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint30_linear_857_14195" x1="55.4801" y1="67.7254" x2="55.1544" y2="77.2875" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint31_linear_857_14195" x1="51.1735" y1="72.033" x2="51.2147" y2="82.8922" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint32_linear_857_14195" x1="69.705" y1="101.599" x2="69.832" y2="63.4469" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E95704" />
                    <stop offset="1" stopColor="#FF9521" />
                </linearGradient>
                <linearGradient id="paint33_linear_857_14195" x1="60.4761" y1="64.8809" x2="72.7718" y2="74.2987" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint34_linear_857_14195" x1="61.1904" y1="72.3927" x2="67.7216" y2="79.3845" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint35_linear_857_14195" x1="60.1313" y1="79.6583" x2="67.839" y2="87.3078" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint36_linear_857_14195" x1="105.285" y1="70.364" x2="97.7736" y2="45.0322" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E95704" />
                    <stop offset="1" stopColor="#FF9521" />
                </linearGradient>
                <linearGradient id="paint37_linear_857_14195" x1="101.922" y1="61.2408" x2="102.637" y2="44.547" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E95704" />
                    <stop offset="0.28" stopColor="#ED630A" />
                    <stop offset="0.8" stopColor="#F9861A" />
                    <stop offset="1" stopColor="#FF9521" />
                </linearGradient>
                <linearGradient id="paint38_linear_857_14195" x1="93.7425" y1="41.0876" x2="98.6086" y2="51.8649" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint39_linear_857_14195" x1="85.583" y1="42.5286" x2="90.6985" y2="53.8584" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint40_linear_857_14195" x1="114.531" y1="48.4412" x2="118.469" y2="57.9374" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint41_linear_857_14195" x1="101.286" y1="43.2184" x2="104.986" y2="49.0418" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint42_linear_857_14195" x1="108.392" y1="44.3514" x2="112.343" y2="51.0313" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint43_linear_857_14195" x1="88.9777" y1="74.1271" x2="81.4663" y2="48.7953" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E95704" />
                    <stop offset="1" stopColor="#FF9521" />
                </linearGradient>
                <linearGradient id="paint44_linear_857_14195" x1="85.6093" y1="65.0065" x2="86.3301" y2="48.3104" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E95704" />
                    <stop offset="0.28" stopColor="#ED630A" />
                    <stop offset="0.8" stopColor="#F9861A" />
                    <stop offset="1" stopColor="#FF9521" />
                </linearGradient>
                <linearGradient id="paint45_linear_857_14195" x1="77.4353" y1="44.8507" x2="82.3014" y2="55.628" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint46_linear_857_14195" x1="69.6975" y1="46.0942" x2="74.8157" y2="57.4298" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint47_linear_857_14195" x1="93.2512" y1="49.9672" x2="100.465" y2="56.6217" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint48_linear_857_14195" x1="85.8052" y1="46.8436" x2="90.9874" y2="52.6208" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint49_linear_857_14195" x1="36.8563" y1="71.8625" x2="44.374" y2="46.5333" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E95704" />
                    <stop offset="1" stopColor="#FF9521" />
                </linearGradient>
                <linearGradient id="paint50_linear_857_14195" x1="40.2251" y1="62.7418" x2="39.5104" y2="46.048" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E95704" />
                    <stop offset="0.28" stopColor="#ED630A" />
                    <stop offset="0.8" stopColor="#F9861A" />
                    <stop offset="1" stopColor="#FF9521" />
                </linearGradient>
                <linearGradient id="paint51_linear_857_14195" x1="48.4051" y1="42.5886" x2="43.539" y2="53.366" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint52_linear_857_14195" x1="54.2911" y1="42.998" x2="49.1756" y2="54.3277" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint53_linear_857_14195" x1="27.6136" y1="49.9482" x2="23.6786" y2="59.4384" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint54_linear_857_14195" x1="40.859" y1="44.7254" x2="37.1617" y2="50.5428" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint55_linear_857_14195" x1="33.755" y1="45.8524" x2="29.8042" y2="52.5323" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint56_linear_857_14195" x1="53.1696" y1="75.6282" x2="60.681" y2="50.2963" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E95704" />
                    <stop offset="1" stopColor="#FF9521" />
                </linearGradient>
                <linearGradient id="paint57_linear_857_14195" x1="56.5384" y1="66.5075" x2="55.815" y2="49.8175" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E95704" />
                    <stop offset="0.28" stopColor="#ED630A" />
                    <stop offset="0.8" stopColor="#F9861A" />
                    <stop offset="1" stopColor="#FF9521" />
                </linearGradient>
                <linearGradient id="paint58_linear_857_14195" x1="64.7123" y1="46.3517" x2="59.8462" y2="57.129" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint59_linear_857_14195" x1="70.1771" y1="46.5635" x2="65.0589" y2="57.8991" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint60_linear_857_14195" x1="43.9214" y1="53.7114" x2="39.9863" y2="63.2016" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint61_linear_857_14195" x1="57.1667" y1="48.4885" x2="53.4694" y2="54.3059" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint62_linear_857_14195" x1="50.0627" y1="49.6155" x2="46.1119" y2="56.2954" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint63_linear_857_14195" x1="70.0577" y1="65.7331" x2="70.4317" y2="44.967" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E95704" />
                    <stop offset="0.28" stopColor="#ED630A" />
                    <stop offset="0.8" stopColor="#F9861A" />
                    <stop offset="1" stopColor="#FF9521" />
                </linearGradient>
                <linearGradient id="paint64_linear_857_14195" x1="60.0906" y1="49.6191" x2="67.1658" y2="58.3034" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint65_linear_857_14195" x1="54.3646" y1="51.6103" x2="61.8067" y2="60.745" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint66_linear_857_14195" x1="77.6214" y1="50.4647" x2="85.2816" y2="56.2251" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
                <linearGradient id="paint67_linear_857_14195" x1="68.7897" y1="49.3436" x2="74.7346" y2="53.6685" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#F4C3BC" />
                    <stop offset="1" stopColor="#FB8B1C" />
                </linearGradient>
            </defs>
        </svg>
    );
}