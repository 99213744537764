import React  from 'react';
export default function Avatar4({ custom }) {
    const face = custom.face;
    const neck = custom.neck;
    const hair = custom.hairType1;
    const hair2 = custom.hairType2;
    const hair3 = custom.hairType3;
    const hair4 = custom.hairType4;
    return (
        <svg width="119" height="176" viewBox="0 0 119 176" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="50.4038" y="71.9309" width="47.8968" height="44.563" fill={hair4} />
            <path d="M19.195 140.347C19.195 154.876 11.8264 165.285 8.35864 174.826" stroke={face} strokeWidth="15.8541" strokeLinecap="round" strokeLinejoin="bevel" />
            <path d="M14.5457 185.597L22.1311 162.177H70.0279L106.655 173.454V188.633L71.9784 193.404H34.2677L14.5457 185.597Z" fill="#CEAF9A" />
            <path d="M79.8788 198.68L41.7349 194.343L33.4993 173.525L85.0803 175.694L79.8788 198.68Z" fill="#6A8AB9" />
            <path d="M9.30153 140.973C9.90843 139.431 11.6499 138.674 13.1913 139.281L25.9619 144.309C27.5032 144.916 28.2608 146.658 27.6538 148.199L25.6413 153.31C25.0343 154.852 23.2928 155.609 21.7515 155.002L8.98093 149.974C7.43956 149.367 6.68203 147.625 7.28894 146.084L9.30153 140.973Z" fill="#E1C6B3" />
            <path d="M26.564 159.864H95.4833L94.3997 179.608H26.9974L26.564 159.864Z" fill="#5C7CAC" />
            <path d="M38.9415 163.476L40.4586 179.306C40.5376 180.131 41.2701 180.735 42.0946 180.656C42.9191 180.577 43.5234 179.845 43.4444 179.02L41.9273 163.19C41.8483 162.366 41.1158 161.761 40.2913 161.84C39.4668 161.919 38.8625 162.652 38.9415 163.476Z" fill="#51709D" />
            <path d="M79.6621 163.333L78.145 179.163" stroke="#51709D" strokeWidth="2.99943" strokeLinecap="round" />
            <path d="M20.4616 104.865C23.5197 94.9529 34.881 90.3226 43.9951 95.274C52.307 99.7894 55.1025 110.375 50.1039 118.405L35.5685 141.757C32.216 147.142 25.8317 149.826 19.6388 148.452C8.60513 146.005 4.1638 132.67 11.5264 124.096L13.8906 121.342C15.4216 119.559 16.5695 117.479 17.2626 115.233L20.4616 104.865Z" fill="#E1C6B3" />
            <rect x="33.4993" y="94.1584" width="50.0641" height="70.4759" rx="23.9954" fill="#EFD6C5" />
            <path d="M47.3697 164.371C37.8336 164.371 27.3325 182.344 14.5456 185.814C11.9449 171.744 19.5689 159.979 21.7957 149.238C23.6528 140.281 26.1304 132.241 26.1304 123.17V104.521C31.6207 101.774 42.8618 93.6378 42.1683 100.23C41.3012 108.47 40.8678 123.17 59.9398 123.17C79.0119 123.17 68.1682 104.521 81.1792 104.521C99.3842 104.521 109.039 179.308 107.305 187.115C105.918 193.36 100.685 180.944 97.6505 177.428L89.4148 171.744L77.0614 164.371C68.9701 164.66 56.9057 164.371 47.3697 164.371Z" fill="#EFD4C5" />
            <path fillRule="evenodd" clipRule="evenodd" d="M42.5731 158.183C39.1418 151.191 37.0667 143.031 37.0667 135.046H40.0661C40.0661 142.55 42.0278 150.263 45.2658 156.862C46.7972 159.983 48.5348 162.621 50.0785 164.398H46.2589C44.9828 162.641 43.7248 160.53 42.5731 158.183ZM78.8225 164.398C79.5145 163.458 80.1279 162.41 80.6537 161.275C82.3093 157.698 82.9137 153.571 82.2199 150.428L79.291 151.074C79.8339 153.534 79.326 157.003 77.9317 160.015C77.101 161.809 76.0308 163.304 74.7935 164.398H78.8225ZM62.9604 164.398C63.4598 163.254 63.6723 162.109 63.714 160.769C63.7232 160.472 63.725 160.241 63.725 159.723C63.725 157.17 63.9563 155.956 65.1499 153.885L62.5515 152.387C61.7166 153.835 61.2118 155.177 60.9552 156.525C60.7636 157.532 60.7256 158.254 60.7256 159.723C60.7256 160.21 60.724 160.42 60.716 160.676C60.6709 162.125 60.3902 163.138 59.5374 164.398H62.9604Z" fill="#DDC1AD" />
            <path d="M95.1681 192.753C105.941 192.753 114.674 184.015 114.674 173.237C114.674 162.458 105.941 153.72 95.1681 153.72C84.3955 153.72 75.6626 162.458 75.6626 173.237C75.6626 184.015 84.3955 192.753 95.1681 192.753Z" fill="#6C4D4B" />
            <path fillRule="evenodd" clipRule="evenodd" d="M99.4124 192.25C109.233 192.25 117.195 184.288 117.195 174.467C117.195 164.646 109.233 156.685 99.4124 156.685C89.5914 156.685 81.6299 164.646 81.6299 174.467C81.6299 184.288 89.5914 192.25 99.4124 192.25Z" fill="#8F5B47" />
            <path fillRule="evenodd" clipRule="evenodd" d="M99.286 194.271C110.058 194.271 118.791 185.533 118.791 174.755C118.791 163.976 110.058 155.238 99.286 155.238C88.5135 155.238 79.7805 163.976 79.7805 174.755C79.7805 185.533 88.5135 194.271 99.286 194.271ZM99.286 157.381C108.875 157.381 116.649 165.159 116.649 174.755C116.649 184.35 108.875 192.129 99.286 192.129C89.697 192.129 81.923 184.35 81.923 174.755C81.923 165.159 89.697 157.381 99.286 157.381Z" fill="#E3A490" />
            <path d="M59.3734 126.127C51.3724 123.834 46.7453 115.489 49.0385 107.488V107.488C51.3316 99.4871 59.6766 94.8599 67.6776 97.1531L86.832 102.643C94.833 104.936 99.4601 113.281 97.167 121.282V121.282C94.8738 129.283 86.5288 133.91 78.5279 131.617L59.3734 126.127Z" fill="#EFD6C5" />
            <path d="M80.2974 169.039L78.13 112.225C78.0872 111.101 77.1419 110.226 76.0186 110.268C74.8953 110.311 74.0194 111.257 74.0623 112.38L76.2296 169.194C76.2725 170.317 77.2178 171.193 78.3411 171.15C79.4643 171.108 80.3402 170.162 80.2974 169.039Z" fill="#E3A490" />
            <path d="M75.6484 171.248L79.9829 172.115L80.4453 169.804L76.1108 168.937L75.6484 171.248Z" fill="#E3A490" />
            <path d="M90.7151 118.229C100.251 135.143 107.186 168.104 103.719 177.645" stroke={face} strokeWidth="15.8541" strokeLinecap="round" strokeLinejoin="bevel" />
            <path d="M74.9535 119.343C72.3176 112.548 75.1405 104.85 81.5448 101.371C89.4923 97.0533 99.4057 100.999 102.214 109.597L107.97 127.216C109.571 132.117 108.128 137.503 104.291 140.948C102.077 142.936 99.2482 144.105 96.2769 144.26L95.6121 144.295C89.1904 144.631 83.2796 140.805 80.9533 134.809L74.9535 119.343Z" fill="#E1C6B3" />
            <mask id="mask0_438_1867" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="54" y="163" width="13" height="18">
                <rect x="54.0881" y="163.984" width="12.5702" height="16.2637" rx="1.28547" fill="white" />
            </mask>
            <g mask="url(#mask0_438_1867)">
                <path fillRule="evenodd" clipRule="evenodd" d="M65.3728 160.984H55.3736C53.0071 160.984 51.0886 162.903 51.0886 165.269V178.962C51.0886 181.328 53.0071 183.247 55.3736 183.247H65.3728C67.7393 183.247 69.6577 181.328 69.6577 178.962V165.269C69.6577 162.903 67.7393 160.984 65.3728 160.984ZM57.0876 177.248V166.983H63.6589V177.248H57.0876Z" fill="#51709D" />
            </g>
            <path d="M89.3665 143.495C88.8274 141.929 89.6602 140.222 91.2265 139.683L104.204 135.217C105.77 134.678 107.477 135.51 108.016 137.077L109.804 142.271C110.343 143.837 109.51 145.544 107.944 146.083L94.9666 150.55C93.4002 151.089 91.6934 150.256 91.1543 148.69L89.3665 143.495Z" fill="#E1C6B3" />
            <path d="M58.7479 119.313C66.9471 119.313 73.5938 112.371 73.5938 103.808C73.5938 95.245 66.9471 88.3033 58.7479 88.3033C50.5488 88.3033 43.9021 95.245 43.9021 103.808C43.9021 112.371 50.5488 119.313 58.7479 119.313Z" fill="#DFC5B2" />
            <rect x="49.9705" y="89.1708" width="16.7545" height="25.1457" rx="8.37716" fill={neck} />
            <path d="M13.2528 86.9151C12.0508 78.358 18.0134 70.4467 26.5705 69.2447L60.106 64.5342C68.6631 63.3322 76.5744 69.2947 77.7763 77.8518V77.8518C78.9783 86.4089 73.0158 94.3202 64.4587 95.5222L30.9231 100.233C22.3661 101.435 14.4548 95.4722 13.2528 86.9151V86.9151Z" fill={hair4} />
            <path d="M24.4807 72.9697C31.472 72.9697 37.1396 67.5908 37.1396 60.9556C37.1396 54.3204 31.472 48.9415 24.4807 48.9415C17.4894 48.9415 11.8218 54.3204 11.8218 60.9556C11.8218 67.5908 17.4894 72.9697 24.4807 72.9697Z" fill="#EBBAAA" />
            <path d="M88.2512 45.6032C84.3015 29.1248 67.2691 20.6577 60.6667 18.6341C57.6524 12.9967 58.0831 1.31544 62.8197 0.421044C69.7092 -0.879897 88.219 7.35934 96.4005 23.4042C106.686 43.5763 105.273 93.9014 102.79 111.682C99.5852 104.849 96.4005 98.5798 86.1194 99.6083C91.313 76.6254 88.2512 59.9133 88.2512 59.9133C88.2512 59.9133 90.4339 54.7097 88.2512 45.6032Z" fill={hair} />
            <path d="M87.7141 73.0948C94.7054 73.0948 100.373 67.7159 100.373 61.0807C100.373 54.4455 94.7054 49.0666 87.7141 49.0666C80.7228 49.0666 75.0552 54.4455 75.0552 61.0807C75.0552 67.7159 80.7228 73.0948 87.7141 73.0948Z" fill={neck} />
            <path d="M23.6455 38.1494C23.6455 20.7126 38.5886 6.57738 57.0217 6.57738C75.4547 6.57738 90.3978 20.7126 90.3978 38.1494V69.5516C87.7062 89.9205 71.5565 101.124 57.0217 101.124C42.487 101.124 25.6194 87.5443 23.6455 69.5516V38.1494Z" fill={face} />
            <path fillRule="evenodd" clipRule="evenodd" d="M48.136 76.7264V80.1642C48.136 85.4447 52.4166 89.7255 57.6974 89.7255C62.9779 89.7255 67.2588 85.4447 67.2588 80.1642V76.7264H48.136Z" fill="#393939" />
            <mask id="mask1_438_1867" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="48" y="76" width="20" height="14">
                <path fillRule="evenodd" clipRule="evenodd" d="M48.136 76.7264V80.1642C48.136 85.4447 52.4166 89.7255 57.6974 89.7255C62.9779 89.7255 67.2588 85.4447 67.2588 80.1642V76.7264H48.136Z" fill="white" />
            </mask>
            <g mask="url(#mask1_438_1867)">
                <path d="M57.6346 92.6257C61.3027 92.6257 64.2762 90.9951 64.2762 88.9836C64.2762 86.9721 61.3027 85.3414 57.6346 85.3414C53.9665 85.3414 50.9929 86.9721 50.9929 88.9836C50.9929 90.9951 53.9665 92.6257 57.6346 92.6257Z" fill="#FF6C6C" />
                <rect x="47.7791" y="76.3431" width="20.7819" height="4.2849" fill="white" />
            </g>
            <path d="M58.6136 0.84734C66.4004 -2.73539 86.5304 6.27651 95.6395 24.1537C95.6395 24.1537 100.577 34.9392 101.787 42.3934C102.981 49.7478 99.0668 53.4675 99.0668 53.4675L90.3977 48.7848C90.3977 48.7848 83.8958 38.6542 75.6601 31.7568C67.4245 24.8594 60.4034 22.8716 58.2517 21.3516C56.1002 19.8316 55.847 6.71012 58.6136 0.84734Z" fill={hair} />
            <path d="M36.0091 48.2612L48.2839 47.725C48.9932 47.694 49.5431 47.0939 49.5121 46.3846C49.4811 45.6754 48.881 45.1255 48.1717 45.1565L35.8969 45.6927C35.1876 45.7237 34.6377 46.3238 34.6687 47.0331C34.6997 47.7424 35.2998 48.2922 36.0091 48.2612Z" fill={hair} />
            <path d="M35.5554 77.9C39.3258 77.9 42.3823 74.8418 42.3823 71.0693C42.3823 67.2967 39.3258 64.2385 35.5554 64.2385C31.785 64.2385 28.7285 67.2967 28.7285 71.0693C28.7285 74.8418 31.785 77.9 35.5554 77.9Z" fill="#F2CACA" />
            <path d="M34.593 45.8925C37.2572 34.1722 50.5294 22.9584 56.8139 20.9327C59.4056 20.0973 61.1393 0.0718842 56.8139 0.0718842C45.8641 0.0718842 28.4956 11.0865 22.2974 20.9327C12.119 37.1018 8.51963 55.2293 13.9375 96.029C15.2633 106.013 12.0292 110.354 9.7749 112.741C15.9908 119.123 39.2844 121.423 39.2844 121.423C39.2844 121.423 43.206 116.547 40.536 106.881C35.714 89.4236 31.9291 57.6127 34.593 45.8925Z" fill={hair2} />
            <path d="M25.6087 21.9661C23.1987 25.1011 20.3636 30.7895 18.1856 37.0676C15.6135 44.4818 14.1169 52.1238 14.1169 59.0491C14.1169 59.6407 14.5966 60.1203 15.1882 60.1203C15.7798 60.1203 16.2594 59.6407 16.2594 59.0491C16.2594 52.3846 17.7108 44.9729 20.2097 37.7698C22.3181 31.6924 25.0541 26.2028 27.3073 23.2719C27.6679 22.8029 27.5799 22.1304 27.1109 21.7698C26.6418 21.4092 25.9693 21.4971 25.6087 21.9661Z" fill={hair3} />
            <path d="M26.105 32.2181C23.1814 40.0187 22.5103 42.0076 21.3037 46.6358C21.1545 47.2083 21.4976 47.7934 22.0701 47.9426C22.6426 48.0919 23.2276 47.7488 23.3769 47.1763C24.5605 42.636 25.2116 40.7068 28.1112 32.97C28.3189 32.416 28.0381 31.7986 27.4841 31.591C26.9301 31.3833 26.3127 31.6641 26.105 32.2181Z" fill={hair3} />
            <path d="M39.9133 57.9904L45.6842 57.0779C46.2686 56.9855 46.6674 56.4369 46.575 55.8525C46.4826 55.2682 45.934 54.8694 45.3496 54.9618L39.5787 55.8742C38.9943 55.9666 38.5955 56.5152 38.6879 57.0996C38.7803 57.684 39.3289 58.0828 39.9133 57.9904Z" fill="black" />
            <path d="M67.3164 56.519L73.0874 57.4314C73.6718 57.5238 74.2204 57.125 74.3128 56.5406C74.4052 55.9563 74.0064 55.4077 73.422 55.3153L67.651 54.4028C67.0667 54.3104 66.5181 54.7092 66.4257 55.2936C66.3333 55.8779 66.7321 56.4266 67.3164 56.519Z" fill="black" />
            <path d="M44.4238 56.0198V62.1854" stroke="black" strokeWidth="5.14188" strokeLinecap="round" />
            <path d="M68.8107 55.4609V61.6266" stroke="black" strokeWidth="5.14188" strokeLinecap="round" />
            <path d="M64.7768 47.3251L77.0405 48.0756C77.7492 48.119 78.3588 47.5797 78.4021 46.871C78.4455 46.1624 77.9062 45.5528 77.1976 45.5094L64.9339 44.7589C64.2252 44.7156 63.6156 45.2549 63.5723 45.9635C63.5289 46.6721 64.0682 47.2817 64.7768 47.3251Z" fill={hair} />
            <path d="M55.9845 62.2582C55.5502 65.4248 52.8196 71.1991 57.4737 71.1991" stroke={neck} strokeWidth="3.64217" />
            <path d="M92.9532 75.6703C94.3926 75.6703 95.5594 74.5028 95.5594 73.0626C95.5594 71.6224 94.3926 70.4549 92.9532 70.4549C91.5138 70.4549 90.3469 71.6224 90.3469 73.0626C90.3469 74.5028 91.5138 75.6703 92.9532 75.6703Z" fill="#F6E7D6" />
            <path d="M92.1889 80.2131C93.2063 80.2131 94.031 79.3879 94.031 78.3699C94.031 77.3519 93.2063 76.5267 92.1889 76.5267C91.1715 76.5267 90.3467 77.3519 90.3467 78.3699C90.3467 79.3879 91.1715 80.2131 92.1889 80.2131Z" fill="#F6E7D6" />
            <path d="M78.4675 77.9C82.2379 77.9 85.2944 74.8418 85.2944 71.0693C85.2944 67.2967 82.2379 64.2385 78.4675 64.2385C74.6971 64.2385 71.6406 67.2967 71.6406 71.0693C71.6406 74.8418 74.6971 77.9 78.4675 77.9Z" fill="#F2CACA" />
        </svg>
    );
}