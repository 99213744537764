import "./AlertPickup.style.css";
import CustomButton from "../../button/CustomButton";
import { KRATONGS } from "../configure/modalState";

export default function AlertPickup({ setOpen }) {
    return (
        <div className="text-center">
            <div className="container-center">
                <div className="picture">
                    {KRATONGS[0].kratong}
                </div>
            </div>
            <h1>Let’s protect our rivers</h1>
            <div>
                <div>You can <span className="bold">pick up another Krathong</span> as a sustainable and clean river contribution.</div>
                <div className="pt-1">Thank you for your eco-conscious efforts!</div>
            </div>
            <CustomButton type="primary" className="margin-top" onClick={() => setOpen(false)} block>Continue</CustomButton>
        </div>
    );
}