
import "./App.css";
import {React , useEffect } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./pages/Main/Main";
import DownloadKratong from "./pages/downloadKratong/DownloadKratong";
import { KratongProvider } from "./store/KratongContext";

function App() {
  useEffect(() => {
    ReactGA.initialize(`${process.env.REACT_APP_GA_TRACKING_ID}`);
  }, []);

  
  return (
    <KratongProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/download" element={<DownloadKratong />} />
        </Routes>
      </BrowserRouter>
    </KratongProvider>
  );
}

export default App;
