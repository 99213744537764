import { useState, createContext } from "react";
import { Grid } from "antd";

export const KratongContext = createContext();

export function KratongProvider({ children }) {
  const [currentKratong, setCurrentKratong] = useState({ a: "test" });

  const { useBreakpoint } = Grid;
  const { xs: isMobile } = useBreakpoint();

  const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") === -1 &&
    navigator.userAgent.indexOf("FxiOS") === -1;

  const value = { currentKratong, setCurrentKratong, isSafari, isMobile };
  return (
    <KratongContext.Provider value={value}>{children}</KratongContext.Provider>
  );
}
