import "./DownloadKratong.style.css";
import { useEffect, useRef } from "react";
import View from "../loykratong/view/View";
import Loading from "../../components/modal/loading/Loading";
import html2canvas from "html2canvas";
export default function DownloadKratong() {
  const cardRef = useRef();
  const kratong = [JSON?.parse(localStorage?.getItem("tempKratong"))];
  console.log(kratong)
  const handleDownloadImage = async (ref) => {
    const element = ref.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = kratong[0]?.name+"_"+kratong[0]?.noTypeKrathong + ".jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
    
    setTimeout(()=>{
      window?.close();
    },5000)

  };
  useEffect(() => {
    handleDownloadImage(cardRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="download-container">
      <div className="download-view">
        <View
          kratongs={{
            1: [],
            2: [],
            3: kratong,
          }}
          isExport
          cardRef={cardRef}
        />
      </div>
      <Loading />
    </div>
  );
}
