export default function TreeRed() {
    return (
        <svg width="33" height="54" viewBox="0 0 33 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="12.8794" y="19.1672" width="7.38391" height="34.8328" fill="#93642D" />
            <rect x="12.8989" y="13.0262" width="7.38391" height="34.8328" fill="#714D22" />
            <path d="M32.9798 27.5013C32.9798 36.6144 25.597 44.0021 16.4899 44.0021C7.38278 44.0021 0 36.6144 0 27.5013C0 18.3882 7.38277 0 16.4899 0C25.597 0 32.9798 18.3882 32.9798 27.5013Z" fill="#EE9D9D" />
            <path fillRule="evenodd" clipRule="evenodd" d="M32.5798 23.31C31.1922 31.0181 24.4544 36.8661 16.3517 36.8661C8.55163 36.8661 2.01647 31.4468 0.301278 24.1657C0.116594 25.4093 0.0200195 26.5729 0.0200195 27.6152C0.0200195 36.7283 7.4028 44.116 16.5099 44.116C25.617 44.116 32.9998 36.7283 32.9998 27.6152C32.9998 26.3371 32.8546 24.8766 32.5798 23.31Z" fill="#C03232" />
            <path d="M12.12 17.7425C12.12 18.8589 11.2156 19.7638 10.1 19.7638C8.98445 19.7638 8.08008 18.8589 8.08008 17.7425C8.08008 16.6262 8.98445 15.7212 10.1 15.7212C11.2156 15.7212 12.12 16.6262 12.12 17.7425Z" fill="black" />
            <path d="M11.2221 17.2934C11.2221 17.6655 10.9207 17.9671 10.5488 17.9671C10.1769 17.9671 9.87549 17.6655 9.87549 17.2934C9.87549 16.9212 10.1769 16.6196 10.5488 16.6196C10.9207 16.6196 11.2221 16.9212 11.2221 17.2934Z" fill="white" />
            <path d="M24.2396 17.7425C24.2396 18.8589 23.3353 19.7638 22.2197 19.7638C21.1041 19.7638 20.1997 18.8589 20.1997 17.7425C20.1997 16.6262 21.1041 15.7212 22.2197 15.7212C23.3353 15.7212 24.2396 16.6262 24.2396 17.7425Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.6858 24.793C13.423 25.1591 11.4633 26.5966 10.6336 27.9058C10.3681 28.3248 9.81335 28.4491 9.39463 28.1834C8.9759 27.9177 8.85172 27.3626 9.11725 26.9436C10.2327 25.1834 12.6496 23.4642 15.3993 23.0193C18.2355 22.5605 21.402 23.4624 23.8489 26.9039C24.1364 27.3082 24.0419 27.8691 23.6379 28.1567C23.2339 28.4443 22.6734 28.3498 22.3859 27.9455C20.3441 25.0737 17.8621 24.4409 15.6858 24.793Z" fill="black" />
            <path d="M23.3423 17.2934C23.3423 17.6655 23.0408 17.9671 22.6689 17.9671C22.2971 17.9671 21.9956 17.6655 21.9956 17.2934C21.9956 16.9212 22.2971 16.6196 22.6689 16.6196C23.0408 16.6196 23.3423 16.9212 23.3423 17.2934Z" fill="white" />
        </svg>
    );
}