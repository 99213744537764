export default function TreeGreen() {
    return (
        <svg width="33" height="54" viewBox="0 0 33 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="12.8794" y="19.1672" width="7.38391" height="34.8328" fill="#93642D" />
            <rect x="12.8989" y="13.0262" width="7.38391" height="34.8328" fill="#714D22" />
            <path d="M32.9798 27.5013C32.9798 36.6144 25.597 44.0021 16.4899 44.0021C7.38278 44.0021 0 36.6144 0 27.5013C0 18.3882 7.38277 0 16.4899 0C25.597 0 32.9798 18.3882 32.9798 27.5013Z" fill="#66A865" />
            <path fillRule="evenodd" clipRule="evenodd" d="M32.5798 23.31C31.1922 31.0181 24.4544 36.8661 16.3517 36.8661C8.55163 36.8661 2.01647 31.4468 0.301278 24.1657C0.116594 25.4093 0.0200195 26.5729 0.0200195 27.6152C0.0200195 36.7283 7.4028 44.116 16.5099 44.116C25.617 44.116 32.9998 36.7283 32.9998 27.6152C32.9998 26.3371 32.8546 24.8766 32.5798 23.31Z" fill="#458244" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.3068 26.4383C19.5697 26.0722 21.5294 24.6347 22.3591 23.3255C22.6246 22.9065 23.1793 22.7822 23.598 23.0479C24.0168 23.3136 24.141 23.8687 23.8754 24.2877C22.7599 26.0479 20.3431 27.7671 17.5934 28.212C14.7572 28.6708 11.5907 27.7689 9.14373 24.3274C8.85629 23.9231 8.95078 23.3622 9.35479 23.0746C9.75879 22.787 10.3193 22.8815 10.6067 23.2858C12.6486 26.1576 15.1305 26.7904 17.3068 26.4383Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M9.62735 17.5465C8.8446 17.6702 8.18406 18.1624 7.93501 18.5462C7.66499 18.9623 7.10898 19.0806 6.69314 18.8104C6.27729 18.5402 6.15907 17.9838 6.42909 17.5677C6.97129 16.7322 8.09106 15.9703 9.3473 15.7718C10.6859 15.5603 12.1748 15.9903 13.2943 17.528C13.5862 17.929 13.498 18.4909 13.0973 18.7831C12.6965 19.0752 12.135 18.987 11.843 18.5859C11.1366 17.6156 10.3277 17.4359 9.62735 17.5465Z" fill="black" />
            <path fillRule="evenodd" clipRule="evenodd" d="M23.0937 17.5465C22.3109 17.6702 21.6504 18.1624 21.4013 18.5462C21.1313 18.9623 20.5753 19.0806 20.1594 18.8104C19.7436 18.5402 19.6254 17.9838 19.8954 17.5677C20.4376 16.7322 21.5574 15.9703 22.8136 15.7718C24.1522 15.5603 25.6411 15.9903 26.7606 17.528C27.0525 17.929 26.9643 18.4909 26.5636 18.7831C26.1628 19.0752 25.6013 18.987 25.3093 18.5859C24.6029 17.6156 23.794 17.4359 23.0937 17.5465Z" fill="black" />
        </svg>
    );
}