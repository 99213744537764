import "./Main.style.css";
import { useRef, useState, useEffect, useCallback, useMemo } from "react";
import ReactGA from "react-ga4";
import { CREATE_STATE } from "../../components/modal/configure/modalState";
import CreateModal from "../../components/modal/CreateModal";
import View from "../loykratong/view/View";
import AnimateClickableComponent from "../../components/animateClickableComponent/AnimateClickableComponent";
import {
  getKratongs,
  getSummary,
  updateKratong,
} from "../../components/modal/configure/api";
import Error from "../error/Error";
// import { mockkrathongs } from "../../components/modal/configure/mockup";

export default function Main() {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(true);
  const [kratongs, setKratongs] = useState({ 1: [], 2: [], 3: [] });
  const [userId, setUserId] = useState([]);
  const [isPicked, setIsPicked] = useState(true);
  const cardRef = useRef();
  const [randKratongs, setRandKratongs] = useState([]);
  const [windowwidth, setWindowWidth] = useState(window.innerWidth);
  const [error, setError] = useState(false);
  const [emissionNo, setEmissionNo] = useState({ from: 0, to: 0 });
  const [callKratongCount, setCallKratongCount] = useState(0);
  const [readyToCall, setReadyToCall] = useState(true);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const getKratongsFromApi = useCallback(async () => {
    // setRandKratongs(mockkrathongs)
    try {
      const resKratongs = await getKratongs();
      if (resKratongs.message !== undefined) {
        setError({
          message: resKratongs.message,
          status: resKratongs.response.status,
        });
      } else {
        setError(false);
        setReadyToCall(false);
        setCallKratongCount((prev) => {
          let result = 0;
          if (resKratongs?.length === 0) {
            if (prev === 0) {
              result = 1;
            }
          }
          return result;
        });

        if (randKratongs?.length > 0) {
          if (resKratongs?.length === 30) {
            setReadyToCall(false);
            setRandKratongs(resKratongs);
          } else {
            const filterKratong = resKratongs?.filter((i) => {
              const temp = randKratongs?.find((k) => k.id === i.id);
              return temp ? false : temp;
            });
            if (filterKratong?.length === 0) {
              setTimeout(() => {
                setReadyToCall(true);
                setRandKratongs([]);
              }, 60000);
            } else {
              setReadyToCall(false);
              setRandKratongs(filterKratong);
            }
          }
        } else {
          setReadyToCall(false);
          setRandKratongs(resKratongs);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [randKratongs]);

  const getEmission = useCallback(async () => {
    try {
      const response = await getSummary();
      if (response.message !== undefined) {
        setError({
          message: response.message,
          status: response.response.status,
        });
      } else {
        setError(false);
        setEmissionNo((prev) => {
          return {
            from: prev?.to,
            to: response?.c02UnitTotal,
            response: response,
          };
        });
      }
    } catch (e) {}
  }, []);

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      !error && getEmission();
      if (error) {
        clearInterval(interval);
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [error, getEmission]);

  useEffect(() => {
    if (readyToCall && callKratongCount === 0 && !error) {
      getKratongsFromApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyToCall, error]);

  useEffect(() => {
    let timeout;
    timeout = setTimeout(() => {
      setKratongs((prev) => {
        return { ...prev, 2: [] };
      });
    }, (windowwidth / 20) * 1000);
    return () => {
      clearInterval(timeout);
    };
  }, [kratongs, windowwidth]);

  useEffect(() => {
    getEmission();
  }, [getEmission]);

  /* remove kratong from river when user pick it up */
  const removeKratong = async (e, setWave, index) => {
    ReactGA.event({
      category: "user action",
      action: "pick-kratong",
      label: "test label 3",
      value: 3,
    });
    // let arrayNo, index;
    const resUpdataKratong = await updateKratong({
      id: e.id,
      avatarid: e?.idAvatar,
      status: "Inactive",
    });
    if (resUpdataKratong.message !== undefined) {
      setError({
        message: resUpdataKratong.message,
        status: resUpdataKratong.response.status,
      });
    } else {
      setError(false);
      setWave((prev) => {
        prev[index].status = "Inactive";
        return prev;
      });
    }

    setIsPicked(true);
  };

  const createModal = useMemo(() => {
    return (
      <CreateModal
        open={isCreateModalOpen}
        setOpen={setIsCreateModalOpen}
        addKratong={(e) => {
          setKratongs((prev) => {
            return { ...prev, 2: [e] };
          });
        }}
        initialState={CREATE_STATE[0]}
        cardRef={cardRef}
        setUserId={setUserId}
        setIsPicked={setIsPicked}
        setError={setError}
        error={error}
      />
    );
  }, [error, isCreateModalOpen]);

  const kratongDisplay = useMemo(() => {
    return (
      <View
        cardRef={cardRef}
        kratongs={randKratongs}
        userId={userId}
        isPicked={isPicked}
        removeKratong={removeKratong}
        windowwidth={windowwidth}
        kratongLoyShow={kratongs}
        emission={emissionNo}
        setRandKratongs={setRandKratongs}
        setReadyTocall={setReadyToCall}
      />
    );
  }, [emissionNo, isPicked, kratongs, randKratongs, userId, windowwidth]);

  return (
    <div className="main-container">
      {createModal}
      {kratongDisplay}

      <div className="create-area">
        <AnimateClickableComponent
          className={`create-kratong-button`}
          onClick={() => {
            ReactGA.event({
              category: "user action",
              action: "create-kratong",
              label: "test label",
              value: 1,
            });
            setIsCreateModalOpen(true);
          }}
        >
          Craft your own Krathong
        </AnimateClickableComponent>
      </div>
      <div className="footer">© 2023 Accenture. All Rights Reserved.</div>
      <Error error={error} setError={setError} />
    </div>
  );
}
