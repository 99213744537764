import "./View.style.css";
import { Divider } from "antd";
import Lottie from "lottie-react";
import lotty1 from "../../../assets/lotty_1.json";
import moon from "../../../assets/moon.svg";
import { KratongAnimation } from "../../Main/Main.style";
import LoyKratong from "../../../components/kratong/LoyKratong";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CO2Level from "../../../components/co2/CO2Level";
import logo from "../../../assets/ACN_logo.png";
import {
  DETAIL_KRATONGS,
  KRATONGS,
  USER_ICONS,
} from "../../../components/modal/configure/modalState";
import EmissionCounter from "../../../components/EmissionCounter/EmissionCounter";
import { KratongContext } from "../../../store/KratongContext";

export default function View({
  cardRef,
  kratongs,
  isExport,
  removeKratong,
  userId,
  isPicked,
  windowwidth,
  kratongLoyShow,
  emission,
  setRandKratongs,
  setReadyTocall
}) {
  const templeAnimate = isExport ? "" : "temple-animation";
  const templeAnimate2 = isExport ? "" : "temple2-animation";
  const parkAnimate = isExport ? "" : "park-animation";
  const loyAnimate = isExport ? "" : "wave-animation";
  const loyWrap = isExport ? "loy-wrap" : "";
  const loyImg = isExport ? "loy-img" : "";
  const exportData = kratongs?.["3"]?.[0];
  const exportDataKratong = DETAIL_KRATONGS?.find(
    (i) => i?.key === exportData?.noTypeKrathong
  );
  const firework2 = useRef();
  const [waveList1, setWaveList1] = useState([]);
  const [waveList2, setWaveList2] = useState([]);
  const [waveList3, setWaveList3] = useState([]);
  const [waveList1v2, setWaveList1v2] = useState([]);
  const [waveList2v2, setWaveList2v2] = useState([]);
  const [waveList3v2, setWaveList3v2] = useState([]);

  const [isComplete, setIsComplete] = useState(true);

  const [lengthList, setLengthList] = useState([0, 0, 0]);

  const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") === -1 &&
    navigator.userAgent.indexOf("FxiOS") === -1;

  const { isMobile } = useContext(KratongContext);
  const timeToCall = isMobile ? 5000 : 4000;

  const setWaveValue = (length, setValue1, setValue2, data) => {
    if (length < 11) {
      setValue1((prev) => [...prev, data]);
    } else {
      setValue2((prev) => [...prev, data]);
    }
    if (length === 20) {
      setValue1([]);
    }
    if (length === 10) {
      setValue2([]);
    }
  };

  const pushKratong = useCallback(
    (data, current, round, length1, length2, length3) => {
      let setKratongTimeout;
      setKratongTimeout = setTimeout(() => {
        setIsComplete(false);
        if (round < data?.length) {
          current === 1 &&
            setWaveValue(length1, setWaveList1, setWaveList1v2, data[round]);
          current === 2 &&
            setWaveValue(length2, setWaveList2, setWaveList2v2, data[round]);
          current === 3 &&
            setWaveValue(length3, setWaveList3, setWaveList3v2, data[round]);
          if (current === 3) {
            current = 1;
          } else {
            current++;
          }
          round++;
          if (current === 1) length1 = length1 > 20 ? 0 : length1 + 1;
          if (current === 2) length2 = length2 > 20 ? 0 : length2 + 1;
          if (current === 3) length3 = length3 > 20 ? 0 : length3 + 1;

          if (round === data?.length) {
            setReadyTocall(true);
            setIsComplete(true);
            setLengthList((prev) => [length1, length2, length3]);
          }
          pushKratong(data, current, round, length1, length2, length3);
        } else {
          clearTimeout(setKratongTimeout);
          setIsComplete(true);
          setLengthList((prev) => [length1, length2, length3]);
        }
      }, timeToCall);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeToCall]
  );

  useEffect(() => {
    if (isComplete) {
      pushKratong(kratongs, 1, 0, lengthList[0], lengthList[1], lengthList[2]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kratongs, pushKratong]);

  useEffect(()=>{
    setTimeout(()=>{
      firework2?.current?.play();
    },4500)
  },[firework2])

  const kratongLoy = useCallback(
    (wave, setWave) => {
      return wave?.map((item, index) => {
        return (
          <KratongAnimation key={index} isMobile={isMobile}>
            <LoyKratong
              index={index}
              data={item}
              removeKratong={removeKratong}
              userId={userId}
              isPicked={isPicked}
              setWave={setWave}
            />
          </KratongAnimation>
        );
      });
    },
    [isPicked, removeKratong, userId, isMobile]
  );

  const emissionComponent = useMemo(() => {
    return <EmissionCounter emission={emission} />;
  }, [emission]);
  
  return (
    <div ref={cardRef}>
      <section className="view-container">
        <section className="city-section">
          <div className="top-left">
            <img src={logo} alt="logo" className="logo" />
            {!isExport && emissionComponent}
          </div>
          <div className="top-right">

            <img src={moon} alt="moon" className="moon" />
          </div>
          {!isExport && (
            <div className="firework firework-1">
              <Lottie animationData={lotty1} loop={true} />
            </div>
          )}
          {!isExport && !isMobile && (
            <div className="firework firework-2">
              <Lottie id="firework-2"  animationData={lotty1} loop={true} lottieRef={firework2} autoplay={false}/>
            </div>
          )}

          <div className="temple-container">
            <div
              className={`view-background-setting-temple temple ${templeAnimate}`}
            ></div>
          </div>
          <div className="temple-container2">
            <div
              className={`view-background-setting-temple2 temple2 ${templeAnimate2}`}
            ></div>
          </div>

          <div className="park-container">
            <div
              className={`view-background-setting-park park ${parkAnimate}`}
            ></div>
          </div>
        </section>
      </section>

      <section className="wave-container">
        <section className="wave-wrapper">
          <div
            className={`wave-image position4 ${loyWrap}`}
            style={{ zIndex: 1 }}
          >
            {waveList1?.length > 0 && kratongLoy(waveList1, setWaveList1)}
            {waveList1v2?.length > 0 && kratongLoy(waveList1v2, setWaveList1v2)}
            <div
              className={`wave-background-setting wave4 ${loyAnimate} ${loyImg}`}
            ></div>
          </div>
          <div
            className={`wave-image position3 ${loyWrap}`}
            style={{ zIndex: 3 }}
          >
            {waveList2?.length > 0 && kratongLoy(waveList2, setWaveList2)}
            {waveList2v2?.length > 0 && kratongLoy(waveList2v2, setWaveList2v2)}
            {kratongLoy(kratongLoyShow?.["2"])}
            <div
              className={`wave-background-setting wave3 ${loyAnimate} ${loyImg}`}
            >
              {isExport && (
                <div className="export-kratong-card-information">
                  <div className="export-card">
                    <div class="user-section">
                      <div
                        className={
                          isSafari ? "safari-icon" : "icon-information"
                        }
                      >
                        <div>
                          {React?.cloneElement(
                            USER_ICONS?.find(
                              (i) => i?.key === exportData?.avatar?.icon
                            )?.icon,
                            { custom: exportData?.avatar }
                          )}
                        </div>
                      </div>
                      <div className="name">{exportData?.name}</div>
                    </div>
                    <div className="wish">{`" ${exportData?.wish} "`}</div>
                    <Divider className="diver" />
                    <div className="export-card-carbon-section">
                      <div className="kratong-name">
                        {exportDataKratong?.name}
                      </div>
                      <div className="co2">
                        <CO2Level
                          level={exportDataKratong?.level}
                          space={4}
                          height={30}
                          isExport
                        />
                      </div>
                    </div>
                    <div className="information-carbon">
                      <div>{exportDataKratong?.description}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={`wave-image position2 ${loyWrap}`}
            style={{ zIndex: 3 }}
          >
            {waveList3?.length > 0 && kratongLoy(waveList3, setWaveList3)}
            {waveList3v2?.length > 0 && kratongLoy(waveList3v2, setWaveList3v2)}
            {isExport &&
              kratongs?.["3"]?.map((item, index) => {
                return (
                  <div className="export-kratong" key={index}>
                    <img
                      src={
                        KRATONGS?.find((i) => i.key === item?.noTypeKrathong)
                          ?.image
                      }
                      alt="kratong"
                    />
                  </div>
                );
              })}
            <div
              className={`wave-background-setting wave2 ${loyAnimate} ${loyImg}`}
            ></div>
          </div>
          <div
            className={`wave-image position1 ${loyWrap}`}
            style={{ zIndex: 4 }}
          >
            <div
              className={`wave-background-setting wave1 ${loyAnimate} ${loyImg}`}
            ></div>
          </div>
        </section>
      </section>
    </div>
  );
}
