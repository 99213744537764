import "./assets/fonts/Poppins/Poppins-Thin.ttf"
import "./assets/fonts/Poppins/Poppins-Bold.ttf"
import "./assets/fonts/Poppins/Poppins-Black.ttf"
import "./assets/fonts/Poppins/Poppins-ExtraBold.ttf"
import "./assets/fonts/Poppins/Poppins-SemiBold.ttf"
import "./assets/fonts/Poppins/Poppins-Regular.ttf"
import "./assets/fonts/Poppins/Poppins-Medium.ttf"
import "./assets/fonts/Poppins/Poppins-Light.ttf"
import "./assets/fonts/Poppins/Poppins-ExtraLight.ttf"
import "./assets/fonts/Poppins/Poppins-Italic.ttf"
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <App />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
