import './CO2Level.style.css';
import React from 'react';
import { TREES } from '../modal/configure/modalState';

export default function CO2Level({ level, space, height, isExport }) {
    return (
        <>
            {new Array(level).fill(null).map((_, index) =>
                <span className='tree' style={{paddingLeft: space, paddingRight: space, height: height}} key={index}>
                    {isExport ? <img src={TREES.green.image} alt={"image"+index} /> : TREES.green.svg}
                </span>
            )}
            {new Array(5-level).fill(null).map((_, index) =>
                <span className='tree' style={{paddingLeft: space, paddingRight: space, height: height}} key={index}>
                    { isExport ? <img src={TREES.red.image} alt={"image"+index} /> : TREES.red.svg}
                </span>
            )}
        </>
    );
}