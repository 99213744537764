import "./CustomButton.style.css";
import React from 'react';
import { Button } from "antd";

export default function CustomButton({ onClick, type, children, disabled, block, size, style, className }) {
  return (
    // <motion.div
    //   whileHover={disabled ? {} : { scale: 1.03 }}
    //   whileTap={disabled ? {} : { scale: 0.95 }}
    // >
      block ?
        <Button
          onClick={onClick}
          type={type}
          className={`${type ? type : "default"} text ${className} normalCss`}
          disabled={disabled ? disabled : false}
          size={size ? size : "default"}
          style={style}
          block
        >
          {children}
        </Button> :
        <Button
          onClick={onClick}
          type={type}
          className={`${type ? type : "default"} text ${className} normalCss`}
          disabled={disabled ? disabled : false}
          size={size ? size : "default"}
          style={style}
        >
          {children}
        </Button>
    // </motion.div>
  );
}
