import "./CustomInput.style.css";
import { Input } from "antd";

export default function CustomInput({
  className,
  lineNo,
  placeholder,
  onChange,
  value,
  style,
  maxLength,
  showCount,
}) {
  const { TextArea } = Input;
  return !lineNo || lineNo === 1 ? (
    <Input
      className={`input ${className ? className : ""}`}
      onChange={onChange}
      value={value}
      placeholder={placeholder ? placeholder : ""}
      style={style ? style : {}}
      maxLength={maxLength}
      showCount
    />
  ) : (
    <TextArea
      className={`input ${className ? className : ""}`}
      onChange={onChange}
      value={value}
      placeholder={placeholder ? placeholder : ""}
      style={style ? style : {}}
      autoSize={{ minRows: lineNo }}
      maxLength={maxLength}
      showCount
    />
  );
}
