import axios from 'axios';

let response;

async function getKratongs() {
    await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/internal/krathong/random_krathong/30`,
        headers: { "Content-type": 'application/json' },
    })
        .then(res => response = res.data)
        .catch(err => response = err);
    return response;
};

async function createKratong(body) {
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/api/internal/krathong/add/krathong`,
        headers: { 'Content-type': 'application/json' },
        data: body,
    })
        .then(res => response = res.data)
        .catch(err => response = err);
    return response;
}

async function createAvatar(body) {
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/api/internal/krathong/add/avatar`,
        headers: { 'Content-type': 'application/json' },
        data: body,
    })
        .then(res => response = res.data)
        .catch(err => response = err);
    return response;
}

async function updateKratong(body) {
    await axios({
        method: 'POST',  // not sure
        url: `${process.env.REACT_APP_API_URL}/api/internal/krathong/update/krathong`,
        headers: { 'Content-type': 'application/json' },
        data: body,
    })
        .then(res => response = res.data)
        .catch(err => response = err);
    return response;
}

async function updateAvatar(body) {
    await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/api/internal/krathong/update/avatar`,
        headers: { 'Content-type': 'application/json' },
        data: body,
    })
        .then(res => response = res.data)
        .catch(err => response = err);
    return response;
}

async function getSummary() {
    await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/api/internal/krathong/summary`,
        headers: { 'Content-type': 'application/json' },
    })
        .then(res => response = res.data)
        .catch(err => response = err);
    return response;
}

export { getKratongs, createKratong, createAvatar, updateKratong, updateAvatar, getSummary };