import "./Start.style.css";
import { Flex } from "antd";
import { CREATE_STATE } from "../configure/modalState";
import React  from 'react';
import CustomButton from "../../button/CustomButton";

export default function Start({ setCreateState }) {
  const handleStartButton = () => {
    setCreateState(CREATE_STATE[1]);
  };

  return (
    <Flex justify="center" align="center" vertical ="true">
      <h1 justify="center" align="center" vertical ="true" className="w900">
      Accenture’s <span className="text-highligh">Loy Krathong</span> Celebration
      </h1>
      <div className="text-center f-thin">Enjoy the Beautiful Thai Fiesta Vibes & Embrace the Eco Spirit!</div>
      <CustomButton type="primary" size="large" onClick={handleStartButton} className="btn-start">
        Get started!
      </CustomButton>
    </Flex>
  );
}
