import React from 'react';

export default function Kratong6() {
  return (
    <svg width="130" height="122" viewBox="0 0 130 122" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="66.9585" y="0.583008" width="2.1875" height="65.625" fill="#EBC2EE" />
      <rect x="70.604" y="0.583008" width="2.1875" height="65.625" fill="#EBC2EE" />
      <rect x="57.5996" y="26.6885" width="6.79719" height="37.3845" fill="url(#paint0_linear_0_3)" />
      <path d="M57.5682 16.9165C55.1892 23.7136 58.5595 25.1297 60.542 24.9881C66.9144 24.9881 65.6399 18.6158 64.7903 16.9165C64.1105 15.557 61.6749 9.83606 60.542 7.14551C60.542 8.41998 60.542 8.41998 57.5682 16.9165Z" fill="#FFE92E" />
      <path d="M57.5682 16.9165C55.1892 23.7136 58.5595 25.1297 60.542 24.9881C66.9144 24.9881 65.6399 18.6158 64.7903 16.9165C64.1105 15.557 61.6749 9.83606 60.542 7.14551C60.542 8.41998 60.542 8.41998 57.5682 16.9165Z" fill="#FFE92E" />
      <path d="M57.5682 16.9165C55.1892 23.7136 58.5595 25.1297 60.542 24.9881C66.9144 24.9881 65.6399 18.6158 64.7903 16.9165C64.1105 15.557 61.6749 9.83606 60.542 7.14551C60.542 8.41998 60.542 8.41998 57.5682 16.9165Z" fill="#FFE92E" />
      <path d="M59.0054 19.2188C57.3883 24.0714 59.6792 25.0823 61.0267 24.9812C65.3581 24.9812 64.4919 20.4319 63.9143 19.2188C63.4523 18.2483 61.7968 14.164 61.0267 12.2432C61.0267 13.153 61.0267 13.153 59.0054 19.2188Z" fill="#FFA217" />
      <path d="M59.6961 21.5272C58.5411 24.4387 60.1774 25.0453 61.1399 24.9846C64.2338 24.9846 63.615 22.2551 63.2025 21.5272C62.8725 20.9448 61.6899 18.4943 61.1399 17.3418C61.1399 17.8877 61.1399 17.8877 59.6961 21.5272Z" fill="#FAEE8D" />
      <rect x="0.604492" y="49.7471" width="35" height="72.9167" rx="5.83333" transform="rotate(-15 0.604492 49.7471)" fill="#272727" />
      <rect x="4.17676" y="51.8096" width="7.29167" height="18.9583" rx="3.64583" transform="rotate(-15 4.17676 51.8096)" fill="black" />
      <circle cx="8.64202" cy="54.3876" r="1.82292" transform="rotate(-15 8.64202 54.3876)" fill="#272727" stroke="#7A7A7A" stroke-width="0.729167" />
      <circle cx="10.1518" cy="60.0214" r="2.1875" transform="rotate(-15 10.1518 60.0214)" fill="white" />
      <circle cx="11.6616" cy="65.6562" r="1.82292" transform="rotate(-15 11.6616 65.6562)" fill="#272727" stroke="#7A7A7A" stroke-width="0.729167" />
      <rect x="96.0391" y="40.6875" width="35" height="72.9167" rx="5.83333" transform="rotate(15 96.0391 40.6875)" fill="#272727" />
      <rect x="98.1016" y="44.2598" width="7.29167" height="18.9583" rx="3.64583" transform="rotate(15 98.1016 44.2598)" fill="black" />
      <circle cx="100.679" cy="48.725" r="1.82292" transform="rotate(15 100.679 48.725)" fill="#272727" stroke="#7A7A7A" stroke-width="0.729167" />
      <circle cx="99.1694" cy="54.3598" r="2.1875" transform="rotate(15 99.1694 54.3598)" fill="white" />
      <circle cx="97.6596" cy="59.9946" r="1.82292" transform="rotate(15 97.6596 59.9946)" fill="#272727" stroke="#7A7A7A" stroke-width="0.729167" />
      <g filter="url(#filter0_d_0_2)">
        <rect x="34.8747" y="42.1452" width="33.5417" height="71.4583" rx="5.10417" fill="#272727" stroke="black" stroke-width="1.45833" />
        <rect x="37.062" y="44.333" width="29.1667" height="61.9792" rx="4.375" fill="#DDF4FB" />
        <rect x="47.9995" y="107.771" width="8.02083" height="3.64583" rx="1.82292" fill="#AEAEAE" />
        <path opacity="0.5" d="M37.062 75.6872L66.2287 48.708V51.6247L37.062 78.6038L37.062 75.6872Z" fill="white" />
        <path opacity="0.5" d="M37.062 80.7907L66.2287 53.8115V59.6449L37.062 86.624V80.7907Z" fill="white" />
      </g>
      <g filter="url(#filter1_d_0_1)">
        <rect x="61.8542" y="42.1452" width="33.5417" height="71.4583" rx="5.10417" fill="#272727" stroke="black" stroke-width="1.45833" />
        <rect x="64.0415" y="44.333" width="29.1667" height="61.9792" rx="4.375" fill="#DDF4FB" />
        <rect x="74.979" y="107.771" width="8.02083" height="3.64583" rx="1.82292" fill="#AEAEAE" />
        <path opacity="0.5" d="M64.0415 75.6872L93.2082 48.708V51.6247L64.0415 78.6038L64.0415 75.6872Z" fill="white" />
        <path opacity="0.5" d="M64.0415 80.7907L93.2082 53.8115V59.6449L64.0415 86.624V80.7907Z" fill="white" />
      </g>
      <rect x="10.0835" y="113.97" width="107.188" height="7.29167" fill="url(#paint1_linear_0_5)" />
      <defs>
        <filter id="filter0_d_0_2" x="29.0413" y="38.4993" width="40.8333" height="78.7503" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="-2.1875" />
          <feGaussianBlur stdDeviation="1.45833" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
        </filter>
        <filter id="filter1_d_0_1" x="57.4792" y="39.9577" width="38.6458" height="75.8337" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="-2.1875" />
          <feGaussianBlur stdDeviation="0.729167" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_0_3" x1="60.9982" y1="26.6885" x2="60.9982" y2="64.073" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FFE92E" />
          <stop offset="1" stop-color="#FB8B1C" />
        </linearGradient>
        <linearGradient id="paint1_linear_0_5" x1="63.6772" y1="113.97" x2="63.6772" y2="121.261" gradientUnits="userSpaceOnUse">
          <stop stop-color="#D9D9D9" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}